import {
  generateBillingHistoryTotalAmount,
  generateBillingHistoryTotalBilledAmount,
  generateBillingHistoryURL,
  generateByMonthlyReportingURL,
  generateByPspReportingURL,
  generateByReasonReportingURL,
  generateByStoresReportingURL,
  generatePaypalReportString,
  generateReadyDisputeURL,
  generateReadyDisputeURLTotalAmount,
  generateReadyDisputeURLTotalBilledAmount,
  generateReportingURL,
  generateUnderReviewCardTypeURL,
  generateUnderReviewURL,
  generateURL,
  generateWaitingSellerCardAmountURL,
  generateWaitingSellerResponseURL,
  getFetchContentByDisputeIdTemplateId
} from "./api-constants.utils";

export const apiConstants = {
  CREATE_USER: "/account/create-account",
  CONNECT_PAYPAL: (codeID: string) => `/paypal?code=${codeID}`,
  DASHBOARD_TOTAL_REVENUE_PROTECTED: "/dashboard/total-revenue-protected",
  DASHBOARD_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT: (payload: string) => `/dashboard/total-revenue-protected-by-account?userId=${payload}`,
  DASHBOARD_GET_LINKED_SHOPS: "/dashboard/linked-shops",
  DASHBOARD_GET_LINKED_SHOPS_BY_ACCOUNT: (payload: string) => `/dashboard/linked-shops-by-account?userId=${payload}`,
  DASHBOARD_DELETE_LINKED_SHOPS: (payload: number) => `/shops/delete/${payload}`,
  DASHBOARD_LINKED_PAYPAL: "/dashboard/linked-paypal",
  DASHBOARD_LINKED_PAYPAL_BY_ACCOUNT: (payload: string) => `/dashboard/linked-paypal-by-account?userId=${payload}`,
  DASHBOARD_RECENT_DISPUTES: "/dashboard/recent-disputes",
  DASHBOARD_RECENT_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/recent-disputes-by-account?userId=${payload}`,
  DASHBOARD_OPEN_DISPUTES: "/dashboard/open-disputes",
  DASHBOARD_OPEN_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/open-disputes-by-account?userId=${payload}`,
  DASHBOARD_RECOVERED_DISPUTES: "/dashboard/recovered-disputes",
  DASHBOARD_RECOVERED_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/recovered-disputes-by-account?userId=${payload}`,
  DASHBOARD_CURRENT_WEEK_DISPUTES: "/dashboard/current-week-disputes",
  DASHBOARD_CURRENT_WEEK_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/current-week-disputes-by-account?userId=${payload}`,
  // DASHBOARD_CURRENT_MONTH_DISPUTES: '/dashboard/current-month-disputes',
  // DASHBOARD_CURRENT_MONTH_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/current-month-disputes-by-account?userId=${payload}`,

  GET_DISPUTES_LIST: (payload: any) => `/dispute?page=${payload.page}&pageLength=${payload.pageLength}`,
  FIND_FILTERED_DISPUTES: (payload: any) => generateURL(payload),
  POST_DISPUTES_FETCH_BY_ACCOUNT: "/dispute/fetch-by-account",
  DASHBOARD_PROCESSING_DISPUTES: "/dashboard/processing-disputes",
  DASHBOARD_PROCESSING_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/processing-disputes-by-account?userId=${payload}`,
  DASHBOARD_AWAITING_RESPONSE_DISPUTES: "/dashboard/awaiting-response-disputes",
  DASHBOARD_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT: (payload: string) => `/dashboard/awaiting-response-disputes-by-account?userId=${payload}`,
  //dispute-details
  GET_DISPUTE_DETAILS: (payload: any) => `/dispute/${payload}`,
  GET_DISPUTE_ORDER_SUMMARY: (payload: any) => `/dispute/dispute-order-summary/${payload}`,
  GET_DISPUTE_TRANSACTION_DETAILS: (payload: any) => `/dispute/disputed-transaction/${payload}`,
  GET_DISPUTE_EVIDENCE_DETAILS: (payload: any) => `/dispute/dispute-evidence/${payload}`,
  GET_DISPUTE_MESSAGE_DETAILS: (payload: any) => `/dispute/dispute-message/${payload}`,
  GET_DISPUTE_ADJUDICATION_DETAILS: (payload: any) => `/dispute/dispute-adjudication/${payload}`,
  GET_DISPUTE_MONEY_MOVEMENT_DETAILS: (payload: any) => `/dispute/dispute-money-movement/${payload}`,
  GET_DISPUTE_SUPPORTING_INFO_DETAILS: (payload: any) => `/dispute/dispute-supporting-info/${payload}`,
  GET_DISPUTE_OFFER_DETAILS: (payload: any) => `/dispute/dispute-offer/${payload}`,
  POST_DISPUTE_FLOW_UPLOAD: (payload: any) => `/dispute-flow/upload-file?uploadFileType=${payload?.uploadFileType}&disputeFlowMappingId=${payload?.disputeFlowMappingId}`,
  POST_SHOP_SEND_MESSAGE: (payload: any) => `/dispute/${payload?.disputeId}/send-message?message=${payload?.message}`,
  POST_ACCOUNT_LIST: "/account/fetch-accounts-by-status",
  GET_FILTERED_AWAITING_DISPUTES: (payload: any) => {
    if (typeof payload === 'string') {
      return `/dispute/fetch-awaiting-disputes${payload}`;
    } else {
      const queryString = new URLSearchParams(payload).toString();
      return `/dispute/fetch-awaiting-disputes?${queryString}`;
    }
  },
  //manage-dispute-end-points
  POST_MANAGE_DISPUTE_SEND_MESSAGE: "/dispute-endpoint/other-party-dispute-message",
  GET_MANAGE_DISPUTE_CARRIER_OPTIONS: "/dispute-endpoint/carrier",
  GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS: "/dispute-endpoint/evidenceType",
  GET_MANAGE_DISPUTE_CUSTOMER: (payload: any) => `/dispute/customer-by-dispute?disputeId=${payload}`,
  GET_MANAGE_DISPUTE_FULFILLMENT: (payload: any) => `/dispute/fulfillment-details-by-dispute?disputeId=${payload}`,
  GET_MANAGE_DISPUTE_TRACKING_DETAILS: (payload: any) => `/dispute/track-details?disputeId=${payload}`,
  GET_RESPONSE_JSON_BY_ID: (payload: any) => `/dispute/find-json-by-id?disputeId=${payload}`,
  GET_MANAGE_DISPUTE_SHIPPING_DETAILS: (payload: any) => `/dispute/shipping-details?disputeId=${payload}`,
  // GET_MANAGE_DISPUTE_SHIPPING_DETAILS: (payload: any) =>
  //   `/dispute/getTracking?disputeId=${payload}`,
  GET_MANAGE_DISPUTE_GETTRACKING_DETAILS: (payload: any) => `/dispute/getTracking?disputeId=${payload}`,
  POST_MANAGE_DISPUTE_SUBMITTED_BY_CHARGEPAY: (payload: any) => `/dispute/submitted-by-chargepay?disputeId=${payload?.disputeId}&submittedByChargePay=${payload?.submittedByChargePay}`,
  GET_MANAGE_FRAUD_ANALYSIS_DETAILS: (payload: any) => `/dispute/fraud-analysis?disputeId=${payload}`,
  POST_MANAGE_DISPUTE_APPEAL_DISPUTE: "",
  POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM: "/dispute-endpoint/acknowledge-return-item",
  POST_MANAGE_ACCEPT_CLAIM: "/dispute-endpoint/accept-claim-request",
  POST_MANAGE_MANUALLY_DISPUTE: `/dispute/shopify-file-upload`,
  GET_MANAGE_DISPUTE_HATEOAS_LINK: (payload: any) => `/dispute/hateoas-link/${payload}`,
  GET_MANAGE_DISPUTE_JIRA_COMMENTS: (payload: any) => `/jira/comments?disputeId=${payload}`,
  //dispute-template
  POST_DISPUTE_TEMPLATE: "/dispute-template",
  GET_DISPUTE_TEMPLATE_LIST: (payload: any) => `/dispute-template/list?${payload?.enabled && payload?.type ? `enabled=${payload?.enabled}` : `enabled=${payload?.enabled}`}&${payload?.type ? `reasons=${payload?.type}` : ""}&page=${payload?.page}&pageLength=${payload?.pageLength}`,
  GET_DISPUTE_TEMPLATE_FULL_LIST: (payload: any) => `/dispute-template/list`,
  GET_DISPUTE_TEMPLATE_REASON_LIST: (payload: any) => `/dispute-template/reason-list${payload?.page && payload?.pageLength ? `?page=${payload?.page}&pageLength=${payload?.pageLength}` : ""}`,
  GET_DISPUTE_TEMPLATE_ACTION_LIST: (payload: any) => `/dispute-template/action-list?type=${payload}`,
  GET_DISPUTE_TEMPLATE_STAGE_LIST: (payload: any) => `/dispute-template/stage-list${payload?.page && payload?.pageLength ? `?page=${payload?.page}&pageLength=${payload?.pageLength}` : ""}`,
  GET_DISPUTE_TEMPLATE_FIND_BY_TYPE: (payload: string) => `/dispute-template/find-by-type?type=${payload}`,
  GET_DISPUTE_TEMPLATE_FIND_BY_STORE: (payload: string) => `/dispute-template/find-by-store?storeId=${payload}`,
  POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES: "/dispute-template/filter-templates",
  GET_DISPUTE_TEMPLATE_VIEW: (payload: any) => `/dispute-template/${payload}`,
  GET_DISPUTE_TEMPLATE_PREVIEW: (payload: string | number) => `/dispute-template/preview?templateId=${payload}`,
  GET_DISPUTE_TEMPLATE_FETCH_CONTENT: (payload: string | number) => `/dispute-template/fetch-content?templateId=${payload}`,
  //dispute-response
  GET_DISPUTE_RESPONSE_FIND_ALL: "/dispute-response/find-all",
  GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID: (payload: any) => `/dispute-response/find-by-disputeId?disputeId=${payload}`,
  GET_DISPUTE_FLOW_BY_PSP: (payload: any) => `/dispute-flow/psp?paymentProcessor=${payload}`,
  GET_DISPUTES_FLOW_BY_TEMPLATES_FLOWID: (payload: any) => `/dispute-flow/fetch-templates-by-flow?flowId=${payload}`,
  // POST_FETCH_CONTENT_BY_DISUTEID_TEMPLATEID:(payload:any)=> getFetchContentByDisputeIdTemplateId(payload),
  POST_FETCH_CONTENT_BY_DISUTEID_TEMPLATEID:(payload:any)=> `/dfdfdispute-response/fetch-content-by-disputeId-templateId?`,

  POST_DISPUTE_RESPONSE_CREATE: "/dispute-response/create",
  GET_DISPUTE_RESPONSE_PDF: (payload: any) => `/dispute-response/pdf?templateId=${payload?.templateId}&disputeId=${payload?.disputeId}`,
  GET_DISPUTE_RESPONSE_PDF_BY_ID: (payload: any) => `/dispute-response/pdf/${payload}`,
  UPDATE_STATUS_SUBMITTED: (payload: any) => `/dispute-response/update-status-submitted?disputeResponseId=${payload}`,
  UPDATE_RESPONSE_STATUS_SUBMITED: (payload: any) => `/dispute-response/update-status-submitted?disputeResponseId=${payload}`,
  UPDATE_STATUS_QUEUED: (payload: any) => `/dispute-response/update-status-queued?disputeResponseId=${payload}`,
  UPDATE_STATUS_APPROVED: (payload: any) => `/dispute-response/update-status-approved?disputeResponseId=${payload}`,
  GET_DISPUTE_RESPONSE_FETCH_CONTENT: (payload: string | number) => `/dispute-response/fetch-content?disputeResponseId=${payload}`,
  POST_DISPUTE_RESPONSE_SAVE_CONTENT: "/dispute-response/save-content",
  POST_SAVING_MISSING_MACROS: "/dispute-response/save-macros",
  GET_MISSING_MACROS: (payload: any) => `/dispute-response/get-missing-macros?page=${payload?.page}&pageLength=${payload?.pageLength}&disputeResponseInfo=${payload?.disputeResponseInfo}&responseSection=${payload?.responseSection}`,
  GET_DISPUTE_RESPONSE_MACROS: (payload: any) => `/dispute-response/get-macros?disputeResponseId=${payload?.disputeResponseId}&responseSection=${payload?.responseSection}`,
  POST_DISPUTE_RESPONSE_UPDATE_STATUS_APPROVED: "/dispute-response/update-status-approved",
  POST_DISPUTE_RESPONSE_CREATE_RESPONSE: `/dispute-response/create-response`,
  DELETE_SINGLE_DISPUTE_RESPONSE: (payload: number) => `/dispute-response/delete/${payload}`,
  GET_MANAGE_DISPUTE_FLOW: (payload: any) => `/dispute-response/fetch-dispute-flows?disputeId=${payload.disputeId}&paymentProcessor=${payload.paymentProcessor}`,
  GET_MANAGE_DISPUTE_TEMPLATE: (payload: any) => `/dispute-response/fetch-templates?disputeId=${payload.disputeId}&flowId=${payload.flowId}`,
  GET_MANAGE_DISPUTE_SUMMARY_TEMPLATE: (payload: any) => `/dispute-response/get-template-summary?disputeId=${payload.disputeId}&templateId=${payload.templateId}`,
  //stores-shop
  GET_SHOP_ALL_SHOPS: (payload: any) => `/shop/all-shops${payload?.page && payload?.pageLength ? `?page=${payload?.page}&pageLength=${payload?.pageLength}` : ""}`,
  GET_SHOP_FIND_SHOP: (payload: any) => `/shop/find-shop?id=${payload}`,
  POST_SHOP_FILTER_SHOPS: (payload: any) => `/shop/filter-shops${payload?.page && payload?.pageLength ? `?page=${payload?.page}&pageLength=${payload?.pageLength}` : ""}`,
  GET_FIND_ACTIVE_STORES: (payload: any) => `/shop/find-active-stores`,
  GET_SHOP_STORE_REPORT: (payload: any) => `/shop/store-report${payload?.page && payload?.pageLength ? `?page=${payload?.page}&pageLength=${payload?.pageLength}` : ""}`,
  POST_SHOP_SAVE_COMPANY_ADD_ON: "/shop/save-company-addon",
  POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON: "/shop/save-addon",
  POST_SHOP_SAVE_ADD_ON: "/shop/save-addon",
  GET_SHOP_FIND_SHOP_ADD_ON: (payload: any) =>
    `/shop/find-shop-addon?shopId=${payload}`,
  GET_PRE_SIGNED_URL: (payload: any) => `/aws/pre-signed-url?url=${payload}`,
  UPDATE_SHOP_COLLABORATOR_ACCESS: (payload: any) =>
    `/shop/update-collaborator-access?id=${payload.id}&collaboratorAccess=${payload.collaboratorAccess}`,
  //dispute-flow
  POST_DISPUTE_FLOW: "/dispute-flow/save",
  POST_DISPUTE_HANDLE_SAVE: "/dispute/handle-dispute",
  POST_DISPUTE_FLOW_DUPLICATE_ID:(payload:any)=>  `/dispute-flow/duplicate/${payload?.id}?suffix=${payload?.suffix}&shopId=${payload?.shopId}`,
  GET_DISPUTE_FLOW_LIST: "/dispute-flow/all",
  GET_DISPUTE_FLOW_REASON_LIST: (payload: any) => `/dispute-template/reason-list?page=${payload?.page}&pageLength=${payload?.pageLength}`,
  GET_DISPUTE_FLOW_ACTION_LIST: (payload: any) => `/dispute-template/action-list?type=${payload}`,
  GET_DISPUTE_FLOW_STAGE_LIST: (payload: any) => `/dispute-template/stage-list?page=${payload?.page}&pageLength=${payload?.pageLength}`,
  GET_DISPUTE_FLOW_FIND_BY_TYPE: (payload: string) => `/dispute-template/find-by-type?type=${payload}`,
  GET_DISPUTE_FLOW_FIND_BY_STORE: (payload: string) => `/dispute-template/find-by-store?storeId=${payload}`,
  POST_DISPUTE_FLOW_FILTER_FLOWS: "/dispute-template/filter-templates",
  GET_DISPUTE_FLOW_VIEW: (payload: any) => `/dispute-flow/${payload}`,

  //manage-user+manager
  GET_MANAGER_LIST: (payload: any) => `/manage-user/list-manager?role=${payload}`,
  POST_UPDATE_MANAGER_BY_SHOPID: `/manage-user/update-by-shopId`,
  POST_UPDATE_MANAGER_BY_DISPUTEID: `/manage-user/update-by-disputeId`,
  GET_SHOP_BY_MANAGER: `/manage-user/find-shop-by-manager`,
  GET_DISPUTE_BY_MANAGER: `/manage-user/find-dispute-by-manager`,
  GET_USER_LIST: `/manage-user/list`,
  POST_CREATE_USER: `/manage-user/create`,
  // klarna
  GET_KLARNA_REQUESTED_FIELDS: (payload: any) => `/dispute-response/requested-fields-by-dispute?disputeId=${payload}`,
  // Billing
  // POST_DISPUTE_BILLING: (payload: any) => `/dispute/billing-report?page=${payload.page}&pageLength=${payload.pageLength}`,
  POST_DISPUTE_BILLING: (payload: any) => generateBillingHistoryURL(payload),
  POST_APPROVED_DISPUTES: (payload: any) => `/dispute/approved-disputes?${payload.map((id: string) => `disputeId=${id}`).join("&")}`,
  POST_NEW_BILLING_LIST: (payload: any) => `/dispute/new-billing-list?page=${payload.page}&pageLength=${payload.pageLength}`,
  GET_NEW_BILLING_READY_DISPUTE_LIST: (payload: any) => generateReadyDisputeURL(payload),
  POST_CONFIRM_DISPUTE_BILLING: (payload: any) => `dispute/ready-status?${payload.map((id: string) => `disputeId=${id}`).join("&")}`,
  // Paypal pull report
  // POST_PAYPAL_PULL_REPORT : (payload:any)=> `/dispute/payPal-pull-report?page=${payload?.page}&pageLength=${payload?.pageLength}`, 
  POST_PAYPAL_PULL_REPORT : (payload:any)=> generatePaypalReportString(payload), 
  // for billing card api
  GET_TOTAL_BILLED_AMOUNT: (payload: any) => generateBillingHistoryTotalBilledAmount(payload),
  GET_TOTAL_AMOUNT: (payload: any) => generateBillingHistoryTotalAmount(payload),
  GET_NEW_BILLING_TOTAL_BILLED_AMOUNT: (payload: any) => generateReadyDisputeURLTotalBilledAmount(payload),
  GET_NEW_BILLING_TOTAL_AMOUNT: (payload: any) => generateReadyDisputeURLTotalAmount(payload),
  GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT: (payload: any) => `dispute/ready-dispute/total-billed-amount`,
  GET_READY_DISPUTE_TOTAL_AMOUNT: (payload: any) => `dispute/ready-dispute/total-amount`,
  //Reporting
  GET_REPORT_LIST: (payload: any) => generateReportingURL(payload),
  GET_BY_STORES_REPORT_LIST: (payload: any) => generateByStoresReportingURL(payload),
  GET_BY_PSP_REPORT_LIST: (payload: any) => generateByPspReportingURL(payload),
  GET_BY_REASONS_REPORT_LIST: (payload: any) => generateByReasonReportingURL(payload),
  GET_BY_MONTHLY_REPORT_LIST: (payload: any) => generateByMonthlyReportingURL(payload),
  // under review
  GET_FETCH_UNDER_REVIEW_DISPUTES: (payload: any) => generateUnderReviewURL(payload),
  GET_UNDER_REVIEW_TOTAL_AMOUNT: (payload: any) => generateUnderReviewCardTypeURL(payload),
  GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT: (payload: any) => generateUnderReviewCardTypeURL(payload),
  // awaiting seller response
  GET_AWAITING_DISPUTES: (payload: any) => generateWaitingSellerResponseURL(payload),
  GET_AWAITING_TOTAL_AMOUNT: (payload: any) => generateWaitingSellerCardAmountURL(payload),
  GET_AWAITING_TOTAL_BILLED_AMOUNT: (payload: any) => generateWaitingSellerCardAmountURL(payload),


};
