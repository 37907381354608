import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDisputeFlowListRequest } from "redux/actions";

type ShopData = {
  additionalMandatoryShopDataRequired:boolean;
  appId:any;
  chargeActive:boolean;
  collaboratorAccess:string;
  country:any;
  createdAt:any;
  email:any;
  externalId:any;
  id:number;
  manager:any;
  myShopifyDomain:any;
  name:string;
  onboarded:boolean;
  paypalActive:boolean;
  riskAccepted:boolean;
  scopes:any;
  shopOwner:any;
  shopType:any;
  subscriptionDate:any;
  subscriptionId:any;
  uniqueId:any;
}
export type DisputeFlowData = {
  approvedBy:any;
  approvedOn:any;
  createdBy:string;
  createdOn:string;
  flow:string;
  id:number;
  lastUpdatedBy:string;
  lastUpdatedOn:string;
  name:string;
  paymentGateway:string;
  shop: ShopData
}


type DisputeFlowState = {
  isLoading:boolean;
  isSuccess:boolean;
  isError:boolean;
  data :{
    config:any;
    data: {
      count:number;
      disputeFlowDTOList:DisputeFlowData[]
    },
    headers:any;
    request:any;
    status:number;
    statusText:string
  }
}

const useSystemFlow = () => {
  const dispatch = useDispatch();
  const systemFlowState:DisputeFlowState = useSelector(
    (state: any) => state?.disputeFlow?.disputeFlowList
  );
  const {isLoading,isSuccess,isError,data} = systemFlowState
  useEffect(() => {
    dispatch(getDisputeFlowListRequest());
  }, [dispatch]);

  return {
    isLoading,
    isSuccess,
    isError,
    data,
  };
};

export default useSystemFlow;
