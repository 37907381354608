import { DisputesWidget, Loading, Pagination } from 'components';
import styles from './style.module.scss';
import { Button } from 'components/ui';
import { useDispatch } from 'react-redux';
import {
  postDisputeReadyStatusRequest,
  postNewBillingListRequest,
  getReadyDisputeTotalBilledAmountRequest,
  getReadyDisputeTotalAmountRequest,
} from 'redux/actions';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ReusableTable from 'components/table';
import { ToastContainer } from 'react-toastify';
import { IconRender } from 'elements';

type NewBillingTypes = {
  selectedDisputes: any;
  handleCheckboxChange: any;
  // onPageChange: any;
  // page: any;
  // pageLength: any;
};
const billingSetupTableHeading = [
  'Dispute ID',
  'Store',
  'Order Number',
  'Amount',
  'Billable',
  // 'Status',
  'Dispute Reason',
  'Date Opened',
  'Decision Date',
  'Response SubmittedAt',
  'Action'
];
const BillingSetup = ({
  selectedDisputes,
  handleCheckboxChange,
}: NewBillingTypes) => {
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const [page,setPage] = useState(1)
  const pageLength = 15
  const onPageChange = (page:number)=>{
    setPage(page)
  }
  const handleDisputeApproveCharges = () => {
    dispatch(postDisputeReadyStatusRequest(selectedDisputes));
    setCallApi(true);
  };
  const checkBoxVisible = true;
  const {
    data: newBillingList,
    isLoading,
    isError,
    isSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingList);
  const {
    data: disputeReadyStatus,
    isLoading: isReadyStatusLoading,
    isError: isReadyStatusError,
    isSuccess: isReadyStatusSuccess,
  } = useSelector((state: any) => state?.billing?.disputeReadyStatus);

  const { data: readyDisputTotalBilledAmount, isLoading: isTotalBilledLoading } =
    useSelector((state: any) => state?.billing?.readyDisputeTotalBilledAmount);
  const { data: readyDisputeTotalAmount, isLoading: isTotalAmountLoading } = useSelector(
    (state: any) => state?.billing?.readyDisputeTotalAmount
  );

  useEffect(() => {
    if (isReadyStatusSuccess && callApi) {
      const body = {
        disputeId: null,
        merchantName: null,
        disputedAmount: null,
        status: null,
        disputeReason: null,
        billable: null,
        dateOpenedFrom: null,
        dateOpenedTo: null,
        decisionDateFrom: null,
        decisionDateTo: null,
        shopId: null,
        currencyCode: null,
      };
      const payload = {
        page,
        pageLength,
        body,
      };
      dispatch(postNewBillingListRequest(payload));
      setCallApi(false);
    }
  }, [isReadyStatusSuccess, callApi]);
  useEffect(() => {
    const body = {
      disputeId: null,
      merchantName: null,
      disputedAmount: null,
      status: null,
      disputeReason: null,
      billable: null,
      dateOpenedFrom: null,
      dateOpenedTo: null,
      decisionDateFrom: null,
      decisionDateTo: null,
      shopId: null,
      currencyCode: null,
    };
    const payload = {
      page,
      pageLength,
      body,
    };
    dispatch(postNewBillingListRequest(payload));
  }, [page]);

  useEffect(() => {
    dispatch(getReadyDisputeTotalBilledAmountRequest())
    dispatch(getReadyDisputeTotalAmountRequest())
  }, []);

  // cards data
  const totalCountCard = {
    title: 'Total Count     ',
    blueEye: true,
    icon: <IconRender iconName='layersThree' />,
    totalDisputes: newBillingList?.count || 0,
    loading: isLoading,
  };
  const totalAmountCard = {
    title: 'Total Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: readyDisputeTotalAmount || 0,
    loading: isTotalAmountLoading,
  };
  const totalBillableAmountCard = {
    title: 'Total Billiable Amount',
    blueEye: true,
    icon: <IconRender iconName='activity' />,
    disputedAmount: readyDisputTotalBilledAmount || 0,
    loading: isTotalBilledLoading,
  };
  return (
    <>
      <div className={styles.newBillingTableWrapper}>
        <div className={styles.cardsWrapper}>
          <div className={styles.card}>
            <DisputesWidget disputeWidgetData={totalCountCard} />
          </div>

          <div className={styles.card}>
            <DisputesWidget disputeWidgetData={totalAmountCard} />
          </div>

          <div className={styles.card}>
            <DisputesWidget disputeWidgetData={totalBillableAmountCard} />
          </div>
        </div>
        <ToastContainer />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '8px',
            marginBottom: '20px',
          }}
        >
          <Button
            {...{
              btnLabel: 'Confirm we can bill those disputes',
              btnTitle: 'Confirm we can bill those disputes',
              onClick: handleDisputeApproveCharges,
              isDisable: selectedDisputes.length > 0 ? false : true,
            }}
          />
        </Box>
        {isLoading ? (
          <div style={{ background: '#fff' }}>
            <Loading />
          </div>
        ) : (
          <ReusableTable
            {...{
              usersColumns: billingSetupTableHeading,
              data: newBillingList,
              isLoading,
              isSuccess,
              isError,
              selectedDisputes,
              handleCheckboxChange,
              checkBoxVisible,
              isPdfButton: true,
            }}
          />
        )}
        {Math.ceil(newBillingList?.count / pageLength) > 1 && (
          <div>
            <Pagination
              onPageChange={pageNum => onPageChange(pageNum)}
              page={page}
              totalPages={Math.ceil(newBillingList?.count / pageLength)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BillingSetup;
