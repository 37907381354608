import { StoreReportTableType } from "components/storeReports/StoreReportList";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getShopStoreReportRequest } from "redux/actions";

type DataTypes = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: {
    count: number;
    storeReportList: StoreReportTableType[]
  }
}

const useStoreReportList = () => {
  const dispatch = useDispatch()
  const shopStoreReport: DataTypes = useSelector(
    (state: any) => state?.storesShop?.shopStoreReport
  );
  const { data, isLoading, isSuccess, isError } = shopStoreReport
  const [page, setPage] = useState(1);
  const pageLength = 15;
  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };
  useEffect(() => {
    const payload = {
      page: page,
      pageLength: pageLength
    }
    dispatch(getShopStoreReportRequest(payload))
  }, [page])
  return {
    data,
    isLoading,
    isSuccess,
    isError,
    pageLength,
    page,
    onPageChange
  }


}


export default useStoreReportList