import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Loading } from '..';
import { svgIcons } from 'elements';
import { getColorByStatus } from 'utils/accountsUtils';
import styles from './style.module.scss';
import { Tooltip } from '@mui/material';
import { useManagerList } from 'hooks';
import InlineUpdateManager from 'components/storesView/InlineUpdateManager';
import dayjs from "dayjs"

const storeListColumns = [
  'Store Id',
  'Stores',
  'Email Verified',
  'Status',
  'Create Date',
  'Total Disputes',
  'Manager',
];

type StoreTableData = {
  uniqueId: string;
  name: string;
  shopOwner: string;
  customerEmail: string;
  chargeActive: boolean;
  createdAt: string;
  manager: string;
  totalDisputes: number;
  id: number;
};

type StoreState = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: {
    count: number;
    shopResponseList: StoreTableData[];
  };
};

const StoresList: FC = () => {
  const StoreDataState: StoreState = useSelector(
    (state: any) => state.storesShop.shopAllShops
  );
  const { isLoading, isSuccess, isError, data } = StoreDataState;
  const { manageManager } = useManagerList();
  // const [currentData, setCurrentData] = useState<any[]>([]);
  // useEffect(() => {
  //   if (isSuccess && data?.shopResponseList?.length) {
  //     setCurrentData(data?.shopResponseList);
  //   }
  // }, [isSuccess]);

  return (
    <div className={styles.tableWrapper}>
      {isLoading && <Loading />}
      {(!isLoading && isError) || (isSuccess && !data?.count) ? (
        <div className={styles.NoData}>
          <img src={svgIcons.GettingCoffee} alt='disputes' />
          {isError && (
            <h3 data-testid='store-error-message'>
              Oops! Something went wrong. Please try again.
            </h3>
          )}
          <h3>No store found</h3>
        </div>
      ) : (
        isSuccess &&
        data?.count > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  {storeListColumns.map(item => (
                    <th key={item}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.shopResponseList?.length > 0 &&
                  data?.shopResponseList?.map((store: any) => (
                    <tr key={store.id} className={styles.disputeWrapper}>
                      <td className={styles.id} data-testid='storeId-test-id'>
                        <Tooltip
                          placement='top'
                          arrow
                          title='click to view the store'
                        >
                          <Link
                            className={styles.id}
                            to={`/store-details/${store?.id}`}
                          >
                            <p className={styles.recentDisputeID}>
                              {store?.id}
                            </p>
                          </Link>
                        </Tooltip>
                      </td>
                      <td className={styles.accountName}>{store?.name}</td>
                      <td>{``}</td>
                      <td>
                        <div
                          className={styles.status}
                          style={
                            store?.chargeActive === true
                              ? {
                                  backgroundColor:
                                    getColorByStatus('Active')?.backgroundColor,
                                  color: getColorByStatus('Active')?.textColor,
                                }
                              : {
                                  backgroundColor:
                                    getColorByStatus('Inactive')
                                      ?.backgroundColor,
                                  color:
                                    getColorByStatus('Inactive')?.textColor,
                                }
                          }
                        >
                          {store?.chargeActive === true ? 'Active' : 'Inactive'}
                        </div>
                      </td>
                      <td>
                        {store?.createdAt &&
                          dayjs(store?.createdAt).format('DD.MM.YY')}
                      </td>
                      <td className={cn(styles.reasonType, styles.merchantId)}>
                        {store?.totalDisputes ?? 0}
                      </td>
                      <td>
                        <InlineUpdateManager
                          {...{
                            singleStore: store,
                            toUsers: manageManager?.data,
                            dispute: false,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )
      )}
    </div>
  );
};
export default StoresList;
