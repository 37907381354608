import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  getDisputeResponseFindByDisputeIdRequest,
  getDisputeResponsePdfByIdRequest,
} from 'redux/actions';

const usePdfDownloadResponse = () => {
  const dispatch = useDispatch();
  const [pdfSwitch, setPdfSwitch] = useState(false);
  const [pdfData, setPdfData] = useState<any>();
  const [disputeId, setDisputeId] = useState('');
  const [templateId, setTemplateId] = useState();
  const [responseId, setResponseId] = useState<number>();
  const [loadingResponseId, setLoadingResponseId] = useState<number | null>(
    null
  );

  const disputeResponsePdf = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdf
  );
  const disputeResponsePdfById = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdfByResponseId
  );
  // function for download the pdf
  const handleDownloadPdfResponse = (
    responseId: number,
    disputeId: string,
    templateId: any
  ) => {
    setLoadingResponseId(responseId);
    setDisputeId(disputeId);
    setResponseId(responseId);
    if (templateId !== null) {
      setTemplateId(templateId);
    }
    const payload = responseId;
    dispatch(getDisputeResponsePdfByIdRequest(payload));
  };

  useEffect(() => {
    const payload = disputeId;
    if (payload) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(payload));
    }
  }, [disputeId]);

  useEffect(() => {
    if (
      pdfSwitch &&
      disputeResponsePdf?.isSuccess &&
      disputeResponsePdf?.data
    ) {
      const pdfUrl = window.URL.createObjectURL(
        new Blob([disputeResponsePdf?.data], { type: 'application/pdf' })
      );
      setPdfData(pdfUrl);
    }
  }, [disputeResponsePdf, pdfSwitch]);

  function downloadPdfFromBlob(blobResponse: any, filename = 'download.pdf') {
    // Create a blob from the
    const blob = new Blob([blobResponse], { type: 'application/pdf' });

    // Create a URL for the blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');

    // Set link attributes
    link.href = blobUrl;
    link.download = filename;

    // Append link to the body
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  }

  useEffect(() => {
    if (
      disputeResponsePdfById?.isSuccess &&
      disputeResponsePdfById?.data &&
      responseId
    ) {
      downloadPdfFromBlob(
        disputeResponsePdfById?.data,
        `${disputeId}_${templateId ? templateId : responseId}.pdf`
      );
      setLoadingResponseId(null);
    }
  }, [disputeResponsePdfById, pdfSwitch, responseId, disputeId]);

  return {
    handleDownloadPdfResponse,
    loadingResponseId,
  };
};

export default usePdfDownloadResponse;
