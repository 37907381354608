import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getNewBillingTotalAmountRequest,
  getNewBillingTotalBilledAmountRequest,
  getNewBillingReadyDisputeListRequest,
  removeDisputeApproveRequest,
  postDisputeApproveRequest,
} from 'redux/actions';
import Swal from 'sweetalert2';
import { getDisputeCurrency } from 'utils/disputeUtils';

export type IStoreOptions = {
  value: number;
  label: string;
};

type IDisputeCurrency = {
  value: number;
  label: string;
}

const useNewBillingTabs = (
  filterSuccessData: any,
  selectedDisputes: any,
  setSelectedDisputes: any
) => {
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const selectRef: any = useRef(null);
  const [open, setOpen] = useState(false);
  const [updatedDisputeIds, setUpdatedDisputeIds] = useState<string[]>([]);
  const [disputesPayload, setDisputesPayload] = useState<any>();
  // this is the state for shop filtering
  const [shopList, setShopList] = useState<IStoreOptions[]>([]);
  // this is the state for payment Processor
  const [paymentProcessor,setPaymentProcessor] = useState<IStoreOptions[]>([]);
  const [disputeCurrency,setDisputeCurrency] = useState<IDisputeCurrency[]>([]);

  const [page, setPage] = useState(1);
  const pageLength = 15;
  const onPageChange = (page: number) => {
    setPage(page);
  };
  // this is our main table data
  const {
    data: newBillingTableData,
    isLoading,
    isError,
    isSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingReadyDisputes);

  // this is for cardTotalBilledAmountData
  const {
    data: newBillingTotalBilledAmount,
    isLoading: isTotalBilledLoading,
    isError: isTotalBilledError,
    isSuccess: isTotalBilledSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingTotalBilledAmount);

  // this is for cardTotalAmountData
  const {
    data: newBillingTotalAmount,
    isLoading: isTotalAmountLoading,
    isError: isTotalAmountError,
    isSuccess: isTotalAmountSuccess,
  } = useSelector((state: any) => state?.billing?.newBillingTotalAmount);

  // this is for approvedDisputes
  const {
    data: billedDisputes,
    isLoading: isApprovedLoading,
    isError: isApprovedError,
    isSuccess: isApprovedSuccess,
  } = useSelector((state: any) => state?.billing?.disputeApprove);

  // this function is for close the modal
  const handleClose = () => {
    setOpen(false);
    dispatch(removeDisputeApproveRequest());
  };

  //  handle approve function
  const handleDisputeApproveCharges = () => {
    const successMessages = filterSuccessData
      ?.map((item: any) => {
        return `<p>20% of disputed amount: ${
          getDisputeCurrency(item?.currencyCode as string)?.currencySign
        } ${item.disputeAmount} for dispute id: ${item.disputeId}</p>`;
      })
      .join('');
    if (selectedDisputes?.length > 0) {
      Swal.fire({
        title: 'Disputes will be charged at 20% of amount',
        html: `<div style="display:flex; flex-direction:column;padding:10px;background:#ededed;height:250px; overflow:auto;">${successMessages}</div>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Create Charge',
      }).then(result => {
        if (result.isConfirmed) {
          setCallApi(true);
          console.log("this is the before line of api post request")
          // dispatch(postDisputeApproveRequest(selectedDisputes));
        }
      });
    }
    setSelectedDisputes([]);
  };

  useEffect(() => {
    if (billedDisputes?.disputeIds?.length && callApi) {
      setUpdatedDisputeIds(billedDisputes?.disputeIds);
      setOpen(true);
    }
  }, [billedDisputes?.disputeIds, callApi]);

  // this is the shopID filtering useEffect
  useEffect(() => {
    setPage(1);
    const shopIds =
      shopList && shopList.length > 0 ? shopList.map(item => item.value) : [];
    const payload = {
      ...disputesPayload,
      shopIds,
    };
    setDisputesPayload(payload);
  }, [shopList]);

  useEffect(()=>{
    setPage(1)
    const disputeCurrencyArr = disputeCurrency && disputeCurrency.length > 0 ? disputeCurrency.map((item)=> item.label) : []
    const payload = {
      ...disputesPayload,
      disputeCurrencyArr
    }
    setDisputesPayload(payload)
  },[disputeCurrency])


  // this is the paymentProcessors filtering useEffect
  useEffect(() => {
    setPage(1);
    const paymentProcessors =
      paymentProcessor && paymentProcessor.length > 0 ? paymentProcessor.map(item => item.label) : [];
    const payload = {
      ...disputesPayload,
      paymentProcessors,
    };
    setDisputesPayload(payload);
  }, [paymentProcessor]);


  console.log()

  useEffect(() => {
    if (disputesPayload) {
      const payload = {
        ...disputesPayload,
        page: page,
        pageLength: pageLength,
      };
      console.log(payload,'this is the actual payload which is sent to the backend')
      dispatch(getNewBillingReadyDisputeListRequest(payload));
      dispatch(getNewBillingTotalAmountRequest(payload));
      dispatch(getNewBillingTotalBilledAmountRequest(payload));   
    }
  }, [page, disputesPayload]);

  useEffect(() => {
    if (callApi && isApprovedSuccess) {
      dispatch(getNewBillingReadyDisputeListRequest(disputesPayload));
      setCallApi(false);
    }
  }, [callApi, isApprovedSuccess, disputesPayload]);

  return {
    newBillingTableData,
    isLoading,
    isSuccess,
    isError,
    billedDisputes,
    isApprovedLoading,
    isApprovedSuccess,
    isApprovedError,
    open,
    setOpen,
    handleClose,
    callApi,
    setCallApi,
    newBillingTotalBilledAmount,
    newBillingTotalAmount,
    isTotalBilledLoading,
    isTotalAmountLoading,
    selectRef,
    updatedDisputeIds,
    shopList,
    setShopList,
    page,
    pageLength,
    onPageChange,
    handleDisputeApproveCharges,
    paymentProcessor,
    setPaymentProcessor,
    disputeCurrency,
    setDisputeCurrency
  };
};

export default useNewBillingTabs;
