import { takeEvery, all, takeLatest } from "redux-saga/effects";
import * as constants from "./constants";
import { postCreateUserDataRequest } from "./auth/actions";
import { postConnectPayPalRequest } from "./connectPayPal/action";
import { getAwaitingDisputesRequest, getAwaitingDisputesTotalBilledAmountRequest, getAwaitingDisputesTotalAmountRequest } from './awaitingDisputes/actions';
import { getFilteredWaitingSellerRequest } from './filteredAwaitingDisputes/actions';
import {
  getTotalRevenueProtectedDataRequest,
  getTotalRevenueProtectedByAccountDataRequest,
  getLinkedShopsRequest,
  getLinkedShopsByAccountRequest,
  getLinkedPayPalRequest,
  getLinkedPayPalByAccountRequest,
  getRecentDisputesDataRequest,
  getRecentDisputesByAccountDataRequest,
  getOpenDisputesDataRequest,
  getOpenDisputesByAccountDataRequest,
  getRecoveredDisputesDataRequest,
  getRecoveredDisputesByAccountDataRequest,
  getCurrentWeekDisputesDataRequest,
  getCurrentWeekDisputesByAccountDataRequest,
  deleteLinkedShopsRequest,
} from "./dashboard/actions";
import {
  getDisputesListDataRequest,
  getFilteredDisputesDataRequest,
  getProcessingDisputesDataRequest,
  getProcessingDisputesByAccountDataRequest,
  getAwaitingResponseDisputesDataRequest,
  getAwaitingResponseDisputesByAccountDataRequest,
  postDisputesListFetchByAccountDataRequest,
} from "./disputesList/actions";
import {
  getDisputeDetailsRequest,
  getDisputeOrderSummaryRequest,
  getDisputeTransactionDetailsRequest,
  getDisputeEvidenceDetailsRequest,
  getDisputeMessageDetailsRequest,
  getDisputeAdjudicationDetailsRequest,
  getDisputeMoneyMovementDetailsRequest,
  getDisputeSupportingInfoDetailsRequest,
  getDisputeOfferDetailsRequest,
  postDisputeFlowUploadFileRequest,
  postDisputeSendMessageRequest,
  postManageDisputeSendMessageRequest,
  getManageDisputeCarrierOptionsRequest,
  getManageDisputeEvidenceTypeOptionsRequest,
  postManageDisputeAppealDisputeRequest,
  postManageDisputeAcknowledgeReturnItemRequest,
  postManageDisputeAcceptClaimRequest,
  getManageDisputeHateoasLinkRequest,
  postManageDisputeManageManuallyRequest,
  getDisputeCustomerDetailsRequest,
  getDisputeOrderFulfillmentDetailsRequest,
  getDisputeTrackingDetailsRequest,
  getDisputeShippingDetailsRequest,
  getDisputeGetTrackingRequest,
  getResponseJsonByIdRequest,
  postDisputeSubmittedRequest,
  getManageDisputeFlowRequest,
  getFetchDisputeTemplateRequest,
  getFetchTemplateSummaryRequest,
  getDisputeFraudAnalysisRequest,
  getDisputeJiraCommentsRequest,
  // getManageDisputeFlowRequest,
} from "./disputeDetails/actions";
import { postAccountListDataRequest } from "./accountsList/actions";
import {
  postDisputeTemplateRequest,
  getDisputeTemplateListRequest,
  getDisputeTemplateReasonListRequest,
  getDisputeTemplateActionListRequest,
  getDisputeTemplateStageListRequest,
  getDisputeTemplateFindByTypeRequest,
  postDisputeTemplateFilterTemplatesRequest,
  getDisputeTemplateViewRequest,
  getDisputeTemplateFindByStoreRequest,
  getDisputeTemplatePreviewPdfRequest,
  getDisputeTemplateFetchContentRequest,
  getDisputeTemplateFullListRequest,
} from "./disputeTemplate/actions";
import {
  getShopAllShopsRequest,
  getShopFindShopRequest,
  getShopFindActiveStoresRequest,
  getShopStoreReportRequest,
  postShopsSaveAddonRequest,
  postShopSaveCompanyAddonRequest,
  postShopSaveCompanyPoliciesAddonRequest,
  postShopFilterShopsRequest,
  getShopFindShopAddonRequest,
  updateShopCollaboratorAccessRequest,
  getPreSignedUrlRequest,
} from "./stores/actions";
import {
  getDisputeFlowActionListRequest,
  getDisputeFlowFindByStoreRequest,
  getDisputeFlowFindByTypeRequest,
  getDisputeFlowListRequest,
  getDisputeFlowReasonListRequest,
  getDisputeFlowStageListRequest,
  getDisputeFlowViewRequest,
  postDisputeFlowFilterFlowsRequest,
  postDisputeFlowRequest,
  postHandledByDisputeRequest,
  postDisputeFlowDuplicateIdRequest
} from "./disputeflow/actions";

import {
  getDisputeResponseFindAllRequest,
  getDisputeResponseFindByDisputeIdRequest,
  getDisputeFlowListByPspRequest,
  getDisputeTemplateByFlowIdRequest,
  postDisputeResponseCreateRequest,
  postCreateResponseByDisputeIdTemplateId,
  getDisputeResponsePdfRequest,
  getDisputeResponseStatusSubmittedRequest,
  getDisputeResponseStatusQueuedRequest,
  getDisputeResponseStatusApprovedRequest,
  getDisputeResponseFetchContentRequest,
  postDisputeResponseSaveContentRequest,
  getAllMacrosRequest,
  getMissingMacrosRequest,
  PostSavingMissingMacrosRequest,
  postDisputeResponseUpdateStatusApprovedRequest,
  postCreateResponseRequest,
  deleteDisputeResponseRequest,
  getDisputeResponsePdfByIdRequest,
  getDisputeUpdateStatusSubmittedRequest,
} from "./disputeResponse/actions";
import {
  getDisputeFindByManagerRequest,
  getManagerListRequest,
  getShopFindByManagerRequest,
  getUserListRequest,
  postManageCreateUserRequest,
  postUpdateManagerByDisputeIdRequest,
  postUpdateManagerByShopIdRequest,
} from "./manageUser/actions";
import { getKlarnaByDisputeRequest } from "./klarna/actions";
import {
  postDisputeBillingRequest,
  postDisputeApproveRequest,
  postNewBillingListRequest,
  postDisputeReadyStatusRequest,
  getNewBillingReadyDisputeListRequest,
  getTotalBilledAmountReportListRequest,
  getTotalAmountRequest,
  getNewBillingTotalAmountRequest,
  getNewBillingTotalBilledAmountRequest,
  getReadyDisputeTotalAmountRequest,
  getReadyDisputeTotalBilledAmountRequest
} from "./billing/actions";
import { getReportingListRequest, getByStoresReportingListRequest, getByPspReportingListRequest, getByReasonsReportingListRequest, getByMonthlyReportingListRequest } from "./reports/actions";
import { getFetchUnderReviewListRequest, getFetchUnderReviewTotalBilledAmountRequest, getFetchUnderReviewTotalAmountRequest } from "./underReview/actions"
import {postDisputePaypalPullReportRequest} from "./paypalPullReports/actions"
export function* watchActions() {
  yield takeEvery(
    constants.POST_CREATE_USER_REQUEST,
    postCreateUserDataRequest
  );
  yield takeEvery(
    constants.POST_CONNECT_PAYPAL_REQUEST,
    postConnectPayPalRequest
  );
  yield takeEvery(
    constants.GET_TOTAL_REVENUE_PROTECTED_REQUEST,
    getTotalRevenueProtectedDataRequest
  );
  yield takeEvery(
    constants.GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_REQUEST,
    getTotalRevenueProtectedByAccountDataRequest
  );
  yield takeEvery(constants.GET_LINKED_SHOPS_REQUEST, getLinkedShopsRequest);
  yield takeEvery(
    constants.GET_LINKED_SHOPS_BY_ACCOUNT_REQUEST,
    getLinkedShopsByAccountRequest
  );
  yield takeEvery(
    constants.DELETE_LINKED_SHOPS_REQUEST,
    deleteLinkedShopsRequest
  );
  yield takeEvery(constants.GET_LINKED_PAYPAL_REQUEST, getLinkedPayPalRequest);
  yield takeEvery(
    constants.GET_LINKED_PAYPAL_BY_ACCOUNT_REQUEST,
    getLinkedPayPalByAccountRequest
  );
  yield takeEvery(
    constants.GET_RECENT_DISPUTES_REQUEST,
    getRecentDisputesDataRequest
  );
  yield takeEvery(
    constants.GET_RECENT_DISPUTES_BY_ACCOUNT_REQUEST,
    getRecentDisputesByAccountDataRequest
  );
  yield takeEvery(
    constants.GET_OPEN_DISPUTES_REQUEST,
    getOpenDisputesDataRequest
  );
  yield takeEvery(
    constants.GET_OPEN_DISPUTES_BY_ACCOUNT_REQUEST,
    getOpenDisputesByAccountDataRequest
  );
  yield takeEvery(
    constants.GET_RECOVERED_DISPUTES_REQUEST,
    getRecoveredDisputesDataRequest
  );
  yield takeEvery(
    constants.GET_RECOVERED_DISPUTES_BY_ACCOUNT_REQUEST,
    getRecoveredDisputesByAccountDataRequest
  );
  yield takeEvery(
    constants.GET_CURRENT_WEEK_DISPUTES_REQUEST,
    getCurrentWeekDisputesDataRequest
  );
  yield takeEvery(
    constants.GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_REQUEST,
    getCurrentWeekDisputesByAccountDataRequest
  );
  yield takeEvery(
    constants.GET_DISPUTES_LIST_REQUEST,
    getDisputesListDataRequest
  );
  yield takeEvery(
    constants.GET_FILTERED_DISPUTES_REQUEST,
    getFilteredDisputesDataRequest
  );
  yield takeEvery(
    constants.POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_REQUEST,
    postDisputesListFetchByAccountDataRequest
  );
  yield takeEvery(
    constants.GET_PROCESSING_DISPUTES_REQUEST,
    getProcessingDisputesDataRequest
  );
  //seller-await - response 
  yield takeEvery(
    constants.GET_AWAITING_DISPUTES_REQUEST,
    getAwaitingDisputesRequest
  );

  yield takeEvery(
    constants.GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_REQUEST,
    getAwaitingDisputesTotalBilledAmountRequest
  );
  yield takeEvery(
    constants.GET_AWAITING_DISPUTES_TOTAL_AMOUNT_REQUEST,
    getAwaitingDisputesTotalAmountRequest
  );

  // filtered - await -seller-response
  yield takeEvery(
    constants.GET_FILTERED_WAITING_SELLER_REQUEST,
    getFilteredWaitingSellerRequest
  )
  yield takeEvery(
    constants.GET_PROCESSING_DISPUTES_BY_ACCOUNT_REQUEST,
    getProcessingDisputesByAccountDataRequest
  );
  yield takeEvery(
    constants.GET_AWAITING_RESPONSE_DISPUTES_REQUEST,
    getAwaitingResponseDisputesDataRequest
  );
  yield takeEvery(
    constants.GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_REQUEST,
    getAwaitingResponseDisputesByAccountDataRequest
  );
  //dispute-details
  yield takeEvery(
    constants.GET_DISPUTE_DETAILS_REQUEST,
    getDisputeDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_ORDER_SUMMARY_REQUEST,
    getDisputeOrderSummaryRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TRANSACTION_DETAILS_REQUEST,
    getDisputeTransactionDetailsRequest
  );

  yield takeEvery(
    constants.GET_DISPUTE_CUSTOMER_DETAILS_REQUEST,
    getDisputeOrderFulfillmentDetailsRequest
  );

  yield takeEvery(
    constants.GET_DISPUTE_TRACKING_DETAILS_REQUEST,
    getDisputeTrackingDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TRACKING_REQUEST,
    getDisputeGetTrackingRequest
  );
  yield takeEvery(
    constants.GET_RESPONSE_JSON_BY_ID_REQUEST,
    getResponseJsonByIdRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST,
    getDisputeFraudAnalysisRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_SHIPPING_DETAILS_REQUEST,
    getDisputeShippingDetailsRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_SUBMITTED_REQUEST,
    postDisputeSubmittedRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FULFILLMENT_DETAILS_REQUEST,
    getDisputeCustomerDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_JIRA_CUSTOMERS_REQUEST,
    getDisputeJiraCommentsRequest
  );

  yield takeEvery(
    constants.GET_DISPUTE_EVIDENCE_DETAILS_REQUEST,
    getDisputeEvidenceDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_MESSAGE_DETAILS_REQUEST,
    getDisputeMessageDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST,
    getDisputeAdjudicationDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST,
    getDisputeMoneyMovementDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST,
    getDisputeSupportingInfoDetailsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_OFFER_DETAILS_REQUEST,
    getDisputeOfferDetailsRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_FLOW_UPLOAD_FILE_REQUEST,
    postDisputeFlowUploadFileRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_SEND_MESSAGE_REQUEST,
    postDisputeSendMessageRequest
  );
  yield takeEvery(
    constants.POST_ACCOUNT_LIST_REQUEST,
    postAccountListDataRequest
  );
  yield takeEvery(
    constants.POST_MANAGE_DISPUTE_SEND_MESSAGE_REQUEST,
    postManageDisputeSendMessageRequest
  );
  yield takeEvery(
    constants.GET_MANAGE_DISPUTE_CARRIER_OPTIONS_REQUEST,
    getManageDisputeCarrierOptionsRequest
  );
  yield takeEvery(
    constants.GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_REQUEST,
    getManageDisputeEvidenceTypeOptionsRequest
  );
  yield takeEvery(
    constants.POST_MANAGE_DISPUTE_APPEAL_DISPUTE_REQUEST,
    postManageDisputeAppealDisputeRequest
  );
  yield takeEvery(
    constants.POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_REQUEST,
    postManageDisputeAcknowledgeReturnItemRequest
  );
  yield takeEvery(
    constants.POST_MANAGE_ACCEPT_CLAIM_REQUEST,
    postManageDisputeAcceptClaimRequest
  );
  yield takeEvery(
    constants.GET_MANAGE_DISPUTE_HATEOAS_LINK_REQUEST,
    getManageDisputeHateoasLinkRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_MANAGE_FLOW_REQUEST,
    getManageDisputeFlowRequest
  );
  yield takeEvery(
    constants.GET_FETCH_DISPUTE_TEMPLATE_REQUEST,
    getFetchDisputeTemplateRequest
  );
  yield takeEvery(
    constants.GET_FETCH_DISPUTE_SUMMARY_REQUEST,
    getFetchTemplateSummaryRequest
  );
  yield takeEvery(
    constants.POST_MANAGE_DISPUTE_MANAGE_MANUALLY_REQUEST,
    postManageDisputeManageManuallyRequest
  );
  //dispute-template
  yield takeEvery(
    constants.POST_DISPUTE_TEMPLATE_REQUEST,
    postDisputeTemplateRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_LIST_REQUEST,
    getDisputeTemplateListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_FULL_LIST_REQUEST,
    getDisputeTemplateFullListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_REASON_LIST_REQUEST,
    getDisputeTemplateReasonListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_ACTION_LIST_REQUEST,
    getDisputeTemplateActionListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_STAGE_LIST_REQUEST,
    getDisputeTemplateStageListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_REQUEST,
    getDisputeTemplateFindByTypeRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_FIND_BY_STORE_REQUEST,
    getDisputeTemplateFindByStoreRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_REQUEST,
    postDisputeTemplateFilterTemplatesRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_VIEW_REQUEST,
    getDisputeTemplateViewRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_PREVIEW_PDF_REQUEST,
    getDisputeTemplatePreviewPdfRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_FETCH_CONTENT_REQUEST,
    getDisputeTemplateFetchContentRequest
  );
  //dispute-response
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_FIND_ALL_REQUEST,
    getDisputeResponseFindAllRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_REQUEST,
    getDisputeResponseFindByDisputeIdRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_LIST_BY_PSP_REQUEST,
    getDisputeFlowListByPspRequest
  );
  
  yield takeEvery(
    constants.GET_DISPUTE_TEMPLATE_BY_FLOWID_REQUEST,
    getDisputeTemplateByFlowIdRequest
  );

  yield takeEvery(
    constants.POST_DISPUTE_RESPONSE_CREATE_REQUEST,
    postDisputeResponseCreateRequest
  );
  yield takeEvery(
    constants.POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_REQUEST,
    postCreateResponseByDisputeIdTemplateId
  );

  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_PDF_REQUEST,
    getDisputeResponsePdfRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_PDF_BY_ID_REQUEST,
    getDisputeResponsePdfByIdRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST,
    getDisputeResponseStatusSubmittedRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_REQUEST,
    getDisputeUpdateStatusSubmittedRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST,
    getDisputeResponseStatusQueuedRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST,
    getDisputeResponseStatusApprovedRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_RESPONSE_FETCH_CONTENT_REQUEST,
    getDisputeResponseFetchContentRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_RESPONSE_SAVE_CONTENT_REQUEST,
    postDisputeResponseSaveContentRequest
  );
  yield takeEvery(
    constants.GET_ALL_MACROS_REQUEST,
    getAllMacrosRequest
  );
  yield takeEvery(
    constants.GET_MISSING_MACROS_REQUEST,
    getMissingMacrosRequest
  );
  yield takeEvery(
    constants.POST_SAVING_MISSING_MACROS_REQUEST,
    PostSavingMissingMacrosRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_RESPONSE_UPDATE_STATUS_REQUEST,
    postDisputeResponseUpdateStatusApprovedRequest
  );
  yield takeEvery(
    constants.POST_CREATE_RESPONSE_REQUEST,
    postCreateResponseRequest
  );
  yield takeEvery(
    constants.DELETE_DISPUTE_RESPONSE_REQUEST,
    deleteDisputeResponseRequest
  );
  //stores-shop
  yield takeEvery(constants.GET_SHOP_ALL_SHOPS_REQUEST, getShopAllShopsRequest);
  yield takeEvery(constants.GET_SHOP_FIND_SHOP_REQUEST, getShopFindShopRequest);
  yield takeEvery(constants.GET_SHOP_FIND_ACTIVE_STORES_REQUEST, getShopFindActiveStoresRequest);
  yield takeEvery(constants.GET_SHOP_STORE_REPORT_REQUEST, getShopStoreReportRequest);
  yield takeEvery(
    constants.POST_SHOP_FILTER_SHOPS_REQUEST,
    postShopFilterShopsRequest
  );
  yield takeEvery(
    constants.POST_SHOPS_SAVE_ADD_ON_REQUEST,
    postShopsSaveAddonRequest
  );
  yield takeEvery(
    constants.GET_SHOP_FIND_SHOP_ADD_ON_REQUEST,
    getShopFindShopAddonRequest
  );
  yield takeEvery(constants.GET_PRE_SIGNED_URL_REQUEST, getPreSignedUrlRequest);
  yield takeEvery(
    constants.UPDATE_SHOP_COLLABORATOR_ACCESS_REQUEST,
    updateShopCollaboratorAccessRequest
  );
  //dispute-flow
  yield takeEvery(constants.POST_DISPUTE_FLOW_REQUEST, postDisputeFlowRequest);
  yield takeEvery(constants.POST_HANDLED_BY_DISPUTE_REQUEST, postHandledByDisputeRequest);
  yield takeEvery(constants.POST_DISPUTE_FLOW_DUPLICATE_ID_REQUEST, postDisputeFlowDuplicateIdRequest);
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_LIST_REQUEST,
    getDisputeFlowListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_REASON_LIST_REQUEST,
    getDisputeFlowReasonListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_ACTION_LIST_REQUEST,
    getDisputeFlowActionListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_STAGE_LIST_REQUEST,
    getDisputeFlowStageListRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_FIND_BY_TYPE_REQUEST,
    getDisputeFlowFindByTypeRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_FIND_BY_STORE_REQUEST,
    getDisputeFlowFindByStoreRequest
  );
  yield takeEvery(constants.GET_LIST_MANAGER_REQUEST, getManagerListRequest);
  yield takeEvery(
    constants.POST_UPDATE_MANAGER_BY_SHOPID_REQUEST,
    postUpdateManagerByShopIdRequest
  );
  yield takeEvery(
    constants.POST_UPDATE_MANAGER_BY_DISPUTEID_REQUEST,
    postUpdateManagerByDisputeIdRequest
  );

  yield takeEvery(
    constants.GET_SHOP_FIND_BY_MANAGER_REQUEST,
    getShopFindByManagerRequest
  );

  yield takeEvery(
    constants.GET_DISPUTE_FIND_BY_MANAGER_REQUEST,
    getDisputeFindByManagerRequest
  );

  yield takeEvery(constants.GET_USER_LIST_REQUEST, getUserListRequest);

  yield takeEvery(
    constants.POST_MANAGE_CREATE_USER_REQUEST,
    postManageCreateUserRequest
  );

  yield takeEvery(
    constants.POST_DISPUTE_FLOW_FILTER_FLOWS_REQUEST,
    postDisputeFlowFilterFlowsRequest
  );
  yield takeEvery(
    constants.GET_DISPUTE_FLOW_VIEW_REQUEST,
    getDisputeFlowViewRequest
  );

  yield takeEvery(
    constants.POST_SHOPS_SAVE_ADD_ON_REQUEST,
    postShopsSaveAddonRequest
  );
  yield takeEvery(
    constants.POST_SHOP_SAVE_COMPANY_ADD_ON_REQUEST,
    postShopSaveCompanyAddonRequest
  );
  yield takeEvery(
    constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_REQUEST,
    postShopSaveCompanyPoliciesAddonRequest
  );

  yield takeEvery(
    constants.GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_REQUEST,
    getKlarnaByDisputeRequest
  );
  // billing
  yield takeEvery(
    constants.POST_DISPUTE_BILLING_REQUEST,
    postDisputeBillingRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_APPROVE_REQUEST,
    postDisputeApproveRequest
  );
  yield takeEvery(
    constants.POST_DISPUTE_READY_STATUS_REQUEST,
    postDisputeReadyStatusRequest
  );
  yield takeEvery(
    constants.POST_NEW_BILLING_LIST_REQUEST,
    postNewBillingListRequest
  );
  
  yield takeEvery(
    constants.POST_DISPUTE_PAYPAL_PULL_REPORT_REQUEST,
    postDisputePaypalPullReportRequest
  );

  yield takeEvery(
    constants.GET_NEW_BILLING_READY_DISPUT_REQUEST,
    getNewBillingReadyDisputeListRequest
  );
  yield takeEvery(
    constants.GET_TOTAL_BILLED_AMOUNT_REQUEST,
    getTotalBilledAmountReportListRequest
  );
  yield takeEvery(constants.GET_TOTAL_AMOUNT_REQUEST, getTotalAmountRequest);
  yield takeEvery(constants.GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_REQUEST, getNewBillingTotalBilledAmountRequest);
  yield takeEvery(constants.GET_NEW_BILLING_TOTAL_AMOUNT_REQUEST, getNewBillingTotalAmountRequest);
  yield takeEvery(constants.GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_REQUEST, getReadyDisputeTotalBilledAmountRequest);
  yield takeEvery(constants.GET_READY_DISPUTE_TOTAL_AMOUNT_REQUEST, getReadyDisputeTotalAmountRequest);

  //Reporting
  yield takeEvery(constants.GET_REPORTING_REQUEST, getReportingListRequest);
  yield takeEvery(constants.GET_BY_STORES_REPORTING_REQUEST, getByStoresReportingListRequest);
  yield takeEvery(constants.GET_BY_PSP_REPORTING_REQUEST, getByPspReportingListRequest);
  yield takeEvery(constants.GET_BY_REASONS_REPORTING_REQUEST, getByReasonsReportingListRequest);
  yield takeEvery(constants.GET_BY_MONTHLY_REPORTING_REQUEST, getByMonthlyReportingListRequest);

  // under review disputes
  yield takeEvery(constants.GET_FETCH_UNDER_REVIEW_DIPSUTES_REQUEST, getFetchUnderReviewListRequest);
  yield takeEvery(constants.GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_REQUEST, getFetchUnderReviewTotalBilledAmountRequest);
  yield takeEvery(constants.GET_UNDER_REVIEW_TOTAL_AMOUNT_REQUEST, getFetchUnderReviewTotalAmountRequest);

}

export default function* rootSaga() {
  yield all([watchActions()]);
}
