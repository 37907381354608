import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from '../../../configs/index';
export class PostPaypalPullReport {
    /**
     * Function to invoke to get account list
     */
    async invokePaypalPullReports(payload: any) {
        const res = await ApiService.getInstance().post(apiConstants.POST_PAYPAL_PULL_REPORT(payload), payload.body, { baseURL: configs.BASE_URL as string });
        return res;
    }

}
