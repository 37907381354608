import { combineReducers } from "redux";
import authStore from './auth/reducer'
import connectPayPalStore from './connectPayPal/reducer';
import dashboard from './dashboard/reducer';
import disputesList from "./disputesList/reducer";
import disputeDetailsStore from "./disputeDetails/reducer";
import accountsData from "./accountsList/reducer";
import disputeTemplate from "./disputeTemplate/reducer";
import disputeFlow from "./disputeflow/reducer";
import storesShop from "./stores/reducer";
import disputeResponse from "./disputeResponse/reducer";
import manageUser from "./manageUser/reducer";
import klarnaStore from "./klarna/reducer";
import billing from "./billing/reducer";
import reporting from "./reports/reducer";
import awaitingDisputes from "./awaitingDisputes/reducer";
import filteredAwaitingDisputes from './filteredAwaitingDisputes/reducer';
import underReviewDisputes from "./underReview/reducer"
import paypalPullReports from "./paypalPullReports/reducer"

const appReducer = combineReducers({
  authStore,
  connectPayPalStore,
  dashboard,
  disputesList,
  disputeDetailsStore,
  accountsData,
  disputeTemplate,
  storesShop,
  disputeFlow,
  disputeResponse,
  manageUser,
  klarnaStore,
  billing,
  reporting,
  awaitingDisputes,
  filteredAwaitingDisputes,
  underReviewDisputes,
  paypalPullReports
});

// HERE, CLEAERING THE WHOLE STATE AS FRESH WHILE LOGGING OUT THE USER.
const makeRootReducer = (state: any, action: any) => {

  return appReducer(state, action);
};

export default makeRootReducer;