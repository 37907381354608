import { Button } from '@mui/material';
import styles from './style.module.scss';
import { Textarea } from 'components/ui';
import { useSelector } from 'react-redux';
import { useResponseSaveContent } from 'hooks';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Loading from 'components/loading/loading';
import { useDispatch } from 'react-redux';
import {
  getDisputeResponseFetchContentRequest,
  getDisputeResponseFindByDisputeIdRequest,
} from 'redux/actions';
interface DisputeResponseSummaryProp {
  disputeResponseSummary: {
    id: number;
    disputeId: string;
    disputeTemplateId: number;
    fields: any;
    missingFields: string | any;
    products: any;
    submittedAt: string;
    status: string;
  };
  isSubmitted: boolean;
}

const DisputeResponseSummary = ({
  disputeResponseSummary,
  isSubmitted,
}: DisputeResponseSummaryProp) => {
  const { data, isSuccess, isLoading }: any = useSelector(
    (state: any) => state?.disputeResponse?.fetchedContent
  );
  const [apiCall, setApiCall] = useState(false);
  const dispatch = useDispatch();
  const {
    handleUpdateSaveContent,
    isSavedContentLoading,
    isSavedContentSuccess,
  } = useResponseSaveContent();
  const [previewSummaryContent, setPreviewSummaryContent] = useState('');
  const handleValidate = () => {
    const updatedEditorContent = data?.htmlContent
      .replace(/<br>/g, '<br/>')
      .replace(/<col>/g, '<col/>');
    const payload = {
      disputeResponseId: disputeResponseSummary?.id,
      htmlContent: updatedEditorContent,
      summaryText: previewSummaryContent,
    };
    handleUpdateSaveContent(payload);
    setApiCall(true);
  };
  useEffect(() => {
    if (apiCall) {
      dispatch(
        getDisputeResponseFindByDisputeIdRequest(
          disputeResponseSummary?.disputeId
        )
      );
    }
    setApiCall(false);
  }, [apiCall]);
  useEffect(() => {
    if (!isEmpty(data?.summaryContent) && isSuccess) {
      setPreviewSummaryContent(data?.summaryContent);
    }
  }, [data?.summaryContent]);

  useEffect(() => {
    if (isSavedContentSuccess) {
      dispatch(getDisputeResponseFetchContentRequest(data?.disputeResponseId));
      // here isSavaedContentSuccess should be false after fetching data
    }
  }, [isSavedContentSuccess]);

  return (
    <div className={styles.disputeWrapper}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {' '}
          <div className={styles.manageHead}>
            <h5>Dispute response Summary</h5>
            <div className={styles.headButtons}>
              {/* <div className={styles.actionButtons}>
                <Button
                  variant="outlined"
                  onClick={handleValidate}
                  className={styles.queue}
                >
                  Validate
                </Button>
                <Button variant="outlined" className={styles.review}>
                  <img src={svgIcons.Eye_2} alt="review" />
                  <p>Review</p>
                </Button>
              </div> */}
              {!isSubmitted && (
                <div className={styles.actionButtons}>
                  <Button
                    onClick={handleValidate}
                    variant='contained'
                    className={styles.send}
                    style={{
                      background: isSavedContentLoading
                        ? 'rgba(0, 0, 0, 0.12)'
                        : '',
                    }}
                  >
                    {isSavedContentLoading ? 'Loading...' : 'Save Changes'}
                  </Button>
                  {disputeResponseSummary.status === 'READY_FOR_SUBMISSION' && (
                    <Button
                      onClick={handleValidate}
                      variant='contained'
                      className={styles.send}
                    >
                      Send
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.disputePreview}>
            <Textarea
              minRows={8}
              style={{ overflowY: 'scroll' }}
              onChange={e => setPreviewSummaryContent(e.target.value)}
              defaultValue={data?.summaryContent ?? ''}
              aria-label='maximum height'
              placeholder='Type here...'
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DisputeResponseSummary;
