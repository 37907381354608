

/**
 * this is the custom hook for shop filter
 * It can be working anythere where date filter is requried just pass the disputesPayload and setDisputesPayload and aslo setPage as props
 * working properly
 */

import { IStoreOptions } from "hooks/billingReports/useNewBilling"
import { useEffect, useState } from "react"


const useShopFilter = (disputesPayload:any,setDisputesPayload:any,setPage:any)=>{
  const [shopList,setShopList] = useState<IStoreOptions[]>([])

  useEffect(() => {
    setPage(1)
    const shopIds =
      shopList && shopList.length > 0 ? shopList.map(item => item.value) : [];
    const payload = {
      ...disputesPayload,
      shopIds,
    };
    setDisputesPayload(payload);
  }, [shopList]);

  return {
    shopList,
    setShopList,
  }

}


export default useShopFilter










