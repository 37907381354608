import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getDisputeResponseFindByDisputeIdRequest } from "redux/actions";

export type DisputeResponseTableData = {
  approvedAt:null,
  approvedBy:null,
  createdBy:string;
  createdAt:string;
  disputeId:string;
  disputeTemplate:string;
  disputeTemplateId:number;
  fields:any;
  flowId:number;
  id:number;
  lastUpdatedAt:string;
  lastUpdatedBy:string;
  missingFields:string;
  predictedWinningScore:number;
  products:null,
  shippingDate:null,
  status:string;
  storeOrderId:null;
  submissionMode:string;
  submittedAt:null;
  submittedBy:null,
  textValue:null;
  trackingId:string;
  withAttachment:boolean
}

export type DisputeResponseState = {
  isLoading:boolean;
  isSuccess:boolean;
  isError:boolean;
  data:DisputeResponseTableData[]
}

const useDisputeResponseList = (disputeId:any)=>{
  const dispatch = useDispatch()
  const disputeResponseState : DisputeResponseState = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  const {isLoading,isSuccess,isError,data} = disputeResponseState

  useEffect(() => {
    if (disputeId){
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);

 
  return {
    isLoading,
    isSuccess,
    isError,
    data
  }

}



export default useDisputeResponseList