import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { Button, TextField, Tooltip } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../loading/loading';
import {
  postDisputeResponseCreateRequest,
  postDisputeTemplateFilterTemplatesRequest,
  postManageDisputeSendMessageRequest,
} from '../../../../redux/actions';
import styles from './style.module.scss';
import { ErrorMessage } from 'components/disputeFlow/NodeHelpers';
import { FileUploader } from 'react-drag-drop-files';
import { svgIcons } from 'elements';
import { Textarea } from 'components/ui';
import { useSendMessage } from 'hooks';

const fileTypes = ['PNG', 'JPG', 'JPEG', 'PDF'];
type FormType = {
  file: any;
  message: string;
};

const SendMessage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { disputeId } = useParams();
  const { onFormSubmit } = useSendMessage();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormType>();

  const onSubmit = async (data: any) => {
    onFormSubmit(data?.message,data?.file,disputeId)
  };

  const handleFileUpload = (file: any) => {
    setUploadFile(file);
    setValue('file', file); // by this setValue we get this file data to the onSubmit function
  };

  return (
    <div className={styles.formWrapper}>
      <ToastContainer />
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.initialSelect}>
            <Tooltip
              arrow
              placement='top'
              title='Upload or drag and drop the file'
            >
              <div className={styles.uploadFile}>
                <label>Uncategorized File</label>
                <div className={''}>
                  <FileUploader
                    multiple={false}
                    handleChange={handleFileUpload}
                    name='file'
                    types={fileTypes}
                    children={
                      <div className={styles.documentField}>
                        <img
                          style={{ display: uploadFile ? 'none' : '' }}
                          src={svgIcons.Upload_In_Blue}
                          alt='upload'
                        />
                        <p style={{ display: uploadFile ? 'none' : '' }}>
                          Uncategorized File
                        </p>
                        <input
                          data-testid='dispute-document'
                          hidden={!uploadFile}
                          readOnly
                          value={uploadFile ? uploadFile.name : ''}
                        />
                      </div>
                    }
                  />
                </div>
                <ErrorMessage name={'file'} errors={errors} />
              </div>
            </Tooltip>

            <div className={styles.textAreaWrarpper}>
              <label>Send Message</label>
              <Textarea
                minRows={9}
                placeholder='enter your text'
                {...register('message', {
                  required: 'this field is required',
                })}
              />
              <ErrorMessage name={'message'} errors={errors} />
            </div>
            <Button variant='contained' size='small' type='submit'>
              Send Message
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SendMessage;
