import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface disputeFlowRes {
    data: {
        "id": number,
        "content": string,
        "type": string,
        "description": string,
        "enabled": boolean,
        "category": string,
        "paymentGateway": string
    }
    status:number;
}

export function* postDisputeFlowRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.postDisputeFlow(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.postDisputeFlowError())
        }
        yield put(actions.postDisputeFlowSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeFlowError());
    }
}

export function* postHandledByDisputeRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.postHandledByDisputeRequest(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.postHandledByDisputeError())
        }
        yield put(actions.postHandledByDisputeSuccess(res.data));
    } catch (e) {
        yield put(actions.postHandledByDisputeError());
    }
}

export function* postDisputeFlowDuplicateIdRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.postDisputeFlowDupicateId(action.payload);
        if(res.status === (500 || 401)){
            yield put(actions.postDisputeFlowDuplicateIdError())
        }
        yield put(actions.postDisputeFlowDuplicateIdSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeFlowDuplicateIdError());
    }
}

export function* getDisputeFlowListRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowList(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowListError());
        }else {
            yield put(actions.getDisputeFlowListSuccess(res));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowListError());
    }
}

export function* getDisputeFlowReasonListRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowReasonList(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowReasonListError());
        }else {
            yield put(actions.getDisputeFlowReasonListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowReasonListError());
    }
}

export function* getDisputeFlowActionListRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowActionList(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowActionListError());
        }else {
            yield put(actions.getDisputeFlowActionListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowActionListError());
    }
}

export function* getDisputeFlowStageListRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowStageList(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowStageListError());
        }else {
            yield put(actions.getDisputeFlowStageListSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowStageListError());
    }
}

export function* getDisputeFlowFindByTypeRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowFindByType(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowFindByTypeError());
        }else {
            yield put(actions.getDisputeFlowFindByTypeSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowFindByTypeError());
    }
}

export function* getDisputeFlowFindByStoreRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowFindByStore(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowFindByStoreError());
        }else {
            yield put(actions.getDisputeFlowFindByStoreSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowFindByStoreError());
    }
}

export function* postDisputeFlowFilterFlowsRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.postDisputeFlowFilterFlows(action.payload);
        yield put(actions.postDisputeFlowFilterFlowsSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeFlowFilterFlowsError());
    }
}

export function* getDisputeFlowViewRequest(action: any) {
    try {
        const res: disputeFlowRes = yield Api.getDisputeFlowView(action.payload);
        if(res.status === (401 || 500)){
            yield put(actions.getDisputeFlowViewError());
        }else {
            yield put(actions.getDisputeFlowViewSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowViewError());
    }
}
