import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getDisputeFlowListByPspRequest,getDisputeTemplateByFlowIdRequest } from "redux/actions";


type DisputeFlowListPspType = {
  isLoading:boolean;
  isSuccess:boolean;
  isError:boolean;
  data:any
}

const useDisputeList = (paymentProcessor:any,flowId:any)=>{
  const dispatch = useDispatch()
  const disputeFlowListPsp:DisputeFlowListPspType  = useSelector(
    (state: any) => state?.disputeResponse?.disputeFlowListByPsp
  );
  
  const disputeFlowListTemplateFlowId:DisputeFlowListPspType = useSelector(
    (state: any) => state?.disputeResponse?.disputeTemplateFlowId
  );
  useEffect(()=>{
    if (paymentProcessor){
      dispatch(getDisputeFlowListByPspRequest(paymentProcessor))
    }
  },[paymentProcessor])

  useEffect(()=>{
    if (flowId){
      dispatch(getDisputeTemplateByFlowIdRequest(flowId))
    }
  },[flowId])

  return {
    disputeFlowListPsp,
    disputeFlowListTemplateFlowId
  }

}

export default useDisputeList