import {
  SingleAccountBreadcrumb,
  Pagination,
} from 'components';
import styles from './style.module.scss';
import { BreadcumbItem } from 'components/ui';
import { getItem } from 'utils/localStorage';
import {
  useBreadCumbs,
  useStoreReportList,
} from 'hooks';
import Loading from 'components/loading/loading';
import StoreReportList from 'components/storeReports/StoreReportList';

const storeListHeading = [
  'Shop Name',
  'Shop Domain',
  'Company Name',
  'Background',
  'Refund Policy',
  'Terms and Conditions',
  'Delivery Policy',
  'Refund Policy Url',
  'Terms and Conditions Url',
  'Delivery Policy Url',
  'Cancellation Time Frame Image Url',
  'Shipping Policy Image Url',
  'Delivery Time Frame',
  'Cancellation Time Frame',
  'Delivery Policy Image Url',
  'Refund Policry Image Url',
  'Terms and Conditions Image Url',
  'Sizing Guide Url',
  'Sizing Guide Image Url',
  'Contact Page Url',
  'Contact Page Image Url',
  'Instragram account',
  'Facebook account',
  'Youtube account',
  'Pinterest account',
  'Tiktok account',
  'Linkedin account',
  'Twitter account',
];
const StoreReports = () => {
  const SingleAccountCheck = getItem('singleAccountCheck');
  const { breadCumbs } = useBreadCumbs({ pageName: 'Store Reports' });

  const { data, isLoading, isError, isSuccess, pageLength, page, onPageChange } =
    useStoreReportList();

  return (
    <div className={styles.disputesWrapper}>
      {SingleAccountCheck === 'true' ? (
        <SingleAccountBreadcrumb />
      ) : (
        <div className={styles.nav}>
          <h3>Store Reports</h3>
          <div className={styles.navPath}>
            {breadCumbs?.map((item, key) => (
              <BreadcumbItem key={key} {...item} />
            ))}
          </div>
        </div>
      )}

      <div>
        {isLoading ? (
          <div style={{ background: '#fff', borderRadius: '8px' }}>
            <Loading />
          </div>
        ) : (
          <StoreReportList
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            tableHeading={storeListHeading}
            data={data}
          />
        )}    
      </div>
      {Math.ceil(data?.count / pageLength) > 1 && (
        <div>
          <Pagination
            onPageChange={pageNum => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(data?.count / pageLength)}
          />
        </div>
      )}
    </div>
  );
};

export default StoreReports;
