import { Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './style.module.scss';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import TiptapEditor from 'components/disputeTemplate/TiptapEditor';
import { usePdfDownloadResponse, useResponseSaveContent } from 'hooks';
import { isEmpty } from 'lodash';
import Loading from 'components/loading/loading';
import { useDispatch } from 'react-redux';
import { FullscreenExit, Fullscreen } from '@mui/icons-material';
import {
  getDisputeResponseFetchContentRequest,
  getDisputeResponseFindByDisputeIdRequest,
  getDisputeUpdateStatusSubmittedRequest,
} from 'redux/actions';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import './sweetAlert.scss';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useDisputeToggle } from 'context/DisputeHandleContext';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface disputeResponsePreviewProp {
  disputeResponsePreview: {
    id: number;
    disputeId: string;
    disputeTemplateId: number;
    fields: any;
    missingFields: string | any;
    products: any;
    submittedAt: string;
    status: string;
  };
  isSubmitted: boolean;
}

const DisputeResponsePreview = ({
  disputeResponsePreview,
  isSubmitted,
}: disputeResponsePreviewProp) => {
  const dispatch = useDispatch();
  const { data, isSuccess, isLoading } = useSelector(
    (state: any) => state?.disputeResponse?.fetchedContent
  );
  const disputeResponseFindByDisputeId = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponseFindByDisputeId
  );
  //  function for download pdf from custom hooks
  const { handleDownloadPdfResponse } = usePdfDownloadResponse();
  const { disputeDetails } = useSelector(
    (state: any) => state?.disputeDetailsStore
  );

// this is the toggle state from our toggle context provider
  const {toggleState} = useDisputeToggle();

  // Check if the disputeId is in the toggleState object and if its value is true then button will be not disable if it's value is false then button will be disable
  const isDisableButton = toggleState[disputeResponsePreview?.disputeId] === false ? true : false;

  const { updateResponseStatusSubmitted } = useSelector(
    (state: any) => state?.disputeResponse
  );
  const paymentProcessor = disputeDetails?.data?.paymentProcessor;
  const {
    handleUpdateSaveContent,
    data: responseData,
    isSavedContentSuccess,
    isSavedContentLoading,
  } = useResponseSaveContent();
  const [previewEditorContent, setPreviewEditorContent] = useState('');
  const [apiCall, setApiCall] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);

  // const htmlContent = previewEditorContent?.replace(/<\/img>/g, '');
  // const modifiedHtmlContent = htmlContent
  //   .replace(/<img(\s[^>]*?)?>/g, '<img$1/>')
  //   .replace(/<col(\s[^>]*?)?>/g, '<col$1/>')
  //   .replace(/<br(?!\/)>/g, '<br/>');

  // const uniqueImages = new Set();
  // const noDuplicateImages = modifiedHtmlContent.replace(
  //   /<img\s+[^>]*src="([^"]+)"[^>]*\/>/g,
  //   (match, src) => {
  //     if (uniqueImages.has(src)) {
  //       return ''; // Skip duplicate images
  //     } else {
  //       uniqueImages.add(src);
  //       return match; // Keep the first occurrence
  //     }
  //   }
  // );

  // const finalHtmlContent = noDuplicateImages.replace(/(<br\/>\s*){2,}/g, '<br/>');

  // remove the extra image issue
  const htmlContent = previewEditorContent?.replace(/<\/img>/g, '');
  const modifiedHtmlContent = htmlContent
    .replace(/<img(\s[^>]*?)?>/g, '<img$1/>')
    .replace(/<col(\s[^>]*?)?>/g, '<col$1/>')
    .replace(/<br(?!\/)>/g, '<br/>');

  const uniqueImageSources = new Set();
  const noDuplicateImages = modifiedHtmlContent.replace(
    /<img\s+[^>]*src="([^"]+)"[^>]*\/>/g,
    (match, src) => {
      if (uniqueImageSources.has(src)) {
        return ''; // Skip duplicate images
      } else {
        uniqueImageSources.add(src);
        return match; // Keep the first occurrence
      }
    }
  );

  const finalHtmlContent = noDuplicateImages.replace(
    /(<br\/>\s*){2,}/g,
    '<br/>'
  );
  console.log({ finalHtmlContent }, 'this is the final htmlContent');

  const handleValidate = () => {
    const payload = {
      disputeResponseId: disputeResponsePreview?.id,
      htmlContent: finalHtmlContent ?? data?.htmlContent,
      summaryText: data?.summaryContent,
    };
    handleUpdateSaveContent(payload);
    setApiCall(true);
  };
  console.log({ updateResponseStatusSubmitted });
  useEffect(() => {
    if (apiCall) {
      dispatch(
        getDisputeResponseFindByDisputeIdRequest(
          disputeResponsePreview?.disputeId
        )
      );
    }
    setApiCall(false);
  }, [apiCall]);

  const handleApprove = () => {
    Swal.fire({
      title: `Are you aware of this button which will publish the response to ${paymentProcessor}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Approve it!',
      customClass: {
        title: 'customPopupTitle',
      },
    }).then(result => {
      if (result.isConfirmed) {
        setCloseAlert(true);
        dispatch(
          getDisputeUpdateStatusSubmittedRequest(disputeResponsePreview?.id)
        );
      }
    });
  };
  useEffect(() => {
    if (updateResponseStatusSubmitted?.isSuccess && closeAlert) {
      Swal.fire({
        title: 'Approve!',
        text: 'Dispute status has been approved!',
        icon: 'success',
      }).then(result => {
        if (result.isConfirmed || result.isDismissed) {
          setCloseAlert(false);
        }
      });
    }
    if (updateResponseStatusSubmitted?.isError && closeAlert) {
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong!',
        icon: 'error',
      }).then(result => {
        if (result.isConfirmed || result.isDismissed) {
          setCloseAlert(false);
        }
      });
    }
  }, [
    updateResponseStatusSubmitted?.isSuccess,
    updateResponseStatusSubmitted?.isError,
    closeAlert,
  ]);
  const { isLoading: isPdfResponseLoading } = useSelector(
    (state: any) => state?.disputeResponse?.disputeResponsePdfByResponseId
  );

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(data?.htmlContent) && isSuccess) {
      setPreviewEditorContent(data?.htmlContent);
    }
  }, [data?.htmlContent]);

  useEffect(() => {
    if (isSavedContentSuccess) {
      dispatch(getDisputeResponseFetchContentRequest(data?.disputeResponseId));
      // here isSavaedContentSuccess should be false after fetching data
    }
  }, [isSavedContentSuccess]);

  return (
    <div
      className={styles.disputeWrapper}
      style={{
        position: isOpen ? 'fixed' : 'initial',
        top: isOpen ? '-19px' : '',
        left: isOpen ? '0' : '',
        background: isOpen ? '#fff' : '',
        width: isOpen ? '100%' : '',
        height: isOpen ? '100vh' : '',
        zIndex: isOpen ? '10000' : '',
        padding: isOpen ? '20px 35px' : '',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={styles.manageHead}>
            <h5>Dispute response preview</h5>
            {!isSubmitted && (
              <div className={styles.headButtons}>
                <div className={styles.actionButtons}>
                  <Tooltip
                    title={isOpen ? 'click to collapse' : 'click to expand'}
                    placement={isOpen ? 'bottom' : 'top'}
                    arrow
                    style={{ zIndex: '1000' }}
                  >
                    <Button
                      variant='contained'
                      size='small'
                      onClick={() =>
                        isOpen ? setIsOpen(false) : setIsOpen(true)
                      }
                    >
                      {isOpen ? <FullscreenExit /> : <Fullscreen />}
                    </Button>
                  </Tooltip>

                  <Button
                    variant='contained'
                    className={styles.send}
                    // onClick={handlePdfResponse}
                    onClick={() =>
                      handleDownloadPdfResponse(
                        disputeResponsePreview?.id,
                        disputeResponseFindByDisputeId?.data[0]?.disputeId,
                        disputeResponseFindByDisputeId?.data[0]
                          ?.disputeTemplateId
                      )
                    }
                    disabled={isPdfResponseLoading ? true : false}
                    style={{
                      background: isPdfResponseLoading
                        ? 'rgba(0, 0, 0, 0.12)'
                        : '',
                    }}
                  >
                    {isPdfResponseLoading ? 'loading...' : 'PDF'}
                  </Button>
                  {disputeResponsePreview?.status === 'QUEUED' && (
                    <Button
                      onClick={handleApprove}
                      variant='contained'
                      className={styles.send}
                      disabled={isDisableButton ? true : false}
                      sx={{background: isDisableButton ? "#ddd !important": "",color: isDisableButton ? "#000": ""}}
                    >
                      Approve
                    </Button>
                  )}
                  <Button
                    onClick={handleValidate}
                    variant='contained'
                    className={styles.send}
                    style={{
                      background: isSavedContentLoading
                        ? 'rgba(0, 0, 0, 0.12)'
                        : '',
                    }}
                  >
                    {isSavedContentLoading ? 'Loading...' : 'Save Changes'}
                  </Button>
                  {disputeResponsePreview.status === 'READY_FOR_SUBMISSION' && (
                    <Button
                      onClick={handleValidate}
                      variant='contained'
                      className={styles.send}
                      disabled={isDisableButton ? true : false}
                      sx={{
                        background: isDisableButton ? '#ddd !important' : '',
                        color: isDisableButton ? '#000' : '',
                      }}
                    >
                      Send
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div
            // className={styles.disputePreview}
            className={
              isOpen ? styles.disputePreviewFullScreen : styles.disputePreview
            }
            style={{ height: isOpen ? '100vh' : '' }}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <TiptapEditor
                  noToolBox
                  setEditorContent={setPreviewEditorContent}
                  editorData={data?.htmlContent}
                  fullWidth={isOpen ? true : false}
                />
                {/* <Viewer
              fileUrl="/assets/pdf-open-parameters.pdf"
              plugins={[defaultLayoutPluginInstance]}
            /> */}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DisputeResponsePreview;
