import { FC, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecentDisputesRequest,
  getRecentDisputesByAccountRequest,
} from "redux/actions";
import { getItem } from "utils/localStorage";
import { getColorByStatus } from "utils/disputeUtils";
import { BlueTick, svgIcons } from "elements";
import styles from "./style.module.scss";
import FormatCurrency from "components/formatCurrency";
import Loading from "components/loading/loading";
import { Avatar, Tooltip } from "@mui/material";
import { HtmlTooltip } from "components/ui";
import ToolTipTitle from "components/tooltip/ToolTipTitle";
import Image from "components/image";
import { DisputesData } from "hooks/disputeHooks/useDiputeList";
import dayjs from "dayjs";

const recentDisputesColumns = [
  "Dispute ID",
  "Store Name",
  "Date",
  "Amount",
  "Reason",
  "Response Due",
  "Decision Date",
  "Status",
  "Response Status",
  "Manager",
];

type RecentDisptuesProps = {
  isLoading:boolean;
  isSuccess:boolean;
  isError:boolean;
  data: DisputesData[]
}

const RecentDisputes: FC = () => {
  const dispatch = useDispatch();
  const SingleAccountCheck = getItem("singleAccountCheck");
  const { accountId } = useParams();
  const disputesData:RecentDisptuesProps = useSelector(
    (state: any) => state.dashboard?.recentDisputesData
  );
  const {isLoading,isSuccess,isError,data} = disputesData
  useEffect(() => {
    SingleAccountCheck
      ? dispatch(getRecentDisputesByAccountRequest(accountId))
      : dispatch(getRecentDisputesRequest());
  }, [SingleAccountCheck]);

  const handleRecentDisputeStatus = (disputeData: any) => {
    if (
      disputeData?.status &&
      disputeData?.status?.toLowerCase() === "resolved"
    ) {
      if (disputeData?.disputeOutcome === "RESOLVED_SELLER_FAVOUR") {
        return "Won";
      } else if (disputeData?.disputeOutcome === "RESOLVED_BUYER_FAVOUR") {
        return "Lost";
      } else {
        return "Resolved";
      }
    } else {
      return disputeData?.status && disputeData?.status?.split("_").join(" ");
    }
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        {isLoading ? (
          <div style={{ background: "#fff" }}>
            <Loading />
          </div>
        ) : (
          <div>
            {!isLoading && isSuccess && data && data?.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    {recentDisputesColumns.map((recentDisputesColumn) => (
                      <th key={recentDisputesColumn}>{recentDisputesColumn}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data
                    ?.slice(0, 10)
                    ?.map((recentDispute: DisputesData, index: number) => (
                      <tr
                        key={recentDispute?.id}
                        className={styles.tableDataRow}
                      >
                        <td data-testid="recent-dispute-id">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {recentDispute?.paymentProcessor && (
                              <>
                                {recentDispute?.submittedByChargePay && (
                                  <div
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <BlueTick />
                                  </div>
                                )}
                                <Image
                                  icon={recentDispute?.paymentProcessor}
                                  altText="payment processor"
                                />
                              </>
                            )}
                            <Link
                              className={styles.id}
                              to={`/dispute/${recentDispute?.id}`}
                            >
                              <p className={styles.recentDisputeID}>
                                {recentDispute?.id}
                              </p>
                            </Link>
                          </div>
                        </td>
                        <td className={styles.recentDisputeID}>
                          <Link
                            className={styles.id}
                            to={
                              recentDispute?.shopId
                                ? `/store-details/${recentDispute?.shopId}`
                                : "#"
                            }
                          >
                            <p className={styles.recentDisputeID}>
                              {recentDispute?.shopName ?? "Missing shop"}
                            </p>
                          </Link>
                        </td>
                        <td>
                          {
                            recentDispute?.createTime && dayjs(recentDispute?.createTime).format(
                              "MMMM DD, YYYY"
                            )
                          }
                        </td>
                        <td>
                          <FormatCurrency
                            value={Number(
                              recentDispute?.disputeAmountValue
                            )?.toFixed(2)}
                            currencyCode={
                              recentDispute?.disputeAmountCurrencyCode
                            }
                          />
                        </td>
                        <td>
                          <div className={styles.recentDisputeReason}>
                            <p>{recentDispute?.reason?.split("_").join(" ")}</p>
                          </div>
                        </td>
                        <td>
                          {recentDispute?.sellerResponseDueDate &&
                            dayjs(recentDispute?.sellerResponseDueDate).format(
                              "MMMM DD, YYYY"
                            )}
                        </td>
                        <td>
                          {recentDispute?.decisionDate &&
                            dayjs(recentDispute?.decisionDate).format(
                              "MMMM DD, YYYY"
                            )}
                        </td>

                        <td>
                          <div
                            className={styles.recentDisputeStatus}
                            style={{
                              backgroundColor:
                                recentDispute?.status &&
                                getColorByStatus(
                                  handleRecentDisputeStatus(recentDispute)
                                )?.backgroundColor,
                            }}
                          >
                            <p>
                              {recentDispute?.status &&
                                handleRecentDisputeStatus(recentDispute)}
                            </p>
                          </div>
                        </td>
                        <td>
                          {recentDispute?.responseStatus && (
                            <HtmlTooltip
                              placement="top-start"
                              arrow
                              title={
                                <ToolTipTitle toolTipDetails={recentDispute} />
                              }
                            >
                              <div
                                className={styles.reasonType}
                                style={{
                                  background:
                                    recentDispute.responseStatus === "SUBMITTED"
                                      ? "rgb(188 248 212)"
                                      : recentDispute.responseStatus ===
                                        "MISSING_INFO"
                                      ? "rgb(248 226 224)"
                                      : "rgb(252 228 154)",
                                  display: "inline-block",
                                  borderRadius: 4,
                                }}
                              >
                                <p
                                  style={{
                                    padding: "4px 8px",
                                    borderRadius: "4px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {recentDispute.responseStatus}
                                </p>
                              </div>
                            </HtmlTooltip>
                          )}
                        </td>
                        <td>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Tooltip
                              title={recentDispute?.manager ?? "manager"}
                              arrow
                              placement="top"
                            >
                              <Avatar
                                className={styles.avatarStyle}
                                sx={{
                                  width: 38,
                                  height: 38,
                                  padding: "13px",
                                  bgcolor: "#DDDFE2",
                                  marginRight: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#1D2939",
                                    fontSize: "14px",
                                  }}
                                >
                                  {recentDispute?.manager &&
                                    recentDispute?.manager.split("")[0]}
                                </span>
                                <span
                                  style={{ color: "#1D2939", fontSize: "14px" }}
                                >
                                  {recentDispute?.manager &&
                                    recentDispute?.manager.split("")[1]}
                                </span>
                              </Avatar>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              ((!isLoading && isError) ||
                (isSuccess && data?.length === 0)) && (
                <div className={styles.NoData}>
                  <img src={svgIcons.Coffee_Break} alt="disputes" />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default RecentDisputes;
