export const POST_CREATE_USER_REQUEST = "POST_CREATE_USER_REQUEST";
export const POST_CREATE_USER_SUCCESS = "POST_CREATE_USER_SUCCESS";
export const POST_CREATE_USER_ERROR = "POST_CREATE_USER_ERROR";

export const POST_CONNECT_PAYPAL_REQUEST = "POST_CONNECT_PAYPAL_REQUEST";
export const POST_CONNECT_PAYPAL_SUCCESS = "POST_CONNECT_PAYPAL_SUCCESS";
export const POST_CONNECT_PAYPAL_ERROR = "POST_CONNECT_PAYPAL_ERROR";

export const GET_TOTAL_REVENUE_PROTECTED_REQUEST = "GET_TOTAL_REVENUE_PROTECTED_REQUEST";
export const GET_TOTAL_REVENUE_PROTECTED_SUCCESS = "GET_TOTAL_REVENUE_PROTECTED_SUCCESS";
export const GET_TOTAL_REVENUE_PROTECTED_ERROR = "GET_TOTAL_REVENUE_PROTECTED_ERROR";

export const GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_REQUEST = "GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_REQUEST";
export const GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_SUCCESS = "GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_SUCCESS";
export const GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_ERROR = "GET_TOTAL_REVENUE_PROTECTED_BY_ACCOUNT_ERROR";

export const GET_LINKED_SHOPS_REQUEST = "GET_LINKED_SHOPS_REQUEST";
export const GET_LINKED_SHOPS_SUCCESS = "GET_LINKED_SHOPS_SUCCESS";
export const GET_LINKED_SHOPS_ERROR = "GET_LINKED_SHOPS_ERROR";

export const GET_LINKED_SHOPS_BY_ACCOUNT_REQUEST = "GET_LINKED_SHOPS_BY_ACCOUNT_REQUEST";
export const GET_LINKED_SHOPS_BY_ACCOUNT_SUCCESS = "GET_LINKED_SHOPS_BY_ACCOUNT_SUCCESS";
export const GET_LINKED_SHOPS_BY_ACCOUNT_ERROR = "GET_LINKED_SHOPS_BY_ACCOUNT_ERROR";

export const DELETE_LINKED_SHOPS_REQUEST = "DELETE_LINKED_SHOPS_REQUEST";
export const DELETE_LINKED_SHOPS_SUCCESS = "DELETE_LINKED_SHOPS_SUCCESS";
export const DELETE_LINKED_SHOPS_ERROR = "DELETE_LINKED_SHOPS_ERROR";

export const GET_LINKED_PAYPAL_REQUEST = "GET_LINKED_PAYPAL_REQUEST";
export const GET_LINKED_PAYPAL_SUCCESS = "GET_LINKED_PAYPAL_SUCCESS";
export const GET_LINKED_PAYPAL_ERROR = "GET_LINKED_PAYPAL_ERROR";

export const GET_LINKED_PAYPAL_BY_ACCOUNT_REQUEST = "GET_LINKED_PAYPAL_BY_ACCOUNT_REQUEST";
export const GET_LINKED_PAYPAL_BY_ACCOUNT_SUCCESS = "GET_LINKED_PAYPAL_BY_ACCOUNT_SUCCESS";
export const GET_LINKED_PAYPAL_BY_ACCOUNT_ERROR = "GET_LINKED_PAYPAL_BY_ACCOUNT_ERROR";

export const GET_RECENT_DISPUTES_REQUEST = "GET_RECENT_DISPUTES_REQUEST";
export const GET_RECENT_DISPUTES_SUCCESS = "GET_RECENT_DISPUTES_SUCCESS";
export const GET_RECENT_DISPUTES_ERROR = "GET_RECENT_DISPUTES_ERROR";

export const GET_RECENT_DISPUTES_BY_ACCOUNT_REQUEST = "GET_RECENT_DISPUTES_BY_ACCOUNT_REQUEST";
export const GET_RECENT_DISPUTES_BY_ACCOUNT_SUCCESS = "GET_RECENT_DISPUTES_BY_ACCOUNT_SUCCESS";
export const GET_RECENT_DISPUTES_BY_ACCOUNT_ERROR = "GET_RECENT_DISPUTES_BY_ACCOUNT_ERROR";

export const GET_OPEN_DISPUTES_REQUEST = "GET_OPEN_DISPUTES_REQUEST";
export const GET_OPEN_DISPUTES_SUCCESS = "GET_OPEN_DISPUTES_SUCCESS";
export const GET_OPEN_DISPUTES_ERROR = "GET_OPEN_DISPUTES_ERROR";

export const GET_OPEN_DISPUTES_BY_ACCOUNT_REQUEST = "GET_OPEN_DISPUTES_BY_ACCOUNT_REQUEST";
export const GET_OPEN_DISPUTES_BY_ACCOUNT_SUCCESS = "GET_OPEN_DISPUTES_BY_ACCOUNT_SUCCESS";
export const GET_OPEN_DISPUTES_BY_ACCOUNT_ERROR = "GET_OPEN_DISPUTES_BY_ACCOUNT_ERROR";

export const GET_RECOVERED_DISPUTES_REQUEST = "GET_RECOVERED_DISPUTES_REQUEST";
export const GET_RECOVERED_DISPUTES_SUCCESS = "GET_RECOVERED_DISPUTES_SUCCESS";
export const GET_RECOVERED_DISPUTES_ERROR = "GET_RECOVERED_DISPUTES_ERROR";

export const GET_RECOVERED_DISPUTES_BY_ACCOUNT_REQUEST = "GET_RECOVERED_DISPUTES_BY_ACCOUNT_REQUEST";
export const GET_RECOVERED_DISPUTES_BY_ACCOUNT_SUCCESS = "GET_RECOVERED_DISPUTES_BY_ACCOUNT_SUCCESS";
export const GET_RECOVERED_DISPUTES_BY_ACCOUNT_ERROR = "GET_RECOVERED_DISPUTES_BY_ACCOUNT_ERROR";

export const GET_CURRENT_WEEK_DISPUTES_REQUEST = "GET_CURRENT_WEEK_DISPUTES_REQUEST";
export const GET_CURRENT_WEEK_DISPUTES_SUCCESS = "GET_CURRENT_WEEK_DISPUTES_SUCCESS";
export const GET_CURRENT_WEEK_DISPUTES_ERROR = "GET_CURRENT_WEEK_DISPUTES_ERROR";

export const GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_REQUEST = "GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_REQUEST";
export const GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_SUCCESS = "GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_SUCCESS";
export const GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_ERROR = "GET_CURRENT_WEEK_DISPUTES_BY_ACCOUNT_ERROR";

export const GET_DISPUTES_LIST_REQUEST = "GET_DISPUTES_LIST_REQUEST";
export const GET_DISPUTES_LIST_SUCCESS = "GET_DISPUTES_LIST_SUCCESS";
export const GET_DISPUTES_LIST_ERROR = "GET_DISPUTES_LIST_ERROR";

// awaiting-seller-response
export const GET_AWAITING_DISPUTES_REQUEST = "GET_AWAITING_DISPUTES_REQUEST";
export const GET_AWAITING_DISPUTES_SUCCESS = "GET_AWAITING_DISPUTES_SUCCESS";
export const GET_AWAITING_DISPUTES_ERROR = "GET_AWAITING_DISPUTES_ERROR";

export const GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_REQUEST = "GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_REQUEST";
export const GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_SUCCESS = "GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_SUCCESS";
export const GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_ERROR = "GET_AWAITING_DISPUTES_TOTAL_BILLED_AMOUNT_ERROR";

export const GET_AWAITING_DISPUTES_TOTAL_AMOUNT_REQUEST = "GET_AWAITING_DISPUTES_TOTAL_AMOUNT_REQUEST";
export const GET_AWAITING_DISPUTES_TOTAL_AMOUNT_SUCCESS = "GET_AWAITING_DISPUTES_TOTAL_AMOUNT_SUCCESS";
export const GET_AWAITING_DISPUTES_TOTAL_AMOUNT_ERROR = "GET_AWAITING_DISPUTES_TOTAL_AMOUNT_ERROR";

export const GET_FILTERED_DISPUTES_REQUEST = "GET_FILTERED_DISPUTES_REQUEST";
export const GET_FILTERED_DISPUTES_SUCCESS = "GET_FILTERED_DISPUTES_SUCCESS";
export const GET_FILTERED_DISPUTES_ERROR = "GET_FILTERED_DISPUTES_ERROR";

export const POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_REQUEST = "POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_REQUEST";
export const POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_SUCCESS = "POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_SUCCESS";
export const POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_ERROR = "POST_DISPUTES_LIST_FETCH_BY_ACCOUNT_ERROR";

export const GET_PROCESSING_DISPUTES_REQUEST = "GET_PROCESSING_DISPUTES_REQUEST";
export const GET_PROCESSING_DISPUTES_SUCCESS = "GET_PROCESSING_DISPUTES_SUCCESS";
export const GET_PROCESSING_DISPUTES_ERROR = "GET_PROCESSING_DISPUTES_ERROR";

export const GET_PROCESSING_DISPUTES_BY_ACCOUNT_REQUEST = "GET_PROCESSING_DISPUTES_BY_ACCOUNT_REQUEST";
export const GET_PROCESSING_DISPUTES_BY_ACCOUNT_SUCCESS = "GET_PROCESSING_DISPUTES_BY_ACCOUNT_SUCCESS";
export const GET_PROCESSING_DISPUTES_BY_ACCOUNT_ERROR = "GET_PROCESSING_DISPUTES_BY_ACCOUNT_ERROR";

export const GET_AWAITING_RESPONSE_DISPUTES_REQUEST = "GET_AWAITING_RESPONSE_DISPUTES_REQUEST";
export const GET_AWAITING_RESPONSE_DISPUTES_SUCCESS = "GET_AWAITING_RESPONSE_DISPUTES_SUCCESS";
export const GET_AWAITING_RESPONSE_DISPUTES_ERROR = "GET_AWAITING_RESPONSE_DISPUTES_ERROR";

export const GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_REQUEST = "GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_REQUEST";
export const GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_SUCCESS = "GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_SUCCESS";
export const GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_ERROR = "GET_AWAITING_RESPONSE_DISPUTES_BY_ACCOUNT_ERROR";

//dispute-details
export const GET_DISPUTE_DETAILS_REQUEST = "GET_DISPUTE_DETAILS_REQUEST";
export const GET_DISPUTE_DETAILS_SUCCESS = "GET_DISPUTE_DETAILS_SUCCESS";
export const GET_DISPUTE_DETAILS_ERROR = "GET_DISPUTE_DETAILS_ERROR";

export const GET_DISPUTE_ORDER_SUMMARY_REQUEST = "GET_DISPUTE_ORDER_SUMMARY_REQUEST";
export const GET_DISPUTE_ORDER_SUMMARY_SUCCESS = "GET_DISPUTE_ORDER_SUMMARY_SUCCESS";
export const GET_DISPUTE_ORDER_SUMMARY_ERROR = "GET_DISPUTE_ORDER_SUMMARY_ERROR";

export const GET_DISPUTE_TRANSACTION_DETAILS_REQUEST = "GET_DISPUTE_TRANSACTION_DETAILS_REQUEST";
export const GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS = "GET_DISPUTE_TRANSACTION_DETAILS_SUCCESS";
export const GET_DISPUTE_TRANSACTION_DETAILS_ERROR = "GET_DISPUTE_TRANSACTION_DETAILS_ERROR";

export const GET_DISPUTE_CUSTOMER_DETAILS_REQUEST = "GET_DISPUTE_CUSTOMER_DETAILS_REQUEST";
export const GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS = "GET_DISPUTE_CUSTOMER_DETAILS_SUCCESS";
export const GET_DISPUTE_CUSTOMER_DETAILS_ERROR = "GET_DISPUTE_CUSTOMER_DETAILS_ERROR";

export const GET_DISPUTE_JIRA_CUSTOMERS_REQUEST = "GET_DISPUTE_JIRA_CUSTOMERS_REQUEST";
export const GET_DISPUTE_JIRA_CUSTOMERS_SUCCESS = "GET_DISPUTE_JIRA_CUSTOMERS_SUCCESS";
export const GET_DISPUTE_JIRA_CUSTOMERS_ERROR = "GET_DISPUTE_JIRA_CUSTOMERS_ERROR";

export const GET_DISPUTE_TRACKING_DETAILS_REQUEST = "GET_DISPUTE_TRACKING_DETAILS_REQUEST";
export const GET_DISPUTE_TRACKING_DETAILS_SUCCESS = "GET_DISPUTE_TRACKING_DETAILS_SUCCESS";
export const GET_DISPUTE_TRACKING_DETAILS_ERROR = "GET_DISPUTE_TRACKING_DETAILS_ERROR";

export const GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST = "GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_REQUEST";
export const GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS = "GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_SUCCESS";
export const GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR = "GET_DISPUTE_FRAUD_ANALYSIS_DETAILS_ERROR";

export const GET_DISPUTE_TRACKING_REQUEST = "GET_DISPUTE_TRACKING_REQUEST";
export const GET_DISPUTE_TRACKING_SUCCESS = "GET_DISPUTE_TRACKING_SUCCESS";
export const GET_DISPUTE_TRACKING_ERROR = "GET_DISPUTE_TRACKING_ERROR";

export const GET_RESPONSE_JSON_BY_ID_REQUEST = "GET_RESPONSE_JSON_BY_ID_REQUEST";
export const GET_RESPONSE_JSON_BY_ID_SUCCESS = "GET_RESPONSE_JSON_BY_ID_SUCCESS";
export const GET_RESPONSE_JSON_BY_ID_ERROR = "GET_RESPONSE_JSON_BY_ID_ERROR";

export const GET_DISPUTE_SHIPPING_DETAILS_REQUEST = "GET_DISPUTE_SHIPPING_DETAILS_REQUEST";
export const GET_DISPUTE_SHIPPING_DETAILS_SUCCESS = "GET_DISPUTE_SHIPPING_DETAILS_SUCCESS";
export const GET_DISPUTE_SHIPPING_DETAILS_ERROR = "GET_DISPUTE_SHIPPING_DETAILS_ERROR";

export const POST_DISPUTE_SUBMITTED_REQUEST = "POST_DISPUTE_SUBMITTED_REQUEST";
export const POST_DISPUTE_SUBMITTED_SUCCESS = "POST_DISPUTE_SUBMITTED_SUCCESS";
export const POST_DISPUTE_SUBMITTED_ERROR = "POST_DISPUTE_SUBMITTED_ERROR";

export const GET_DISPUTE_FULFILLMENT_DETAILS_REQUEST = "GET_DISPUTE_FULFILLMENT_DETAILS_REQUEST";
export const GET_DISPUTE_FULFILLMENT_DETAILS_SUCCESS = "GET_DISPUTE_FULFILLMENT_DETAILS_SUCCESS";
export const GET_DISPUTE_FULFILLMENT_DETAILS_ERROR = "GET_DISPUTE_FULFILLMENT_DETAILS_ERROR";

export const GET_DISPUTE_EVIDENCE_DETAILS_REQUEST = "GET_DISPUTE_EVIDENCE_DETAILS_REQUEST";
export const GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS = "GET_DISPUTE_EVIDENCE_DETAILS_SUCCESS";
export const GET_DISPUTE_EVIDENCE_DETAILS_ERROR = "GET_DISPUTE_EVIDENCE_DETAILS_ERROR";

export const GET_DISPUTE_MESSAGE_DETAILS_REQUEST = "GET_DISPUTE_MESSAGE_DETAILS_REQUEST";
export const GET_DISPUTE_MESSAGE_DETAILS_SUCCESS = "GET_DISPUTE_MESSAGE_DETAILS_SUCCESS";
export const GET_DISPUTE_MESSAGE_DETAILS_ERROR = "GET_DISPUTE_MESSAGE_DETAILS_ERROR";

export const GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST = "GET_DISPUTE_ADJUDICATION_DETAILS_REQUEST";
export const GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS = "GET_DISPUTE_ADJUDICATION_DETAILS_SUCCESS";
export const GET_DISPUTE_ADJUDICATION_DETAILS_ERROR = "GET_DISPUTE_ADJUDICATION_DETAILS_ERROR";

export const GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST = "GET_DISPUTE_MONEY_MOVEMENT_DETAILS_REQUEST";
export const GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS = "GET_DISPUTE_MONEY_MOVEMENT_DETAILS_SUCCESS";
export const GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR = "GET_DISPUTE_MONEY_MOVEMENT_DETAILS_ERROR";

export const GET_DISPUTE_MANAGE_FLOW_REQUEST = "GET_DISPUTE_MANAGE_FLOW_REQUEST";
export const GET_DISPUTE_MANAGE_FLOW_SUCCESS = "GET_DISPUTE_MANAGE_FLOW_SUCCESS";
export const GET_DISPUTE_MANAGE_FLOW_ERROR = "GET_DISPUTE_MANAGE_FLOW_ERROR";

export const GET_FETCH_DISPUTE_TEMPLATE_REQUEST = "GET_FETCH_DISPUTE_TEMPLATE_REQUEST";
export const GET_FETCH_DISPUTE_TEMPLATE_SUCCESS = "GET_FETCH_DISPUTE_TEMPLATE_SUCCESS";
export const GET_FETCH_DISPUTE_TEMPLATE_ERROR = "GET_FETCH_DISPUTE_TEMPLATE_ERROR";

export const GET_FETCH_DISPUTE_SUMMARY_REQUEST = "GET_FETCH_DISPUTE_SUMMARY_REQUEST";
export const GET_FETCH_DISPUTE_SUMMARY_SUCCESS = "GET_FETCH_DISPUTE_SUMMARY_SUCCESS";
export const GET_FETCH_DISPUTE_SUMMARY_ERROR = "GET_FETCH_DISPUTE_SUMMARY_ERROR";

export const GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST = "GET_DISPUTE_SUPPORTING_INFO_DETAILS_REQUEST";
export const GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS = "GET_DISPUTE_SUPPORTING_INFO_DETAILS_SUCCESS";
export const GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR = "GET_DISPUTE_SUPPORTING_INFO_DETAILS_ERROR";

export const GET_DISPUTE_OFFER_DETAILS_REQUEST = "GET_DISPUTE_OFFER_DETAILS_REQUEST";
export const GET_DISPUTE_OFFER_DETAILS_SUCCESS = "GET_DISPUTE_OFFER_DETAILS_SUCCESS";
export const GET_DISPUTE_OFFER_DETAILS_ERROR = "GET_DISPUTE_OFFER_DETAILS_ERROR";

export const POST_DISPUTE_FLOW_UPLOAD_FILE_REQUEST = "POST_DISPUTE_FLOW_UPLOAD_FILE_REQUEST";
export const POST_DISPUTE_FLOW_UPLOAD_FILE_SUCCESS = "POST_DISPUTE_FLOW_UPLOAD_FILE_SUCCESS";
export const POST_DISPUTE_FLOW_UPLOAD_FILE_ERROR = "POST_DISPUTE_FLOW_UPLOAD_FILE_ERROR";

export const POST_DISPUTE_SEND_MESSAGE_REQUEST = "POST_DISPUTE_SEND_MESSAGE_REQUEST";
export const POST_DISPUTE_SEND_MESSAGE_SUCCESS = "POST_DISPUTE_SEND_MESSAGE_SUCCESS";
export const POST_DISPUTE_SEND_MESSAGE_ERROR = "POST_DISPUTE_SEND_MESSAGE_ERROR";

export const POST_ACCOUNT_LIST_REQUEST = "POST_ACCOUNT_LIST_REQUEST";
export const POST_ACCOUNT_LIST_SUCCESS = "POST_ACCOUNT_LIST_SUCCESS";
export const POST_ACCOUNT_LIST_ERROR = "POST_ACCOUNT_LIST_ERROR";

//manage-dispute-apis-constant
export const POST_MANAGE_DISPUTE_SEND_MESSAGE_REQUEST = "POST_MANAGE_DISPUTE_SEND_MESSAGE_REQUEST";
export const POST_MANAGE_DISPUTE_SEND_MESSAGE_SUCCESS = "POST_MANAGE_DISPUTE_SEND_MESSAGE_SUCCESS";
export const POST_MANAGE_DISPUTE_SEND_MESSAGE_ERROR = "POST_MANAGE_DISPUTE_SEND_MESSAGE_ERROR";

export const GET_MANAGE_DISPUTE_CARRIER_OPTIONS_REQUEST = "GET_MANAGE_DISPUTE_CARRIER_OPTIONS_REQUEST";
export const GET_MANAGE_DISPUTE_CARRIER_OPTIONS_SUCCESS = "GET_MANAGE_DISPUTE_CARRIER_OPTIONS_SUCCESS";
export const GET_MANAGE_DISPUTE_CARRIER_OPTIONS_ERROR = "GET_MANAGE_DISPUTE_CARRIER_OPTIONS_ERROR";

export const GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_REQUEST = "GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_REQUEST";
export const GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_SUCCESS = "GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_SUCCESS";
export const GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_ERROR = "GET_MANAGE_DISPUTE_EVIDENCE_TYPE_OPTIONS_ERROR";

export const POST_MANAGE_DISPUTE_APPEAL_DISPUTE_REQUEST = "POST_MANAGE_DISPUTE_APPEAL_DISPUTE_REQUEST";
export const POST_MANAGE_DISPUTE_APPEAL_DISPUTE_SUCCESS = "POST_MANAGE_DISPUTE_APPEAL_DISPUTE_SUCCESS";
export const POST_MANAGE_DISPUTE_APPEAL_DISPUTE_ERROR = "POST_MANAGE_DISPUTE_APPEAL_DISPUTE_ERROR";

export const POST_MANAGE_DISPUTE_MANAGE_MANUALLY_REQUEST = "POST_MANAGE_DISPUTE_MANAGE_MANUALLY_REQUEST";
export const POST_MANAGE_DISPUTE_MANAGE_MANUALLY_SUCCESS = "POST_MANAGE_DISPUTE_MANAGE_MANUALLY_SUCCESS";
export const POST_MANAGE_DISPUTE_MANAGE_MANUALLY_ERROR = "POST_MANAGE_DISPUTE_MANAGE_MANUALLY_ERROR";

export const POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_REQUEST = "POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_REQUEST";
export const POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_SUCCESS = "POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_SUCCESS";
export const POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_ERROR = "POST_MANAGE_ACKNOWLEDGE_RETURN_ITEM_ERROR";

export const POST_MANAGE_ACCEPT_CLAIM_REQUEST = "POST_MANAGE_ACCEPT_CLAIM_REQUEST";
export const POST_MANAGE_ACCEPT_CLAIM_SUCCESS = "POST_MANAGE_ACCEPT_CLAIM_SUCCESS";
export const POST_MANAGE_ACCEPT_CLAIM_ERROR = "POST_MANAGE_ACCEPT_CLAIM_ERROR";

export const GET_MANAGE_DISPUTE_HATEOAS_LINK_REQUEST = "GET_MANAGE_DISPUTE_HATEOAS_LINK_REQUEST";
export const GET_MANAGE_DISPUTE_HATEOAS_LINK_SUCCESS = "GET_MANAGE_DISPUTE_HATEOAS_LINK_SUCCESS";
export const GET_MANAGE_DISPUTE_HATEOAS_LINK_ERROR = "GET_MANAGE_DISPUTE_HATEOAS_LINK_ERROR";

//dispute-template
export const POST_DISPUTE_TEMPLATE_REQUEST = "POST_DISPUTE_TEMPLATE_REQUEST";
export const POST_DISPUTE_TEMPLATE_SUCCESS = "POST_DISPUTE_TEMPLATE_SUCCESS";
export const POST_DISPUTE_TEMPLATE_ERROR = "POST_DISPUTE_TEMPLATE_ERROR";

export const GET_DISPUTE_TEMPLATE_LIST_REQUEST = "GET_DISPUTE_TEMPLATE_LIST_REQUEST";
export const GET_DISPUTE_TEMPLATE_LIST_SUCCESS = "GET_DISPUTE_TEMPLATE_LIST_SUCCESS";
export const GET_DISPUTE_TEMPLATE_LIST_ERROR = "GET_DISPUTE_TEMPLATE_LIST_ERROR";

export const GET_DISPUTE_TEMPLATE_FULL_LIST_REQUEST = "GET_DISPUTE_TEMPLATE_FULL_LIST_REQUEST";
export const GET_DISPUTE_TEMPLATE_FULL_LIST_SUCCESS = "GET_DISPUTE_TEMPLATE_FULL_LIST_SUCCESS";
export const GET_DISPUTE_TEMPLATE_FULL_LIST_ERROR = "GET_DISPUTE_TEMPLATE_FULL_LIST_ERROR";

export const GET_DISPUTE_TEMPLATE_REASON_LIST_REQUEST = "GET_DISPUTE_TEMPLATE_REASON_LIST_REQUEST";
export const GET_DISPUTE_TEMPLATE_REASON_LIST_SUCCESS = "GET_DISPUTE_TEMPLATE_REASON_LIST_SUCCESS";
export const GET_DISPUTE_TEMPLATE_REASON_LIST_ERROR = "GET_DISPUTE_TEMPLATE_REASON_LIST_ERROR";

export const GET_DISPUTE_TEMPLATE_ACTION_LIST_REQUEST = "GET_DISPUTE_TEMPLATE_ACTION_LIST_REQUEST";
export const GET_DISPUTE_TEMPLATE_ACTION_LIST_SUCCESS = "GET_DISPUTE_TEMPLATE_ACTION_LIST_SUCCESS";
export const GET_DISPUTE_TEMPLATE_ACTION_LIST_ERROR = "GET_DISPUTE_TEMPLATE_ACTION_LIST_ERROR";

export const GET_DISPUTE_TEMPLATE_STAGE_LIST_REQUEST = "GET_DISPUTE_TEMPLATE_STAGE_LIST_REQUEST";
export const GET_DISPUTE_TEMPLATE_STAGE_LIST_SUCCESS = "GET_DISPUTE_TEMPLATE_STAGE_LIST_SUCCESS";
export const GET_DISPUTE_TEMPLATE_STAGE_LIST_ERROR = "GET_DISPUTE_TEMPLATE_STAGE_LIST_ERROR";

export const GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_REQUEST = "GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_REQUEST";
export const GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_SUCCESS = "GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_SUCCESS";
export const GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_ERROR = "GET_DISPUTE_TEMPLATE_FIND_BY_TYPE_ERROR";

export const GET_DISPUTE_TEMPLATE_FIND_BY_STORE_REQUEST = "GET_DISPUTE_TEMPLATE_FIND_BY_STORE_REQUEST";
export const GET_DISPUTE_TEMPLATE_FIND_BY_STORE_SUCCESS = "GET_DISPUTE_TEMPLATE_FIND_BY_STORE_SUCCESS";
export const GET_DISPUTE_TEMPLATE_FIND_BY_STORE_ERROR = "GET_DISPUTE_TEMPLATE_FIND_BY_STORE_ERROR";

export const POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_REQUEST = "POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_REQUEST";
export const POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_SUCCESS = "POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_SUCCESS";
export const POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_ERROR = "POST_DISPUTE_TEMPLATE_FILTER_TEMPLATES_ERROR";

export const GET_DISPUTE_TEMPLATE_VIEW_REQUEST = "GET_DISPUTE_TEMPLATE_VIEW_REQUEST";
export const GET_DISPUTE_TEMPLATE_VIEW_SUCCESS = "GET_DISPUTE_TEMPLATE_VIEW_SUCCESS";
export const GET_DISPUTE_TEMPLATE_VIEW_ERROR = "GET_DISPUTE_TEMPLATE_VIEW_ERROR";

export const GET_DISPUTE_TEMPLATE_PREVIEW_PDF_REQUEST = "GET_DISPUTE_TEMPLATE_PREVIEW_PDF_REQUEST";
export const GET_DISPUTE_TEMPLATE_PREVIEW_PDF_SUCCESS = "GET_DISPUTE_TEMPLATE_PREVIEW_PDF_SUCCESS";
export const GET_DISPUTE_TEMPLATE_PREVIEW_PDF_ERROR = "GET_DISPUTE_TEMPLATE_PREVIEW_PDF_ERROR";

export const GET_DISPUTE_TEMPLATE_FETCH_CONTENT_REQUEST = "GET_DISPUTE_TEMPLATE_FETCH_CONTENT_REQUEST";
export const GET_DISPUTE_TEMPLATE_FETCH_CONTENT_SUCCESS = "GET_DISPUTE_TEMPLATE_FETCH_CONTENT_SUCCESS";
export const GET_DISPUTE_TEMPLATE_FETCH_CONTENT_ERROR = "GET_DISPUTE_TEMPLATE_FETCH_CONTENT_ERROR";

//dispute-response
export const GET_DISPUTE_RESPONSE_FIND_ALL_REQUEST = "GET_DISPUTE_RESPONSE_FIND_ALL_REQUEST";
export const GET_DISPUTE_RESPONSE_FIND_ALL_SUCCESS = "GET_DISPUTE_RESPONSE_FIND_ALL_SUCCESS";
export const GET_DISPUTE_RESPONSE_FIND_ALL_ERROR = "GET_DISPUTE_RESPONSE_FIND_ALL_ERROR";

export const GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_REQUEST = "GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_REQUEST";
export const GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_SUCCESS = "GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_SUCCESS";
export const GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_ERROR = "GET_DISPUTE_RESPONSE_FIND_BY_DISPUTE_ID_ERROR";

export const GET_DISPUTE_FLOW_LIST_BY_PSP_REQUEST = "GET_DISPUTE_FLOW_LIST_BY_PSP_REQUEST";
export const GET_DISPUTE_FLOW_LIST_BY_PSP_SUCCESS = "GET_DISPUTE_FLOW_LIST_BY_PSP_SUCCESS";
export const GET_DISPUTE_FLOW_LIST_BY_PSP_ERROR = "GET_DISPUTE_FLOW_LIST_BY_PSP_ERROR";

export const GET_DISPUTE_TEMPLATE_BY_FLOWID_REQUEST = "GET_DISPUTE_TEMPLATE_BY_FLOWID_REQUEST";
export const GET_DISPUTE_TEMPLATE_BY_FLOWID_SUCCESS = "GET_DISPUTE_TEMPLATE_BY_FLOWID_SUCCESS";
export const GET_DISPUTE_TEMPLATE_BY_FLOWID_ERROR = "GET_DISPUTE_TEMPLATE_BY_FLOWID_ERROR";

export const POST_DISPUTE_RESPONSE_CREATE_REQUEST = "POST_DISPUTE_RESPONSE_CREATE_REQUEST";
export const POST_DISPUTE_RESPONSE_CREATE_SUCCESS = "POST_DISPUTE_RESPONSE_CREATE_SUCCESS";
export const POST_DISPUTE_RESPONSE_CREATE_ERROR = "POST_DISPUTE_RESPONSE_CREATE_ERROR";

export const POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_REQUEST = "POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_REQUEST";
export const POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_SUCCESS = "POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_SUCCESS";
export const POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_ERROR = "POST_CREATE_RESPONSE_DISPUTEID_TEMPLATEID_ERROR";

export const GET_DISPUTE_RESPONSE_PDF_REQUEST = "GET_DISPUTE_RESPONSE_PDF_REQUEST";
export const GET_DISPUTE_RESPONSE_PDF_SUCCESS = "GET_DISPUTE_RESPONSE_PDF_SUCCESS";
export const GET_DISPUTE_RESPONSE_PDF_ERROR = "GET_DISPUTE_RESPONSE_PDF_ERROR";

export const GET_DISPUTE_RESPONSE_PDF_BY_ID_REQUEST = "GET_DISPUTE_RESPONSE_PDF_BY_ID_REQUEST";
export const GET_DISPUTE_RESPONSE_PDF_BY_ID_SUCCESS = "GET_DISPUTE_RESPONSE_PDF_BY_ID_SUCCESS";
export const GET_DISPUTE_RESPONSE_PDF_BY_ID_ERROR = "GET_DISPUTE_RESPONSE_PDF_BY_ID_ERROR";

export const GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST = "GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_REQUEST";
export const GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_SUCCESS = "GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_SUCCESS";
export const GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_ERROR = "GET_DISPUTE_RESPONSE_STATUS_SUBMITTED_ERROR";

export const GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_REQUEST = "GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_REQUEST";
export const GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_SUCCESS = "GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_SUCCESS";
export const GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_ERROR = "GET_DISPUTE_UPDATE_RESPONSE_STATUS_SUBMITTED_ERROR";

export const GET_DISPUTE_RESPONSE_STATUS_QUEUED_REQUEST = "GET_DISPUTE_RESPONSE_STATUS_QUEUED_REQUEST";
export const GET_DISPUTE_RESPONSE_STATUS_QUEUED_SUCCESS = "GET_DISPUTE_RESPONSE_STATUS_QUEUED_SUCCESS";
export const GET_DISPUTE_RESPONSE_STATUS_QUEUED_ERROR = "GET_DISPUTE_RESPONSE_STATUS_QUEUED_ERROR";

export const GET_DISPUTE_RESPONSE_STATUS_APPROVED_REQUEST = "GET_DISPUTE_RESPONSE_STATUS_APPROVED_REQUEST";
export const GET_DISPUTE_RESPONSE_STATUS_APPROVED_SUCCESS = "GET_DISPUTE_RESPONSE_STATUS_APPROVED_SUCCESS";
export const GET_DISPUTE_RESPONSE_STATUS_APPROVED_ERROR = "GET_DISPUTE_RESPONSE_STATUS_APPROVED_ERROR";

export const GET_DISPUTE_RESPONSE_FETCH_CONTENT_REQUEST = "GET_DISPUTE_RESPONSE_FETCH_CONTENT_REQUEST";
export const GET_DISPUTE_RESPONSE_FETCH_CONTENT_SUCCESS = "GET_DISPUTE_RESPONSE_FETCH_CONTENT_SUCCESS";
export const GET_DISPUTE_RESPONSE_FETCH_CONTENT_ERROR = "GET_DISPUTE_RESPONSE_FETCH_CONTENT_ERROR";

export const POST_DISPUTE_RESPONSE_SAVE_CONTENT_REQUEST = "POST_DISPUTE_RESPONSE_SAVE_CONTENT_REQUEST"
export const POST_DISPUTE_RESPONSE_SAVE_CONTENT_SUCCESS = "POST_DISPUTE_RESPONSE_SAVE_CONTENT_SUCCESS"
export const POST_DISPUTE_RESPONSE_SAVE_CONTENT_ERROR = "POST_DISPUTE_RESPONSE_SAVE_CONTENT_ERROR"

export const GET_ALL_MACROS_REQUEST = "GET_ALL_MACROS_REQUEST"
export const GET_ALL_MACROS_SUCCESS = "GET_ALL_MACROS_SUCCESS"
export const GET_ALL_MACROS_ERROR = "GET_ALL_MACROS_ERROR"

export const GET_MISSING_MACROS_REQUEST = "GET_MISSING_MACROS_REQUEST"
export const GET_MISSING_MACROS_SUCCESS = "GET_MISSING_MACROS_SUCCESS"
export const GET_MISSING_MACROS_ERROR = "GET_ALL_MISSING_MACROS_ERROR"


export const POST_SAVING_MISSING_MACROS_REQUEST = "POST_SAVING_MISSING_MACROS_REQUEST"
export const POST_SAVING_MISSING_MACROS_SUCCESS = "POST_SAVING_MISSING_MACROS_SUCCESS"
export const POST_SAVING_MISSING_MACROS_ERROR = "POST_SAVING_MISSING_MACROS_ERROR"

export const POST_DISPUTE_RESPONSE_UPDATE_STATUS_REQUEST = "POST_DISPUTE_RESPONSE_UPDATE_STATUS_REQUEST"
export const POST_DISPUTE_RESPONSE_UPDATE_STATUS_SUCCESS = "POST_DISPUTE_RESPONSE_UPDATE_STATUS_SUCCESS"
export const POST_DISPUTE_RESPONSE_UPDATE_STATUS_ERROR = "POST_DISPUTE_RESPONSE_UPDATE_STATUS_ERROR"

export const POST_CREATE_RESPONSE_REQUEST = "POST_CREATE_RESPONSE_REQUEST";
export const POST_CREATE_RESPONSE_SUCCESS = "POST_CREATE_RESPONSE_SUCCESS";
export const POST_CREATE_RESPONSE_ERROR = "POST_CREATE_RESPONSE_ERROR";

export const DELETE_DISPUTE_RESPONSE_REQUEST = "DELETE_DISPUTE_RESPONSE_REQUEST";
export const DELETE_DISPUTE_RESPONSE_SUCCESS = "DELETE_DISPUTE_RESPONSE_SUCCESS";
export const DELETE_DISPUTE_RESPONSE_ERROR = "DELETE_DISPUTE_RESPONSE_ERROR";
export const DELETE_DISPUTE_RESPONSE_CLEAR = "DELETE_DISPUTE_RESPONSE_CLEAR";
//stores-shop
export const GET_SHOP_ALL_SHOPS_REQUEST = "GET_SHOP_ALL_SHOPS_REQUEST";
export const GET_SHOP_ALL_SHOPS_SUCCESS = "GET_SHOP_ALL_SHOPS_SUCCESS";
export const GET_SHOP_ALL_SHOPS_ERROR = "GET_SHOP_ALL_SHOPS_ERROR";

export const GET_SHOP_FIND_SHOP_REQUEST = "GET_SHOP_FIND_SHOP_REQUEST";
export const GET_SHOP_FIND_SHOP_SUCCESS = "GET_SHOP_FIND_SHOP_SUCCESS";
export const GET_SHOP_FIND_SHOP_ERROR = "GET_SHOP_FIND_SHOP_ERROR";

export const GET_SHOP_FIND_ACTIVE_STORES_REQUEST = "GET_SHOP_FIND_ACTIVE_STORES_REQUEST";
export const GET_SHOP_FIND_ACTIVE_STORES_SUCCESS = "GET_SHOP_FIND_ACTIVE_STORES_SUCCESS";
export const GET_SHOP_FIND_ACTIVE_STORES_ERROR = "GET_SHOP_FIND_ACTIVE_STORES_ERROR";

export const GET_SHOP_STORE_REPORT_REQUEST = "GET_SHOP_STORE_REPORT_REQUEST";
export const GET_SHOP_STORE_REPORT_SUCCESS = "GET_SHOP_STORE_REPORT_SUCCESS";
export const GET_SHOP_STORE_REPORT_ERROR = "GET_SHOPP_STORE_REPORT_ERROR"

export const POST_SHOP_FILTER_SHOPS_REQUEST = "POST_SHOP_FILTER_SHOPS_REQUEST";
export const POST_SHOP_FILTER_SHOPS_SUCCESS = "POST_SHOP_FILTER_SHOPS_SUCCESS";
export const POST_SHOP_FILTER_SHOPS_ERROR = "POST_SHOP_FILTER_SHOPS_ERROR";

export const POST_SHOPS_SAVE_ADD_ON_REQUEST = "POST_SHOPS_SAVE_ADD_ON_REQUEST";
export const POST_SHOPS_SAVE_ADD_ON_SUCCESS = "POST_SHOPS_SAVE_ADD_ON_SUCCESS";
export const POST_SHOPS_SAVE_ADD_ON_ERROR = "POST_SHOPS_SAVE_ADD_ON_ERROR";

export const GET_SHOP_FIND_SHOP_ADD_ON_REQUEST = "GET_SHOP_FIND_SHOP_ADD_ON_REQUEST";
export const GET_SHOP_FIND_SHOP_ADD_ON_SUCCESS = "GET_SHOP_FIND_SHOP_ADD_ON_SUCCESS";
export const GET_SHOP_FIND_SHOP_ADD_ON_ERROR = "GET_SHOP_FIND_SHOP_ADD_ON_ERROR";

export const GET_PRE_SIGNED_URL_REQUEST = "GET_PRE_SIGNED_URL_REQUEST";
export const GET_PRE_SIGNED_URL_SUCCESS = "GET_PRE_SIGNED_URL_SUCCESS";
export const GET_PRE_SIGNED_URL_ERROR = "GET_PRE_SIGNED_URL_ERROR";
export const GET_PRE_SIGNED_URL_CLEAR = "GET_PRE_SIGNED_URL_CLEAR";

export const UPDATE_SHOP_COLLABORATOR_ACCESS_REQUEST = "UPDATE_SHOP_COLLABORATOR_ACCESS_REQUEST";
export const UPDATE_SHOP_COLLABORATOR_ACCESS_SUCCESS = "UPDATE_SHOP_COLLABORATOR_ACCESS_SUCCESS";
export const UPDATE_SHOP_COLLABORATOR_ACCESS_ERROR = "UPDATE_SHOP_COLLABORATOR_ACCESS_ERROR";

//dispute-flow
export const POST_DISPUTE_FLOW_REQUEST = "POST_DISPUTE_FLOW_REQUEST";
export const POST_DISPUTE_FLOW_SUCCESS = "POST_DISPUTE_FLOW_SUCCESS";
export const POST_DISPUTE_FLOW_ERROR = "POST_DISPUTE_FLOW_ERROR";

export const POST_HANDLED_BY_DISPUTE_REQUEST = "POST_HANDLED_BY_DISPUTE_REQUEST";
export const POST_HANDLED_BY_DISPUTE_SUCCESS= "POST_HANDLED_BY_DISPUTE_SUCCESS";
export const POST_HANDLED_BY_DISPUTE_ERROR = "POST_HANDLED_BY_DISPUTE_ERROR";

export const POST_DISPUTE_FLOW_DUPLICATE_ID_REQUEST = "POST_DISPUTE_FLOW_DUPLICATE_ID_REQUEST";
export const POST_DISPUTE_FLOW_DUPLICATE_ID_SUCCESS = "POST_DISPUTE_FLOW_DUPLICATE_ID_SUCCESS";
export const POST_DISPUTE_FLOW_DUPLICATE_ID_ERROR = "POST_DISPUTE_FLOW_DUPLICATE_ID_ERROR";

export const GET_DISPUTE_FLOW_LIST_REQUEST = "GET_DISPUTE_FLOW_LIST_REQUEST";
export const GET_DISPUTE_FLOW_LIST_SUCCESS = "GET_DISPUTE_FLOW_LIST_SUCCESS";
export const GET_DISPUTE_FLOW_LIST_ERROR = "GET_DISPUTE_FLOW_LIST_ERROR";

export const GET_DISPUTE_FLOW_REASON_LIST_REQUEST = "GET_DISPUTE_FLOW_REASON_LIST_REQUEST";
export const GET_DISPUTE_FLOW_REASON_LIST_SUCCESS = "GET_DISPUTE_FLOW_REASON_LIST_SUCCESS";
export const GET_DISPUTE_FLOW_REASON_LIST_ERROR = "GET_DISPUTE_FLOW_REASON_LIST_ERROR";

export const GET_DISPUTE_FLOW_ACTION_LIST_REQUEST = "GET_DISPUTE_FLOW_ACTION_LIST_REQUEST";
export const GET_DISPUTE_FLOW_ACTION_LIST_SUCCESS = "GET_DISPUTE_FLOW_ACTION_LIST_SUCCESS";
export const GET_DISPUTE_FLOW_ACTION_LIST_ERROR = "GET_DISPUTE_FLOW_ACTION_LIST_ERROR";

export const GET_DISPUTE_FLOW_STAGE_LIST_REQUEST = "GET_DISPUTE_FLOW_STAGE_LIST_REQUEST";
export const GET_DISPUTE_FLOW_STAGE_LIST_SUCCESS = "GET_DISPUTE_FLOW_STAGE_LIST_SUCCESS";
export const GET_DISPUTE_FLOW_STAGE_LIST_ERROR = "GET_DISPUTE_FLOW_STAGE_LIST_ERROR";

export const GET_DISPUTE_FLOW_FIND_BY_TYPE_REQUEST = "GET_DISPUTE_FLOW_FIND_BY_TYPE_REQUEST";
export const GET_DISPUTE_FLOW_FIND_BY_TYPE_SUCCESS = "GET_DISPUTE_FLOW_FIND_BY_TYPE_SUCCESS";
export const GET_DISPUTE_FLOW_FIND_BY_TYPE_ERROR = "GET_DISPUTE_FLOW_FIND_BY_TYPE_ERROR";

export const GET_DISPUTE_FLOW_FIND_BY_STORE_REQUEST = "GET_DISPUTE_FLOW_FIND_BY_STORE_REQUEST";
export const GET_DISPUTE_FLOW_FIND_BY_STORE_SUCCESS = "GET_DISPUTE_FLOW_FIND_BY_STORE_SUCCESS";
export const GET_DISPUTE_FLOW_FIND_BY_STORE_ERROR = "GET_DISPUTE_FLOW_FIND_BY_STORE_ERROR";

export const POST_DISPUTE_FLOW_FILTER_FLOWS_REQUEST = "POST_DISPUTE_FLOW_FILTER_FLOWS_REQUEST";
export const POST_DISPUTE_FLOW_FILTER_FLOWS_SUCCESS = "POST_DISPUTE_FLOW_FILTER_FLOWS_SUCCESS";
export const POST_DISPUTE_FLOW_FILTER_FLOWS_ERROR = "POST_DISPUTE_FLOW_FILTER_FLOWS_ERROR";

export const GET_DISPUTE_FLOW_VIEW_REQUEST = "GET_DISPUTE_FLOW_VIEW_REQUEST";
export const GET_DISPUTE_FLOW_VIEW_SUCCESS = "GET_DISPUTE_FLOW_VIEW_SUCCESS";
export const GET_DISPUTE_FLOW_VIEW_ERROR = "GET_DISPUTE_FLOW_VIEW_ERROR";
export const EXPAND_THE_FLOW_EDITOR = "EXPAND_THE_FLOW_EDITOR";

export const POST_SHOP_SAVE_COMPANY_ADD_ON_REQUEST = "POST_SHOP_SAVE_COMPANY_ADD_ON_REQUEST";
export const POST_SHOP_SAVE_COMPANY_ADD_ON_SUCCESS = "POST_SHOP_SAVE_COMPANY_ADD_ON_SUCCESS";
export const POST_SHOP_SAVE_COMPANY_ADD_ON_ERROR = "POST_SHOP_SAVE_COMPANY_ADD_ON_ERROR";

export const POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_REQUEST = "POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_REQUEST";
export const POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_SUCCESS = "POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_SUCCESS";
export const POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_ERROR = "POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_ERROR";

//manage-user(manager)
export const GET_LIST_MANAGER_REQUEST = "GET_LIST_MANAGER_REQUEST";
export const GET_LIST_MANAGER_SUCCESS = "GET_LIST_MANAGER_SUCCESS";
export const GET_LIST_MANAGER_ERROR = "GET_LIST_MANAGER_ERROR";

export const POST_UPDATE_MANAGER_BY_SHOPID_REQUEST = "POST_UPDATE_MANAGER_BY_SHOPID_REQUEST";
export const POST_UPDATE_MANAGER_BY_SHOPID_SUCCESS = "POST_UPDATE_MANAGER_BY_SHOPID_SUCCESS";
export const POST_UPDATE_MANAGER_BY_SHOPID_ERROR = "POST_UPDATE_MANAGER_BY_SHOPID_ERROR";

export const POST_UPDATE_MANAGER_BY_DISPUTEID_REQUEST = "POST_UPDATE_MANAGER_BY_DISPUTEID_REQUEST";
export const POST_UPDATE_MANAGER_BY_DISPUTEID_SUCCESS = "POST_UPDATE_MANAGER_BY_DISPUTEID_SUCCESS";
export const POST_UPDATE_MANAGER_BY_DISPUTEID_ERROR = "POST_UPDATE_MANAGER_BY_DISPUTEID_ERROR";


export const GET_SHOP_FIND_BY_MANAGER_REQUEST = "GET_SHOP_FIND_BY_MANAGER_REQUEST";
export const GET_SHOP_FIND_BY_MANAGER_SUCCESS = "GET_SHOP_FIND_BY_MANAGER_SUCCESS";
export const GET_SHOP_FIND_BY_MANAGER_ERROR = "GET_SHOP_FIND_BY_MANAGER_ERROR";


export const GET_DISPUTE_FIND_BY_MANAGER_REQUEST = "GET_DISPUTE_FIND_BY_MANAGER_REQUEST";
export const GET_DISPUTE_FIND_BY_MANAGER_SUCCESS = "GET_DISPUTE_FIND_BY_MANAGER_SUCCESS";
export const GET_DISPUTE_FIND_BY_MANAGER_ERROR = "GET_DISPUTE_FIND_BY_MANAGER_ERROR";

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";


export const POST_MANAGE_CREATE_USER_REQUEST = "POST_MANAGE_CREATE_USER_REQUEST";
export const POST_MANAGE_CREATE_USER_SUCCESS = "POST_MANAGE_CREATE_USER_SUCCESS";
export const POST_MANAGE_CREATE_USER_ERROR = "POST_MANAGE_CREATE_USER_ERROR";

// klarna
export const GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_REQUEST = "GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_REQUEST"
export const GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_SUCCESS = "GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_SUCCESS"
export const GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_ERROR = "GET_KLARNA_REQUESTED_FIELDS_BY_DISPUTE_ERROR"

// Billing
export const POST_DISPUTE_BILLING_REQUEST = "POST_DISPUTE_BILLING_REQUEST";
export const POST_DISPUTE_BILLING_SUCCESS = "POST_DISPUTE_BILLING_SUCCESS";
export const POST_DISPUTE_BILLING_ERROR = "POST_DISPUTE_BILLING_ERROR";

export const POST_DISPUTE_APPROVE_REQUEST = "POST_DISPUTE_APPROVE_REQUEST";
export const POST_DISPUTE_APPROVE_SUCCESS = "POST_DISPUTE_APPROVE_SUCCESS";
export const POST_DISPUTE_APPROVE_ERROR = "POST_DISPUTE_APPROVE_ERROR";
export const REMOVE_DISPUTE_APPROVE_ERROR = "REMOVE_DISPUTE_APPROVE_ERROR";

export const POST_DISPUTE_READY_STATUS_REQUEST = "POST_DISPUTE_READY_STATUS_REQUEST";
export const POST_DISPUTE_READY_STATUS_SUCCESS = "POST_DISPUTE_READY_STATUS_SUCCESS";
export const POST_DISPUTE_READY_STATUS_ERROR = "POST_DISPUTE_READY_STATUS_ERROR";

// PAYPAL PULL REPORT
export const POST_DISPUTE_PAYPAL_PULL_REPORT_REQUEST = "POST_DISPUTE_PAYPAL_PULL_REPORT_REQUEST"
export const POST_DISPUTE_PAYPAL_PULL_REPORT_SUCCESS = "POST_DISPUTE_PAYPAL_PULL_REPORT_SUCCESS"
export const POST_DISPUTE_PAYPAL_PULL_REPORT_ERROR = "POST_DISPUTE_PAYPAL_PULL_REPORT_ERROR"

export const POST_NEW_BILLING_LIST_REQUEST = "POST_NEW_BILLING_LIST_REQUEST";
export const POST_NEW_BILLING_LIST_SUCCESS = "POST_NEW_BILLING_LIST_SUCCESS";
export const POST_NEW_BILLING_LIST_ERROR = "POST_NEW_BILLING_LIST_ERROR";

export const GET_TOTAL_BILLED_AMOUNT_REQUEST = "GET_TOTAL_BILLED_AMOUNT_REQUEST";
export const GET_TOTAL_BILLED_AMOUNT_SUCCESS = "GET_TOTAL_BILLED_AMOUNT_SUCCESS";
export const GET_TOTAL_BILLED_AMOUNT_ERROR = "GET_TOTAL_BILLED_AMOUNT_ERROR";

export const GET_TOTAL_AMOUNT_REQUEST = "GET_TOTAL_AMOUNT_REQUEST";
export const GET_TOTAL_AMOUNT_SUCCESS = "GET_TOTAL_AMOUNT_SUCCESS";
export const GET_TOTAL_AMOUNT_ERROR = "GET_TOTAL_AMOUNT_ERROR";

export const GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_REQUEST = "GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_REQUEST";
export const GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_SUCCESS = "GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_SUCCESS";
export const GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_ERROR = "GET_NEW_BILLING_TOTAL_BILLED_AMOUNT_ERROR";

export const GET_NEW_BILLING_TOTAL_AMOUNT_REQUEST = "GET_NEW_BILLING_TOTAL_AMOUNT_REQUEST";
export const GET_NEW_BILLING_TOTAL_AMOUNT_SUCCESS = "GET_NEW_BILLING_TOTAL_AMOUNT_SUCCESS";
export const GET_NEW_BILLING_TOTAL_AMOUNT_ERROR = "GET_NEW_BILLING_TOTAL_AMOUNT_ERROR";

export const GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_REQUEST = "GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_REQUEST";
export const GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_SUCCESS = "GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_SUCCESS";
export const GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_ERROR = "GET_READY_DISPUTE_TOTAL_BILLED_AMOUNT_ERROR";

export const GET_READY_DISPUTE_TOTAL_AMOUNT_REQUEST = "GET_READY_DISPUTE_TOTAL_AMOUNT_REQUEST";
export const GET_READY_DISPUTE_TOTAL_AMOUNT_SUCCESS = "GET_READY_DISPUTE_TOTAL_AMOUNT_SUCCESS";
export const GET_READY_DISPUTE_TOTAL_AMOUNT_ERROR = "GET_READY_DISPUTE_TOTAL_AMOUNT_ERROR";

export const GET_NEW_BILLING_READY_DISPUT_REQUEST = "GET_NEW_BILLING_READY_DISPUT_REQUEST";
export const GET_NEW_BILLING_READY_DISPUT_SUCCESS = "GET_NEW_BILLING_READY_DISPUT_SUCCESS";
export const GET_NEW_BILLING_READY_DISPUT_ERROR = "GET_NEW_BILLING_READY_DISPUT_ERROR";

//Reporting
export const GET_REPORTING_REQUEST = "GET_REPORTING_REQUEST";
export const GET_REPORTING_SUCCESS = "GET_REPORTING_SUCCESS";
export const GET_REPORTING_ERROR = "GET_REPORTING_ERROR";

export const GET_BY_STORES_REPORTING_REQUEST = "GET_BY_STORES_REPORTING_REQUEST";
export const GET_BY_STORES_REPORTING_SUCCESS = "GET_BY_STORES_REPORTING_SUCCESS";
export const GET_BY_STORES_REPORTING_ERROR = "GET_BY_STORES_REPORTING_ERROR";

export const GET_BY_PSP_REPORTING_REQUEST = "GET_BY_PSP_REPORTING_REQUEST";
export const GET_BY_PSP_REPORTING_SUCCESS = "GET_BY_PSP_REPORTING_SUCCESS";
export const GET_BY_PSP_REPORTING_ERROR = "GET_BY_PSP_REPORTING_ERROR";

export const GET_BY_REASONS_REPORTING_REQUEST = "GET_BY_REASONS_REPORTING_REQUEST";
export const GET_BY_REASONS_REPORTING_SUCCESS = "GET_BY_REASONS_REPORTING_SUCCESS";
export const GET_BY_REASONS_REPORTING_ERROR = "GET_BY_REASONS_REPORTING_ERROR";

export const GET_BY_MONTHLY_REPORTING_REQUEST = "GET_BY_MONTHLY_REPORTING_REQUEST";
export const GET_BY_MONTHLY_REPORTING_SUCCESS = "GET_BY_MONTHLY_REPORTING_SUCCESS";
export const GET_BY_MONTHLY_REPORTING_ERROR = "GET_BY_MONTHLY_REPORTING_ERROR";

//filtered_waiting_seller_ response
export const GET_FILTERED_WAITING_SELLER_REQUEST = "GET_FILTERED_WAITING_SELLER_REQUEST";
export const GET_FILTERED_WAITING_SELLER_SUCCESS = "GET_FILTERED_WAITING_SELLER_SUCCESS";
export const GET_FILTERED_WAITING_SELLER_ERROR = "GET_FILTERED_WAITING_SELLER_ERROR";

// under review disputes
export const GET_FETCH_UNDER_REVIEW_DIPSUTES_REQUEST = "GET_FETCH_UNDER_REVIEW_DISPUTES_REQUEST";
export const GET_FETCH_UNDER_REVIEW_DISPUTES_SUCCESS = "GET_FETCH_UNDER_REVIEW_DISPUTES_SUCCESS";
export const GET_FETCH_UNDER_REVIEW_DISPUTES_ERROR = "GET_FETCH_UNDER_REVIEW_DISPUTES_ERROR"

export const GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_REQUEST = "GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_REQUEST";
export const GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_SUCCESS = "GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_SUCCESS";
export const GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_ERROR = "GET_UNDER_REVIEW_TOTAL_BILLED_AMOUNT_ERROR";

export const GET_UNDER_REVIEW_TOTAL_AMOUNT_REQUEST = "GET_UNDER_REVIEW_TOTAL_AMOUNT_REQUEST";
export const GET_UNDER_REVIEW_TOTAL_AMOUNT_SUCCESS = "GET_UNDER_REVIEW_TOTAL_AMOUNT_SUCCESS";
export const GET_UNDER_REVIEW_TOTAL_AMOUNT_ERROR = "GET_UNDER_REVIEW_TOTAL_AMOUNT_ERROR";
