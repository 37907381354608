import { getDisputeTrackingDetailsError } from './../actions';
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface DisputeDetailsResProp {
  data: {
    id: string;
    createTime: string;
    updateTime: string;
    status: string;
    reason: string;
    disputeState: string;
    disputeLifeCycleStage: string;
    order: string;
    storeOrderId: string;
    shopName: string;
    shopType: string;
    sellerMerchantId: string;
    disputeAmountValue: number;
    disputeAmountCurrencyCode: string;
    sellerResponseDueDate: string;
    buyerResponseDueDate: string;
    sellerTransactionId: string;
    paypalId: string;
    email: string;
    perEmail: string;
  };
  status: number
}
interface DisputeCustomerDetailsResProps {
  data: {
    id: string;
    customerEmail: string;
    customerFirstName: string;
    customerLastName: string;
    orderStatusUrl: string;
    shippingAddress: {
      id: string;
      address1: string;
      address2: string;
      city: string;
      province: string;
      province_code: string;
      country: string;
      country_code: string;
      zip: string;
      lastName: string;
      firstName: string;
      phone: string;
      name: string;
      disputeId: string;
    };
    billingAddress: {
      id: string;
      firstName: string;
      lastName: string;
      address1: string;
      city: string;
      zip: string;
      country: string;
      address2: string;
      name: string;
      countryCode: string;
      provinceCode: string;
      province: string;
      disputeId: string;
    };
  };
  status: number
}

interface ManageDisputeTypeOptionsProps {
  data: [
    {
      evidenceTypeValue: string;
      description: string;
    }
  ];
  status: number
}

export function* getDisputeDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeDetailsError());
    } else {
      yield put(actions.getDisputeDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeDetailsError());
  }
}

export function* getDisputeOrderSummaryRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeOrderSummary(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeOrderSummaryError());
    } else {
      yield put(actions.getDisputeOrderSummarySuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeOrderSummaryError());
  }
}

export function* getDisputeTransactionDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeTransactionDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeTransactionDetailsError());
    } else {
      yield put(actions.getDisputeTransactionDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeTransactionDetailsError());
  }
}

export function* getDisputeCustomerDetailsRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeByCustomerDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeCustomerDetailsError());
    } else {
      yield put(actions.getDisputeCustomerDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeCustomerDetailsError());
  }
}

export function* getDisputeJiraCommentsRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeByJiraComments(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeJiraCommentsError());
    } else {
      yield put(actions.getDisputeJiraCommentsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeJiraCommentsError());
  }
}

export function* getDisputeOrderFulfillmentDetailsRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeOrderFullmentDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeOrderFulfillmentDetailsError());
    } else {
      yield put(actions.getDisputeOrderFulfillmentDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeOrderFulfillmentDetailsError());
  }
}

export function* getDisputeTrackingDetailsRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeTrackingDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeTrackingDetailsError());
    } else {
      yield put(actions.getDisputeTrackingDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeTrackingDetailsError());
  }
}

export function* getDisputeFraudAnalysisRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeFraudAnalysisDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeFraudAnalysisError());
    } else {
      yield put(actions.getDisputeFraudAnalysisSuccess(res));
    }
  } catch (e) {
    yield put(actions.getDisputeFraudAnalysisError());
  }
}

export function* getDisputeGetTrackingRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeGetTrackingDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeTrackingError());
    } else {
      yield put(actions.getDisputeTrackingSuccess(res));
    }
  } catch (e) {
    yield put(actions.getDisputeTrackingError());
  }
}

export function* getResponseJsonByIdRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageResponseJsonById(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeResponseJsonByIdError());
    } else {
      yield put(actions.getDisputeResponseJsonByIdSuccess(res));
    }
  } catch (e) {
    yield put(actions.getDisputeResponseJsonByIdError());
  }
}

export function* getDisputeShippingDetailsRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.getManageDisputeShippingDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeShippingDetailsError());
    } else {
      yield put(actions.getDisputeShippingDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeShippingDetailsError());
  }
}

export function* postDisputeSubmittedRequest(action: any) {
  try {
    const res: DisputeCustomerDetailsResProps =
      yield Api.postManageSubmittedDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.postDisputeSubmittedError());
    } else {
      yield put(actions.postDisputeSubmittedSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.postDisputeSubmittedError());
  }
}


export function* getDisputeEvidenceDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeEvidenceDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeEvidenceDetailsError());
    } else {
      yield put(actions.getDisputeEvidenceDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeEvidenceDetailsError());
  }
}

export function* getDisputeMessageDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeMessageDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeMessageDetailsError());
    } else {
      yield put(actions.getDisputeMessageDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeMessageDetailsError());
  }
}

export function* getDisputeAdjudicationDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeAdjudicationDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeAdjudicationDetailsError());
    } else {
      yield put(actions.getDisputeAdjudicationDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeAdjudicationDetailsError());
  }
}

export function* getDisputeMoneyMovementDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeMoneyMovementDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeMoneyMovementDetailsError());
    } else {
      yield put(actions.getDisputeMoneyMovementDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeMoneyMovementDetailsError());
  }
}

export function* getDisputeSupportingInfoDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp =
      yield Api.getDisputeSupportingInfoDetails(action.payload);
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeSupportingInfoDetailsError());
    } else {
      yield put(actions.getDisputeSupportingInfoDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeSupportingInfoDetailsError());
  }
}

export function* getDisputeOfferDetailsRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getDisputeOfferDetails(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getDisputeOfferDetailsError());
    } else {
      yield put(actions.getDisputeOfferDetailsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getDisputeOfferDetailsError());
  }
}

export function* postDisputeFlowUploadFileRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.postDisputeFlowUploadFile(
      action.payload
    );
    yield put(actions.postDisputeFlowUploadFileSuccess(res.data));
  } catch (e) {
    yield put(actions.postDisputeFlowUploadFileError());
  }
}

export function* postDisputeSendMessageRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.postDisputeSendMessage(
      action.payload
    );
    yield put(actions.postDisputeSendMessageSuccess(res.data));
  } catch (e) {
    yield put(actions.postDisputeSendMessageError());
  }
}

//manage-dispute
export function* postManageDisputeSendMessageRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.postManageDisputeSendMessage(
      action.payload
    );
    yield put(actions.postManageDisputeSendMessageSuccess(res.data));
  } catch (e) {
    yield put(actions.postManageDisputeSendMessageError());
  }
}
export function* getManageDisputeCarrierOptionsRequest() {
  try {
    const res: DisputeDetailsResProp =
      yield Api.getManageDisputeCarrierOptions();
    if (res.status === (500 || 401)) {
      yield put(actions.getManageDisputeCarrierOptionsError());
    } else {
      yield put(actions.getManageDisputeCarrierOptionsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getManageDisputeCarrierOptionsError());
  }
}
export function* getManageDisputeEvidenceTypeOptionsRequest() {
  try {
    const res: ManageDisputeTypeOptionsProps =
      yield Api.getManageDisputeEvidenceTypeOptions();
    if (res.status === (500 || 401)) {
      yield put(actions.getManageDisputeEvidenceTypeOptionsError());
    } else {
      yield put(actions.getManageDisputeEvidenceTypeOptionsSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getManageDisputeEvidenceTypeOptionsError());
  }
}
export function* postManageDisputeAppealDisputeRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.postManageDisputeAppealDispute(
      action.payload
    );
    yield put(actions.postManageDisputeAppealDisputeSuccess(res.data));
  } catch (e) {
    yield put(actions.postManageDisputeAppealDisputeError());
  }
}
export function* postManageDisputeAcknowledgeReturnItemRequest(action: any) {
  try {
    const res: DisputeDetailsResProp =
      yield Api.postManageDisputeAcknowledgeReturnItem(action.payload);
    yield put(actions.postManageDisputeAcknowledgeReturnItemSuccess(res.data));
  } catch (e) {
    yield put(actions.postManageDisputeAcknowledgeReturnItemError());
  }
}
export function* postManageDisputeAcceptClaimRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.postManageDisputeAcceptClaim(
      action.payload
    );
    yield put(actions.postManageDisputeAcceptClaimSuccess(res.data));
  } catch (e) {
    yield put(actions.postManageDisputeAcceptClaimError());
  }
}
export function* postManageDisputeManageManuallyRequest(action: any) {
  try {
    const res: DisputeDetailsResProp =
      yield Api.postManageDisputeManageManually(action.payload);
    yield put(actions.postManageDisputeManageManuallySuccess(res.data));
  } catch (e) {
    yield put(actions.postManageDisputeManageManuallyError());
  }
}
export function* getManageDisputeHateoasLinkRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getManageDisputeHateoasLink(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getManageDisputeHateoasLinkError());
    } else {
      yield put(actions.getManageDisputeHateoasLinkSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getManageDisputeHateoasLinkError());
  }
}
export function* getManageDisputeFlowRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getManageDisputeFlow(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getManageDisputeFlowError());
    } else {

      yield put(actions.getManageDisputeFlowSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getManageDisputeFlowError());
  }
}
export function* getFetchDisputeTemplateRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getFetchDisputeTemplate(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getFetchDisputeTemplateError());
    } else {

      yield put(actions.getFetchDisputeTemplateSuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getFetchDisputeTemplateError());
  }
}
export function* getFetchTemplateSummaryRequest(action: any) {
  try {
    const res: DisputeDetailsResProp = yield Api.getFetchTemplateSummary(
      action.payload
    );
    if (res.status === (500 || 401)) {
      yield put(actions.getFetchTemplateSummaryError());
    } else {

      yield put(actions.getFetchTemplateSummarySuccess(res.data));
    }
  } catch (e) {
    yield put(actions.getFetchTemplateSummaryError());
  }
}