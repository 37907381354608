import { CopyIcon, svgIcons } from 'elements';
import styles from './style.module.scss';
import { EditIcon } from '../NodeHelpers/flowIcons';
import { Button } from 'components/ui';
import Loading from 'components/loading/loading';
import { Avatar, Tooltip } from '@mui/material';
import { DisputeFlowData } from 'hooks/flowHooks/useSystemFlow';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getDisputeFlowListRequest, postDisputeFlowDuplicateIdRequest } from 'redux/actions';
import dayjs from "dayjs"
import Swal from "sweetalert2"

interface TableData {
  data: DisputeFlowData[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const recentDisputesColumns = [
  'Flow name',
  'Payment Gateway',
  'Created at',
  'Created by',
  'Last updated at',
  'Updated by',
  'Actions',
];

const FlowTable = ({ data, isLoading, isSuccess, isError }: TableData) => {
  const { storeId } = useParams();
  const dispatch = useDispatch();

  const handleCopyFlow = (flowId: number) => {
    const payload = {
      id: flowId,
      shopId:storeId,
      suffix: 'flowList',
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this after this operation!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, copied it!"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(postDisputeFlowDuplicateIdRequest(payload));
        dispatch(getDisputeFlowListRequest());
        Swal.fire({
          title: "Success!",
          text: "Your file has been copied",
          icon: "success"
        });
      }
    });
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {recentDisputesColumns.map(recentDisputesColumn => (
                  <th key={recentDisputesColumn}>{recentDisputesColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((flow: DisputeFlowData, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{flow?.name}</td>
                  <td>{flow?.paymentGateway}</td>
                  <td>
                    {flow?.createdOn
                      ? dayjs(flow?.createdOn).format('MM/DD/YYYY HH:mm')
                      : null}
                  </td>
                  <td>
                    {flow?.createdBy ? (
                      <div>
                        <Tooltip
                          title={flow?.createdBy ?? 'manager'}
                          arrow
                          placement='top'
                        >
                          <Avatar className={styles.avatarStyle}>
                            <span className={styles.firstLetter}>
                              {flow?.createdBy?.split('')[0]}
                            </span>
                            <span className={styles.secondLetter}>
                              {flow?.createdBy?.split('')[1]}
                            </span>
                          </Avatar>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {flow?.lastUpdatedOn
                      ? dayjs(flow?.lastUpdatedOn).format('MM/DD/YYYY HH:mm')
                      : null}
                  </td>
                  <td>
                    {flow?.lastUpdatedBy ? (
                      <div>
                        <Tooltip
                          title={flow?.lastUpdatedBy ?? 'manager'}
                          arrow
                          placement='top'
                        >
                          <Avatar className={styles.avatarStyle}>
                            <span className={styles.firstLetter}>
                              {flow?.lastUpdatedBy?.split('')[0]}
                            </span>
                            <span className={styles.secondLetter}>
                              {flow?.lastUpdatedBy?.split('')[1]}
                            </span>
                          </Avatar>
                        </Tooltip>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <div className={styles.flowButtonWrapper}>
                      <Button
                        {...{
                          btnLabel: '',
                          btnTitle: 'Click to edit',
                          href: `/flows-edit/${flow.id}`,
                          startIcon: <EditIcon style={{ marginLeft: '5px' }} />,
                        }}
                      />

                      {storeId && (
                        <Button
                          {...{
                            btnLabel: '',
                            btnTitle: 'Click to copy',
                            // href: `/flows-edit/${flow.id}`,
                            startIcon: <CopyIcon />,
                            onClick: () => handleCopyFlow(flow?.id),
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) || (isSuccess && data?.length === 0)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No Flow</h3>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default FlowTable;
