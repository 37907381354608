import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getDisputeJiraCommentsRequest } from "redux/actions";

interface RootState {
    disputeDetailsStore: {
        jiraComments: {
            isSuccess: boolean;
            data: any;
            isLoading: boolean;
        };
    };
}

const useJiraComments = () => {
    const dispatch = useDispatch();
    const { disputeId } = useParams();

    const { isSuccess, data, isLoading } = useSelector(
        (state: RootState) =>
            state?.disputeDetailsStore?.jiraComments
    );
    useEffect(() => {
        if (disputeId) {
            dispatch(getDisputeJiraCommentsRequest(disputeId));
        }
    }, [disputeId]);
    return { data, isLoading, isSuccess };
};
export default useJiraComments
