import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";
const initialState = {
    papalReportsList: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
}

// new billing list
const postDisputePaypalPullReportsRequest = (state: any, action: any) => {
    return update(state, {
        papalReportsList: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const postDisputePaypalPullReportsSuccess = (state: any, action: any) => {
    return update(state, {
        papalReportsList: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postDisputePaypalPullReportsError = (state: any, action: any) => {
    return update(state, {
        papalReportsList: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


export default handleActions(
    {
        [constants.POST_DISPUTE_PAYPAL_PULL_REPORT_REQUEST]: postDisputePaypalPullReportsRequest,
        [constants.POST_DISPUTE_PAYPAL_PULL_REPORT_SUCCESS]: postDisputePaypalPullReportsSuccess,
        [constants.POST_DISPUTE_PAYPAL_PULL_REPORT_ERROR]: postDisputePaypalPullReportsError,
    },
    initialState
);