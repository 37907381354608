import { Button, Box, Typography, Tab, Tabs } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Loading from "../loading/loading";
import CompanyBackground from "./companyBackground";
import CompanyPolicies from "./companyPolicies";
import styles from "./style.module.scss";
import { DisputeFlows, DisputeTemplates, Disputes } from "views";
import { useEffect, useState } from "react";
import { useManagerList } from "hooks";
// import SelectUpdateManager from "./selectUpdateManager";
import SelectAccess from "./selectAccess";

// changes
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StoresView = ({
  handleChange,
  viewInShopify,
  storeDetails,
  value,
  shopFindShop,
  shopFindShopAddon,
  setShopAddonCheck,
  shopsSaveAddon,
  currentSelected,
}: any) => {
  const { manageManager } = useManagerList();
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className={styles.formContainer}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const defaultValue =
    manageManager?.data.length > 0 &&
    manageManager?.data.find(
      (item: any) => item?.userName === shopFindShop?.data?.manager
    );
  const [selectedValue, setSelectedValue] = useState<any>();

  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue(null);
    }
  }, [defaultValue]);
  return (
    <div className={styles.storeViewWrapper}>
      <ToastContainer />
      {!["disputes", "templates", "flows"].includes(currentSelected) && (
        <div className={styles.storeDetailsWrapper}>
          {shopFindShop?.isLoading ? (
            <Loading />
          ) : (
            <div>
              <div className={styles.storeDetailsHead}>
                <h4>Details</h4>
                <div className={styles.storeDetailsNav}>
                  <div
                    className={styles.storeNav}
                    style={viewInShopify ? {} : { marginTop: "1rem" }}
                  >
                    <div
                      className={styles.stores}
                      style={{
                        backgroundColor:
                          shopFindShop?.data?.shopType?.toLowerCase() ===
                          "shopify"
                            ? "#b4fed2"
                            : "#fed3d1",
                        color:
                          shopFindShop?.data?.shopType?.toLowerCase() ===
                          "shopify"
                            ? "#0c5132"
                            : "#8e1f0b",
                      }}
                    >
                      <p>Shopify Integration</p>
                    </div>
                    <div
                      className={styles.stores}
                      style={{
                        backgroundColor: shopFindShop?.data?.paypalActive
                          ? "#b4fed2"
                          : "#fed3d1",
                        color: shopFindShop?.data?.paypalActive
                          ? "#0c5132"
                          : "#8e1f0b",
                      }}
                    >
                      <p>PayPal</p>
                    </div>
                    <div
                      className={styles.stores}
                      style={{
                        backgroundColor: shopFindShop?.data?.chargeActive
                          ? "#b4fed2"
                          : "#fed3d1",
                        color: shopFindShop?.data?.chargeActive
                          ? "#0c5132"
                          : "#8e1f0b",
                      }}
                    >
                      <p>
                        {shopFindShop?.data?.chargeActive
                          ? "Valid Charge"
                          : "Invalid Charge"}
                      </p>
                    </div>
                    {shopFindShopAddon?.data?.crawlerStatus && (
                      <div
                        className={styles.stores}
                        style={{
                          backgroundColor:
                            shopFindShopAddon?.data?.crawlerStatus === "SUCCESS"
                              ? "#b4fed2"
                              : "#fed3d1",
                          color:
                            shopFindShopAddon?.data?.crawlerStatus === "SUCCESS"
                              ? "#0c5132"
                              : "#8e1f0b",
                        }}
                      >
                        <p>Crawler</p>
                      </div>
                    )}
                  </div>
                  {viewInShopify && (
                    <a
                      target="_blank"
                      href={
                        shopFindShop?.data?.externalId &&
                        `https://partners.shopify.com/1258116/stores/${shopFindShop?.data?.externalId}`
                      }
                      rel="noreferrer"
                    >
                      <Button variant="contained">View in Shopify</Button>
                    </a>
                  )}
                </div>
              </div>
              <div className={styles.storeDetails}>
                {!shopFindShop?.isLoading &&
                  storeDetails?.length > 0 &&
                  storeDetails?.map((detail: any) => {
                    return (
                      <div className={styles.storeDetail} key={detail?.title}>
                        <p className={styles.detailTitle}>{detail?.title}</p>
                        {detail?.title === "Collaborator Access:" ? (
                          <div>
                            <SelectAccess
                              {...{ collaboratorAccess: detail.data }}
                            />
                          </div>
                        ) : detail?.title === 'Domain:' ? <a href={`https://${detail?.data}`} target="_blank" className={styles.detailData}>{detail?.data}</a>:(
                          <p className={styles.detailData}>{detail?.data}</p>
                        )}
                      </div>
                    );
                  })}
              </div>
              {/* <div>
                <SelectUpdateManager
                  {...{
                    selectedValue,
                    setSelectedValue,
                    defaultValue,
                    dispute: false,
                    shopFindShop: shopFindShop?.data,
                    toUsers: manageManager?.data,
                  }}
                />
              </div> */}
            </div>
          )}
        </div>
      )}
      {currentSelected === "disputes" && (
        <Disputes
          shop={{
            value: shopFindShop?.data?.id,
            label: shopFindShop?.data?.name,
          }}
        />
      )}
      {currentSelected === "templates" && <DisputeTemplates hideHeader />}
      {currentSelected === "flows" && <DisputeFlows hideHeader />}
      {!["disputes", "templates", "flows"].includes(currentSelected) && (
        <div className={styles.storeDetailsWrapper}>
          <div className={styles.storeForm}>
            <h4>Settings</h4>
            <div className={styles.form}>
              {shopsSaveAddon?.isLoading ? (
                <Loading />
              ) : (
                <div className={styles.manageFormWrapper}>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      className={styles.manageFormTabsWrapper}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab
                          className={styles.manageFormTabs}
                          label="Company Background"
                        />
                        <Tab
                          className={styles.manageFormTabs}
                          label="Company Policies"
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <CompanyBackground {...{ setShopAddonCheck }} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <CompanyPolicies {...{ setShopAddonCheck }} />
                    </CustomTabPanel>
                  </Box>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoresView;
