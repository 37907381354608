import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function BasicSelect({
  label,
  options,
  field,
  placeholder,
}: any) {
  return (
    <FormControl fullWidth>
      <label style={{ marginBottom: "4px" }}>{label}</label>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        onChange={(e) => field.onChange(e.target.value)}
        defaultValue={1}
        {...field}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: "300px",
              "& .MuiMenuItem-root": {
                padding: 1,
              },
              maxWidth: "345px"
            },
          },
        }}
        size="small"
        IconComponent={KeyboardArrowDownIcon}
      >
        <MenuItem disabled value={1}>
          <p style={{color:"gray",fontSize:"14px"}}>{placeholder}</p>
        </MenuItem>
        {options && options?.length > 0
          ? options?.map((item: any) => (
              <MenuItem
                value={item?.id}
                key={item?.id}
              >
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item?.name}
                </p>
              </MenuItem>
            ))
          : []}
      </Select>
    </FormControl>
  );
}
