import { useState } from "react";
import styles from "./style.module.scss";
import { BreadcumbItem } from "components/ui";
import { Box, Tab, Tabs } from "@mui/material";
import { useBreadCumbs } from "hooks"; //TODO `need to fetch only billing reports data  - need to remove unnecessary api call from this hook
import BillingHistory from "components/billingHistory";
import NewBilling from "components/newBilling";
import BillingSetup from "components/billingSetup";
import useGlobalBilling from "hooks/billingReports/useGlobalBilling";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: "16px" }}>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CurrentBilling = () => {
  const {
    mapedForm,
    formFunction,
  } = useGlobalBilling();
  const [value, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const { billingBreadCumbs: breadCumbs } = useBreadCumbs({
    pageName: "billing",
  });
  const [selectedDisputes, setSelectedDisputes] = useState<number[]>([]);
  const [selectedReadyDisputes, setSelectedReadyDisputes] = useState<number[]>(
    []
  );
  const handleCheckboxChange = (disputeId: number) => {
    setSelectedDisputes((prevSelected) =>
      prevSelected.includes(disputeId)
        ? prevSelected.filter((id) => id !== disputeId)
        : [...prevSelected, disputeId]
    );
  };
  const handleReadyDisputeCheckbox = (disputeId: number) => {
    setSelectedReadyDisputes((prevSelected) =>
      prevSelected.includes(disputeId)
        ? prevSelected.filter((id) => id !== disputeId)
        : [...prevSelected, disputeId]
    );
  };

  return (
    <div className={styles.disputesWrapper}>
      <div className={styles.nav}>
        <h3>Billing</h3>
        <div className={styles.navPath}>
          {breadCumbs.map((item, key) => (
            <BreadcumbItem {...item} {...{ key }} />
          ))}
        </div>
      </div>

      {/* implementation of tabs */}
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "#fff",
            borderRadius: "8px",
            padding: "0 25px"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              style={{ textTransform: "capitalize" }}
              label="Billing Setup"
              {...a11yProps(0)}
            />
            <Tab
              style={{ textTransform: "capitalize" }}
              label="New Billing"
              {...a11yProps(1)}
            />
            <Tab
              style={{ textTransform: "capitalize" }}
              label="History"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <BillingSetup
            selectedDisputes={selectedDisputes}
            handleCheckboxChange={handleCheckboxChange}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <NewBilling
            mapedForm={mapedForm}
            formFunction={formFunction}
            selectedDisputes={selectedReadyDisputes}
            handleCheckboxChange={handleReadyDisputeCheckbox}
            setSelectedDisputes={setSelectedReadyDisputes}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <BillingHistory
            selectedDisputes={selectedDisputes}
            handleCheckboxChange={handleCheckboxChange}
          />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default CurrentBilling;
