import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import useOpenDatePicker from './useOpenDatePicker';

/**
 * this is the custom hook for date filter
 * It can be working anythere where date filter is requried just pass the disputesPayload and setDisputesPayload props
 * working properly
 */

const useDateRangeFilter = (disputesPayload:any,setDisputesPayload:any) => {
  const { openDatePicker, datePickerRef } = useOpenDatePicker();
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFilterCheck, setDateFilterCheck] = useState(false);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (dateFilterCheck && startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      const datePayload = {
        ...disputesPayload,
        page: 1,
        fromDate: formattedStartDate,
        toDate: formattedEndDate,
      };
      setDisputesPayload(datePayload);
    } else if (dateFilterCheck && !startDate && !endDate) {
      const datePayload = {
        ...disputesPayload,
        page: 1,
        fromDate: null,
        toDate: null,
      };
      setDisputesPayload(datePayload);
    }
  }, [dateFilterCheck, startDate, endDate]);

  return {
    dateRange,
    setDateRange,
    dateFilterCheck,
    setDateFilterCheck,
    disputesPayload,
    startDate,
    endDate,
    openDatePicker,
    datePickerRef
  };
};

export default useDateRangeFilter;
