import { PostCreateUserData } from "../api-invoker/post-create-user";
import { PostConnectPayPal } from "../api-invoker/post-connect-paypal";
import { GetTotalRevenueProtected } from "../api-invoker/get-total-revenue-protected";
import { GetLinkedShops } from "../api-invoker/get-linked-shops";
import { DeleteLinkedShops } from "../api-invoker/delete-linked-shops";
import { GetLinkedPayPal } from "../api-invoker/get-linked-payPal";
import { GetRecentDisputes } from "../api-invoker/get-recent-disputes";
import { GetOpenDisputes } from "../api-invoker/get-open-disputes";
import { GetRecoveredDisputes } from "../api-invoker/get-recovered-disputes";
import { GetCurrentWeekDisputes } from "../api-invoker/get-current-week-disputes";
import { GetDisputesList } from "../api-invoker/get-disputes-list";
import { PostFilteredDisputes } from "../api-invoker/post-filtered-disputes";
import { PostDisputesFetchByAccount } from "../api-invoker/post-disputes-fetch-by-account";
import { GetProcessingDisputes } from "../api-invoker/get-processing-disputes";
import { GetAwaitingResponseDisputes } from "../api-invoker/get-awaiting-response-disputes";
import { GetDisputeDetails } from "../api-invoker/get-dispute-details";
import { GetDisputeTransactionDetails } from "../api-invoker/get-dispute-transaction-details";
import { GetDisputeEvidenceDetails } from "../api-invoker/get-dispute-evidence-details";
import { GetDisputeMessageDetails } from "../api-invoker/get-dispute-message-details";
import { GetDisputeAdjudicationDetails } from "../api-invoker/get-dispute-adjudication-details";
import { GetDisputeMoneyMovementDetails } from "../api-invoker/get-dispute-money-movement-details";
import { GetDisputeSupportingInfoDetails } from "../api-invoker/get-dispute-supporting-info-details";
import { GetDisputeOfferDetails } from "../api-invoker/get-dispute-offer-details";
import { PostDisputeFlowUploadFile } from "../api-invoker/post-dispute-flow-upload-file";
import { PostAccountList } from "../api-invoker/post-account-list";
import { PostManageDispute } from "../api-invoker/post-manage-dispute";
import { PostDisputeTemplate } from "../api-invoker/post-dispute-template";
import { GetDisputeCustomerDetails } from "../api-invoker/get-dispute-customerDetails"
import { PostDisputeDetailsSendMessage } from "../api-invoker/post-dispute-details-send-message"
//dispute-response
import { GetDisputeResponse } from "../api-invoker/get-dispute-response";
//stores-shop
import { GetShopAllShops } from "../api-invoker/get-shop-all-shops";
import { PostDisputeFlow } from "../api-invoker/post-dispute-flow";
import { GetAllListOfManagers } from "../api-invoker/get-user-manager";
import { GetDisputeOrderFulfillmentDetails } from '../api-invoker/get-dispute-order-fulfillment';
import { GetKlarnaByDispute } from '../api-invoker/get-klarna-by-dispute';
import { PostBillingReportsList } from '../api-invoker/post-billing-reports';
import {PostPaypalPullReport} from "../api-invoker/post-dispute-paypal-pull-report"
import { GetBillingReportList } from "../api-invoker/get-billing-report";
import { GetReportingList } from "../api-invoker/get-reporting-list";
//awaiting-seller-response
import { GetAwaitingDisputes } from "../api-invoker/get-awaiting-disputes";
// filtered-seller-awaiting-seller-response
import { GetFilteredAwaitingDisputes } from '../api-invoker/get-filtered-awaiting-disputes'
import { GetUnderReviewDisputes } from "../api-invoker/get-under-review-disputes";

export class Api {

    static async postCreateUserData(payload: any) {
        return new PostCreateUserData().invoke(payload)
    }

    static async postConnectPayPal(payload: any) {
        return new PostConnectPayPal().invoke(payload)
    }

    static async getTotalRevenueProtected() {
        return new GetTotalRevenueProtected().invoke()
    }

    static async getTotalRevenueProtectedByAccount(payload: string) {
        return new GetTotalRevenueProtected().invokeByAccount(payload)
    }

    static async getLinkedShops() {
        return new GetLinkedShops().invoke()
    }

    static async getLinkedShopsByAccount(payload: string) {
        return new GetLinkedShops().invokeByAccount(payload)
    }

    static async deleteLinkedShops(payload: number) {
        return new DeleteLinkedShops().invoke(payload)
    }

    static async getLinkedPayPal() {
        return new GetLinkedPayPal().invoke()
    }

    static async getLinkedPayPalByAccount(payload: string) {
        return new GetLinkedPayPal().invokeByAccount(payload)
    }

    static async getRecentDisputes() {
        return new GetRecentDisputes().invoke()
    }

    static async getRecentDisputesByAccount(payload: string) {
        return new GetRecentDisputes().invokeByAccount(payload)
    }

    static async getOpenDisputes() {
        return new GetOpenDisputes().invoke()
    }

    static async getOpenDisputesByAccount(payload: string) {
        return new GetOpenDisputes().invokeByAccount(payload)
    }

    static async getRecoveredDisputes() {
        return new GetRecoveredDisputes().invoke()
    }

    static async getRecoveredDisputesByAccount(payload: string) {
        return new GetRecoveredDisputes().invokeByAccount(payload)
    }

    static async getCurrentWeekDisputes() {
        return new GetCurrentWeekDisputes().invoke()
    }

    static async getCurrentWeekDisputesByAccount(payload: string) {
        return new GetCurrentWeekDisputes().invokeByAccount(payload)
    }

    static async getDisputesList(payload: any) {
        return new GetDisputesList().invoke(payload)
    }

    static async getFilteredDisputes(payload: any) {
        return new PostFilteredDisputes().invoke(payload)
    }

    static async postDisputesFetchByAccount(payload: any) {
        return new PostDisputesFetchByAccount().invoke(payload)
    }

    static async getProcessingDisputes() {
        return new GetProcessingDisputes().invoke()
    }

    // awaiting-seller- response
    static async getAwaitingDisputes(payload: any) {
        return new GetAwaitingDisputes().invoke(payload)
    }

    static async getAwaitingDisputesTotalBilledAmount(payload: any) {
        return new GetAwaitingDisputes().invokeByTotalBilledAmount(payload)
    }

    static async getAwaitingDisputesTotalAmount(payload: any) {
        return new GetAwaitingDisputes().invokeByTotalAmount(payload)
    }

    // filterd-awaiting-seller-response
    static async getFilteredAwaitingDisputes(payload: any) {
        return new GetFilteredAwaitingDisputes().invoke(payload)
    }

    static async getProcessingDisputesByAccount(payload: string) {
        return new GetProcessingDisputes().invokeByAccount(payload)
    }

    static async getAwaitingResponseDisputes() {
        return new GetAwaitingResponseDisputes().invoke()
    }

    static async getAwaitingResponseDisputesByAccount(payload: string) {
        return new GetAwaitingResponseDisputes().invokeByAccount(payload)
    }

    //dispute-details
    static async getDisputeDetails(payload: any) {
        return new GetDisputeDetails().invoke(payload)
    }

    static async getManageDisputeTrackingDetails(payload: any) {
        return new GetDisputeDetails().invokeByTrackingDetails(payload)
    }

    static async getManageDisputeGetTrackingDetails(payload: any) {
        return new GetDisputeDetails().invokeByGetTrackingDetails(payload)
    }

    static async getManageResponseJsonById(payload: any) {
        return new GetDisputeDetails().invokeByResponseJsonById(payload)
    }

    static async getManageDisputeFraudAnalysisDetails(payload: any) {
        return new GetDisputeDetails().invokeByFraudAnalysisDetails(payload)
    }

    static async getManageDisputeShippingDetails(payload: any) {
        return new GetDisputeDetails().invokeByShippingDetails(payload)
    }
    static async postManageSubmittedDetails(payload: any) {
        return new GetDisputeDetails().invokeBySubmitedDetails(payload)
    }
    static async getDisputeOrderSummary(payload: any) {
        return new GetDisputeTransactionDetails().invokeOrderSummary(payload)
    }

    static async getDisputeTransactionDetails(payload: any) {
        return new GetDisputeTransactionDetails().invoke(payload)
    }

    static async getManageDisputeByCustomerDetails(payload: any) {
        return new GetDisputeCustomerDetails().invoke(payload)
    }
    static async getManageDisputeByJiraComments(payload: any) {
        return new GetDisputeDetails().invokeByJiraComments(payload)
    }

    static async getManageDisputeOrderFullmentDetails(payload: any) {
        return new GetDisputeOrderFulfillmentDetails().invoke(payload)
    }

    static async getDisputeEvidenceDetails(payload: any) {
        return new GetDisputeEvidenceDetails().invoke(payload)
    }

    static async getDisputeMessageDetails(payload: any) {
        return new GetDisputeMessageDetails().invoke(payload)
    }

    static async getDisputeAdjudicationDetails(payload: any) {
        return new GetDisputeAdjudicationDetails().invoke(payload)
    }

    static async getDisputeMoneyMovementDetails(payload: any) {
        return new GetDisputeMoneyMovementDetails().invoke(payload)
    }

    static async getDisputeSupportingInfoDetails(payload: any) {
        return new GetDisputeSupportingInfoDetails().invoke(payload)
    }

    static async getDisputeOfferDetails(payload: any) {
        return new GetDisputeOfferDetails().invoke(payload)
    }

    static async postDisputeFlowUploadFile(payload: any) {
        return new PostDisputeFlowUploadFile().invoke(payload)
    }

    static async postDisputeSendMessage(payload: any) {
        return new PostDisputeDetailsSendMessage().invoke(payload)
    }

    static async postAccountList(payload: any) {
        return new PostAccountList().invoke(payload)
    }
    //manage-dispute
    static async postManageDisputeSendMessage(payload: any) {
        return new PostManageDispute().invokeSendMessage(payload)
    }

    static async getManageDisputeCarrierOptions() {
        return new PostManageDispute().invokeCarrierOptions()
    }

    static async getManageDisputeEvidenceTypeOptions() {
        return new PostManageDispute().invokeEvidenceTypeOptions()
    }

    static async postManageDisputeAppealDispute(payload: any) {
        return new PostManageDispute().invokeAppealDispute(payload)
    }

    static async postManageDisputeManageManually(payload: any) {
        return new PostManageDispute().invokeManageManuallyDispute(payload)
    }

    static async postManageDisputeAcknowledgeReturnItem(payload: any) {
        return new PostManageDispute().invokeAcknowledgeReturnItem(payload)
    }

    static async postManageDisputeAcceptClaim(payload: any) {
        return new PostManageDispute().invokeAcceptClaim(payload)
    }

    static async getManageDisputeHateoasLink(payload: any) {
        return new PostManageDispute().invokeHateoasLink(payload)
    }

    //dispute-template
    static async postDisputeTemplate(payload: any) {
        return new PostDisputeTemplate().invoke(payload)
    }

    static async getDisputeTemplateList(payload: any) {
        return new PostDisputeTemplate().invokeList(payload)
    }

    static async getDisputeTemplateFullList(payload: any) {
        return new PostDisputeTemplate().invokeFullList(payload)
    }

    static async getDisputeTemplateReasonList(payload: any) {
        return new PostDisputeTemplate().invokeReasonList(payload)
    }

    static async getDisputeTemplateActionList(payload: any) {
        return new PostDisputeTemplate().invokeActionList(payload)
    }

    static async getDisputeTemplateStageList(payload: any) {
        return new PostDisputeTemplate().invokeStageList(payload)
    }

    static async getDisputeTemplateFindByType(payload: any) {
        return new PostDisputeTemplate().invokeFindByType(payload)
    }

    static async getDisputeTemplateFindByStore(payload: any) {
        return new PostDisputeTemplate().invokeFindByStore(payload)
    }

    static async postDisputeTemplateFilterTemplates(payload: any) {
        return new PostDisputeTemplate().invokeFilterTemplates(payload)
    }

    static async getDisputeTemplateView(payload: any) {
        return new PostDisputeTemplate().invokeView(payload)
    }

    static async getDisputeTemplatepreviewPdf(payload: any) {
        return new PostDisputeTemplate().invokeTemplatePreview(payload)
    }
    static async getDisputeTemplateFetchContent(payload: any) {
        return new PostDisputeTemplate().invokeTemplateFetchContent(payload)
    }
    //dispute-flow
    static async postDisputeFlow(payload: any) {
        return new PostDisputeFlow().invoke(payload)
    }
    static async postHandledByDisputeRequest(payload: any) {
        return new PostDisputeFlow().invokeHandleDispute(payload)
    }
    static async postDisputeFlowDupicateId(payload: any) {
        return new PostDisputeFlow().invokeDisputeFlowDuplicateId(payload)
    }

    static async getDisputeFlowList(payload: any) {
        return new PostDisputeFlow().invokeList(payload)
    }

    static async getDisputeFlowReasonList(payload: any) {
        return new PostDisputeFlow().invokeReasonList(payload)
    }

    static async getDisputeFlowActionList(payload: any) {
        return new PostDisputeFlow().invokeActionList(payload)
    }

    static async getDisputeFlowStageList(payload: any) {
        return new PostDisputeFlow().invokeStageList(payload)
    }

    static async getDisputeFlowFindByType(payload: any) {
        return new PostDisputeFlow().invokeFindByType(payload)
    }
    static async getDisputeFlowFindByStore(payload: any) {
        return new PostDisputeFlow().invokeFindByStore(payload)
    }

    static async postDisputeFlowFilterFlows(payload: any) {
        return new PostDisputeFlow().invokeFilterTemplates(payload)
    }

    static async getDisputeFlowView(payload: any) {
        return new PostDisputeFlow().invokeView(payload)
    }

    //dispute-response
    static async getDisputeResponseFindAll() {
        return new GetDisputeResponse().invoke()
    }

    static async getDisputeResponseFindByDisputeId(payload: any) {
        return new GetDisputeResponse().invokeFindByDisputeId(payload)
    }
    
    static async getDisputeFlowListByPSP(payload: any) {
        return new GetDisputeResponse().invokeDisputeFlowByPsp(payload)
    }
    
    static async getDisputeTemplateByFlowId(payload: any) {
        return new GetDisputeResponse().invokeDisputeTemplateByFlowId(payload)
    }


    static async postDisputeResponseCreate(payload: any) {
        return new GetDisputeResponse().invokeCreate(payload)
    }
    
    static async postCreateResponseByDisputeIdTemplaetId(payload: any) {
        return new GetDisputeResponse().invokeCreateResponseByDisputeIdTemplateId(payload)
    }


    static async getDisputeResponsePdf(payload: any) {
        return new GetDisputeResponse().invokePdf(payload)
    }
    static async getDisputeResponsePdfById(payload: any) {
        return new GetDisputeResponse().invokePdfByResponseId(payload)
    }
    static async getDisputeResponseStatusSubmitted(payload: any) {
        return new GetDisputeResponse().invokeStatusSubmitted(payload)
    }
    static async getDisputeUpdateStatusSubmitted(payload: any) {
        return new GetDisputeResponse().invokeResponseStatusSubmitted(payload)
    }
    static async getDisputeResponseStatusQueued(payload: any) {
        return new GetDisputeResponse().invokeStatusQueued(payload)
    }
    static async getDisputeResponseStatusApproved(payload: any) {
        return new GetDisputeResponse().invokeStatusApproved(payload)
    }
    static async getDisputeResponseFetchContent(payload: any) {
        return new GetDisputeResponse().invokeDisputeFetchContent(payload)
    }

    static async postDisputeResponseSaveContent(payload: any) {
        return new GetDisputeResponse().invokeDisputeSaveContent(payload)
    }
    static async postSavingMissingMacros(payload: any) {
        return new GetDisputeResponse().invokeSavingMissingMacros(payload)
    }
    static async getAllMacros(payload: any) {
        return new GetDisputeResponse().invokeGettingMacros(payload)
    }
    static async getMissingMacros(payload: any) {
        return new GetDisputeResponse().invokeGetMissingMacros(payload)
    }

    static async postDisputeResponseUpdateStatusApproved(payload: any) {
        return new GetDisputeResponse().invokeDisputeUpdateStatusApproved(payload)
    }

    static async postCreateResponse(payload: any) {
        return new GetDisputeResponse().invokeDisputeCreateResponse(payload)
    }
    static async deleteDisputeResponse(payload: any) {
        return new GetDisputeResponse().invokeDeleteDisputeCreateResponse(payload)
    }
    static async getManageDisputeFlow(payload: any) {
        return new GetDisputeResponse().invokeManageDisputeFlow(payload)
    }
    static async getFetchDisputeTemplate(payload: any) {
        return new GetDisputeResponse().invokeFetchDisputeTemplate(payload)
    }
    static async getFetchTemplateSummary(payload: any) {
        return new GetDisputeResponse().invokeFetchTemplateSummary(payload)
    }
    //stores-shop
    static async getShopAllShops(payload: any) {
        return new GetShopAllShops().invoke(payload)
    }

    static async getShopFindShop(payload: any) {
        return new GetShopAllShops().invokeShopFindShop(payload)
    }

    static async getShopFindActiveStores(payload: any) {
        return new GetShopAllShops().invokeShopFindActiveStores(payload)
    }

    static async getShopStoreReport(payload: any) {
        return new GetShopAllShops().invokeShopStoreReport(payload)
    }

    static async postShopFilterShops(payload: any) {
        return new GetShopAllShops().invokeShopFilterShops(payload)
    }

    static async postShopsSaveAddon(payload: any) {
        return new GetShopAllShops().invokeShopsSaveAddon(payload)
    }

    static async getShopFindShopAddon(payload: any) {
        return new GetShopAllShops().invokeShopFindShopAddon(payload)
    }
    static async getPreSignedUrl(payload: any) {
        return new GetShopAllShops().invokeGetPreSignedUrl(payload)
    }

    static async postShopSaveCompanyAddon(payload: any) {
        return new GetShopAllShops().invokeShopSaveCompanyAddon(payload)
    }
    static async updateShopCollaboratorAccess(payload: any) {
        return new GetShopAllShops().invokeUpdateShopCollaboratorAccess(payload)
    }

    static async postShopSaveCompanyPoliciesAddon(payload: any) {
        return new GetShopAllShops().invokeShopSaveCompanyPoliciesAddon(payload)
    }
    //manage-manager
    static async getAllManagers(payload: any) {
        return new GetAllListOfManagers().invoke(payload)
    }
    static async postUpdateManagerByShopId(payload: any) {
        return new GetAllListOfManagers().invokeUpdateManagerShopId(payload)
    }

    static async postUpdateManagerByDisputeId(payload: any) {
        return new GetAllListOfManagers().invokeUpdateManagerByDisputeId(payload)
    }

    static async getShopFindByManager(payload: any) {
        return new GetAllListOfManagers().invokeGetShopFindByManager(payload)
    }

    static async getDisputeFindByManager(payload: any) {
        return new GetAllListOfManagers().invokeGetDisputeFindByManager(payload)
    }


    static async getUserList() {
        return new GetAllListOfManagers().invokeGetUserList()
    }


    static async postManageCreateUser(payload: any) {
        return new GetAllListOfManagers().invokePostManageCreateUser(payload)
    }

    // klarna
    static async getKlarnaRequestedByDisputeId(payload: any) {
        return new GetKlarnaByDispute().invoke(payload)
    }

    // klarna
    static async PostBillingReports(payload: any) {
        return new PostBillingReportsList().invoke(payload)
    }
    // dispute approve
    static async PostDisputeApprove(payload: any) {
        return new PostBillingReportsList().invokeApproveDisputes(payload)
    }

    // confirm billing
    static async PostConfirmBilling(payload: any) {
        return new PostBillingReportsList().invokeConfirmBilling(payload)
    }
    // new billing list
    static async postNewBillingList(payload: any) {
        return new PostBillingReportsList().invokeNewBillingList(payload)
    }
    // paypal pull reports
    static async postPaypalPullReports(payload: any) {
        return new PostPaypalPullReport().invokePaypalPullReports(payload)
    }
    //new Billing list of ready Disputes
    static async getNewBillingListReadyDispute(payload: any) {
        return new PostBillingReportsList().invokeNewBillingReadyDisputeList(payload)
    }
    // total billed amount in billing report
    static async getTotalBilledAmountBillingReport(payload: any) {
        return new GetBillingReportList().invokeTotalBilledAmount(payload)
    }
    static async getTotalAmountList(payload: any) {
        return new GetBillingReportList().invokeTotalAmount(payload)
    }
    static async getNewBillingTotalBilledAmount(payload: any) {
        return new GetBillingReportList().invokeNewBillingTotalBilledAmount(payload)
    }
    static async getNewBillingTotalAmount(payload: any) {
        return new GetBillingReportList().invokeNewBillingTotalAmount(payload)
    }
    static async getReadyDisputeTotalBilledAmount(payload: any) {
        return new GetBillingReportList().invokeReadyDisputeTotalBilledAmount(payload)
    }
    static async getReadyDisputeTotalAmount(payload: any) {
        return new GetBillingReportList().invokeReadyDisputeTotalAmount(payload)
    }

    //Reporting
    static async getReportingList(payload: any) {
        return new GetReportingList().invoke(payload)
    }
    static async getByStoresReportingList(payload: any) {
        return new GetReportingList().invokeByStores(payload)
    }
    static async getByPspReportingList(payload: any) {
        return new GetReportingList().invokeByPsp(payload)
    }
    static async getByReasonsReportingList(payload: any) {
        return new GetReportingList().invokeByReasons(payload)
    }
    static async getByMonthlyReportingList(payload: any) {
        return new GetReportingList().invokeByMonthly(payload)
    }

    // under reivew disputes
    static async getUnderReviewDisputes(payload: any) {
        return new GetUnderReviewDisputes().invoke(payload)
    }

    static async getUnderReviewDisputesTotalBilledAmount(payload: any) {
        return new GetUnderReviewDisputes().invokeByTotalBilledAmount(payload)
    }

    static async getUnderReviewDisputesTotalAmount(payload: any) {
        return new GetUnderReviewDisputes().invokeByTotalAmount(payload)
    }

}