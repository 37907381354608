import React from 'react';
import styles from './style.module.scss';
import Loading from 'components/loading/loading';
import { svgIcons } from 'elements';
import { HtmlTooltip } from 'components/ui';

export type StoreReportTableType = {
  shopId?: number;
  shopName?: string;
  shopDomain?: any;
  companyName?: any;
  background?: any;
  refundPolicy?: string;
  termsAndConditions?: string;
  deliveryPolicy?: string;
  refundPolicyUrl?: string;
  termsAndConditionsUrl?: string;
  deliveryPolicyUrl?: string;
  cancellationTimeFrameImageUrl?: any;
  shippingPolicyImageUrl?: any;
  deliveryTimeFrame?: string;
  cancellationTimeFrame?: string;
  deliveryPolicyImageUrl?: string;
  refundPolicyImageUrl?: string;
  termsAndConditionsImageUrl?: string;
  sizingGuideUrl?: string;
  sizingGuideImageUrl?: any;
  contactPageUrl?: any;
  contactPageImageUrl?: any;
  accountForInstagram?: string;
  accountForFacebook?: string;
  accountForYoutube?: string;
  accountForPinterest?: string;
  accountForTiktok?: string;
  accountForLinkedIn?: string;
  accountForTwitter?: string;
};

type StoreReportListProps = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  tableHeading: string[];
  data: {
    count: number;
    storeReportList: StoreReportTableType[];
  };
};

const StoreReportList: React.FC<StoreReportListProps> = ({
  isLoading,
  isSuccess,
  isError,
  data,
  tableHeading,
}) => {
  return (
    <div className={styles.tableWrapper}>
      {isLoading && <Loading />}
      {(!isLoading && isError) || (isSuccess && !data?.count) ? (
        <div className={styles.NoData}>
          <img src={svgIcons.Coffee_Break} alt='disputes' />
          {isError && <h3>Oops! Something went wrong. Please try again.</h3>}
          <h3>No waiting seller response found</h3>
        </div>
      ) : (
        isSuccess &&
        data?.count > 0 && (
          <>
            <table>
              <thead>
                <tr>
                  {tableHeading.length > 0 &&
                    tableHeading.map((item: string) => (
                      <th key={item}>{item}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {data?.storeReportList.length > 0 &&
                  data?.storeReportList.map(
                    (store: StoreReportTableType, index: number) => (
                      <React.Fragment key={index}>
                        <tr className={styles.disputeWrapper}>
                          <td>
                            <p>{store.shopName}</p>
                          </td>
                          <td>
                            <p>{store.shopDomain}</p>
                          </td>
                          <td>
                            <p>{store.companyName}</p>
                          </td>
                          <td>
                            <p>{store.background}</p>
                          </td>
                          <td>
                            <p className={styles.reduceData}>{store.refundPolicy}</p>
                          </td>
                          <td>
                            <p className={styles.reduceData}>{store.termsAndConditions}</p>
                          </td>
                          <td>
                            <p className={styles.reduceData}>{store.deliveryPolicy}</p>
                          </td>
                          <td>
                            <p>{store.refundPolicyUrl}</p>
                          </td>
                          <td>
                            <p>{store.termsAndConditionsUrl}</p>
                          </td>
                          <td>
                            <p>{store.deliveryPolicyUrl}</p>
                          </td>
                          <td>
                            <p>{store.cancellationTimeFrameImageUrl}</p>
                          </td>
                          <td>
                            <p>{store.shippingPolicyImageUrl}</p>
                          </td>
                          <td>
                            <p className={styles.reduceData}>{store.deliveryTimeFrame}</p>
                          </td>
                          <td>
                            <p>{store.cancellationTimeFrame}</p>
                          </td>
                          <td>
                            <p>{store.deliveryPolicyImageUrl}</p>
                          </td>
                          <td>
                            <p>{store.refundPolicyImageUrl}</p>
                          </td>
                          <td>
                            <p>{store.termsAndConditionsImageUrl}</p>
                          </td>
                          <td>
                            <p>{store.sizingGuideUrl}</p>
                          </td>
                          <td>
                            <p>{store.sizingGuideImageUrl}</p>
                          </td>
                          <td>
                            <p>{store.contactPageUrl}</p>
                          </td>
                          <td>
                            <p>{store.contactPageImageUrl}</p>
                          </td>
                          <td>
                            <p>{store.accountForInstagram}</p>
                          </td>
                          <td>
                            <p>{store.accountForFacebook}</p>
                          </td>
                          <td>
                            <p>{store.accountForYoutube}</p>
                          </td>
                          <td>
                            <p>{store.accountForPinterest}</p>
                          </td>
                          <td>
                            <p>{store.accountForTiktok}</p>
                          </td>
                          <td>
                            <p>{store.accountForLinkedIn}</p>
                          </td>
                          <td>
                            <p>{store.accountForTwitter}</p>
                          </td>
                        </tr>
                      </React.Fragment>
                    )
                  )}
              </tbody>
            </table>
          </>
        )
      )}
    </div>
  );
};

export default StoreReportList;
