import { svgIcons } from 'elements';
import { Loading } from 'components';
import styles from './style.module.scss';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import Image from 'components/image';
import { Button } from '@mui/material';
import { getDisputeCurrency } from 'utils/disputeUtils';
import { usePdfDownloadResponse } from 'hooks';

export type BillingReportResponse = {
  billable?: number;
  billedDollarAmount?: number;
  billingStatus?: any;
  currencyCode?: string;
  dateOpened?: string;
  decisionDate?: string;
  disputeId?: string;
  disputeReason?: string;
  disputedAmount?: number;
  merchantName?: string;
  responseSubmittedAt?: any;
  status?: string;
  paymentProcessor?: any;
  orderNumber?: string;
  orderLink?: string;
  responseId?: number;
};

type ReusableTableProps = {
  usersColumns: string[];
  data: {
    count: number;
    billingReportResponses: BillingReportResponse[];
    disputeIds: null;
  };
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  selectedDisputes: any;
  handleCheckboxChange: (disputeId: any) => void;
  checkBoxVisible: boolean;
  isPdfButton?: boolean;
};

const ReusableTable = ({
  usersColumns,
  data,
  isLoading,
  isSuccess,
  isError,
  selectedDisputes,
  handleCheckboxChange,
  checkBoxVisible,
  isPdfButton,
}: ReusableTableProps) => {
  // function for download pdf from custom hook
  const {handleDownloadPdfResponse,loadingResponseId} = usePdfDownloadResponse()
  return (
    <div>
      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <Loading />
          </div>
        )}
        {!isLoading && isSuccess && data && data?.billingReportResponses?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                <th></th>
                {usersColumns.map(usersColumn => (
                  <th key={usersColumn}>{usersColumn}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.billingReportResponses.map(
                (user: BillingReportResponse, index: number) => (
                  <tr key={index} className={styles.tableDataRow}>
                    <td>
                      <div className={styles.checkedBox}>
                        {checkBoxVisible && (
                          <input
                            type='checkbox'
                            checked={selectedDisputes.includes(user?.disputeId)}
                            onChange={() =>
                              handleCheckboxChange(user?.disputeId)
                            }
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <Link
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                        to={`/dispute/${user?.disputeId}`}
                      >
                        {user?.paymentProcessor && (
                          <Image
                            icon={user?.paymentProcessor}
                            altText='payment processor'
                          />
                        )}
                        {user?.disputeId}
                      </Link>
                    </td>
                    <td>{user?.merchantName}</td>
                    <td>
                      <Link to={`${user?.orderLink}`} target='_blank'>
                        {user?.orderNumber}
                      </Link>
                    </td>
                    <td>
                      {getDisputeCurrency(user?.currencyCode as string)?.currencySign}
                      {user?.disputedAmount}
                    </td>
                    <td>
                      {getDisputeCurrency(user?.currencyCode as string)?.currencySign}
                      {user?.billable}
                    </td>
                    <td>{user?.disputeReason}</td>
                    <td>
                      {user?.dateOpened &&
                        dayjs(user.dateOpened).format('MMMM D, YYYY')}
                    </td>
                    <td>
                      {user?.decisionDate &&
                        dayjs(user.decisionDate).format('MMMM D, YYYY')}
                    </td>
                    <td>
                      {user?.responseSubmittedAt &&
                        dayjs(user?.responseSubmittedAt).format('MMMM D, YYYY')}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {isPdfButton && (
                        <Button
                          variant='contained'
                          size='small'
                          disabled={!user?.responseId}
                          onClick={() =>
                            handleDownloadPdfResponse(
                              user?.responseId as number,
                              user?.disputeId as string,
                              null
                            )
                          }
                        >
                          {loadingResponseId === user?.responseId
                            ? 'loading...'
                            : 'Pdf'}
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) ||
            (isSuccess && !data?.billingReportResponses?.length)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt='disputes' />
              <h3>No stores</h3>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ReusableTable;
