import { Loading } from "components";
import { svgIcons } from "elements";
import styles from "./style.module.scss";
import { EditIcon, ViewIcon } from "components/disputeFlow/NodeHelpers";
import { Button } from "components/ui";
import { Avatar, Tooltip } from "@mui/material";
import { DisputeTemplateData } from "hooks/disputeTemplateHooks/useDisputeTemplateList";
import dayjs from "dayjs"
interface TableData {
  data: DisputeTemplateData[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

const recentDisputesColumns = [
  "Template name",
  "Payment gateway",
  "Reason",
  "Created at",
  "Created by",
  "Last updated at",
  "Updated by",
  "Actions",
];

const TemplateTable = ({ isLoading, isSuccess, isError, data }: TableData) => {
  return (
    <>
      <div className={styles.tableWrapper}>
        {isLoading && <Loading />}
        {!isLoading && isSuccess && data && data?.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr>
                {recentDisputesColumns.map((recentDisputesColumn) => (
                  <th
                    key={recentDisputesColumn}
                    style={{
                      textAlign:
                        recentDisputesColumn === "Actions" ? "center" : "left",
                    }}
                  >
                    {recentDisputesColumn}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((template: any, index: number) => (
                <tr key={index} className={styles.tableDataRow}>
                  <td>{template?.name}</td>
                  <td>{template?.paymentGateway}</td>
                  <td>
                    <Tooltip arrow placement="top" title={template?.reason}>
                      {template?.reason}
                    </Tooltip>
                  </td>
                  <td>
                    {template?.createdOn
                      ? dayjs(template?.createdOn).format("MM/DD/YYYY HH:mm")
                      : null}
                  </td>
                  <td>
                    {template?.createdBy ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={template?.createdBy ?? "manager"}
                          arrow
                          placement="top"
                        >
                          <Avatar
                            className={styles.avatarStyle}
                            sx={{
                              width: 38,
                              height: 38,
                              padding: "13px",
                              bgcolor: "#DDDFE2",
                            }}
                          >
                            <span
                              style={{
                                color: "#1D2939",
                                fontSize: "14px",
                              }}
                            >
                              {template?.createdBy?.split("")[0]}
                            </span>
                            <span
                              style={{ color: "#1D2939", fontSize: "14px" }}
                            >
                              {template?.createdBy?.split("")[1]}
                            </span>
                          </Avatar>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    {" "}
                    {template?.lastUpdatedOn
                      ? dayjs(template?.lastUpdatedOn).format(
                          "MM/DD/YYYY HH:mm"
                        )
                      : null}
                  </td>
                  <td className={styles.type}>
                    {template?.lastUpdatedBy ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={template?.lastUpdatedBy ?? "manager"}
                          arrow
                          placement="top"
                        >
                          <Avatar
                            className={styles.avatarStyle}
                            sx={{
                              width: 38,
                              height: 38,
                              padding: "13px",
                              bgcolor: "#DDDFE2",
                            }}
                          >
                            <span
                              style={{
                                color: "#1D2939",
                                fontSize: "14px",
                                textTransform: "capitalize",
                              }}
                            >
                              {template?.lastUpdatedBy?.split("")[0]}
                            </span>
                            <span
                              style={{ color: "#1D2939", fontSize: "14px" }}
                            >
                              {template?.lastUpdatedBy?.split("")[1]}
                            </span>
                          </Avatar>
                        </Tooltip>
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <div className={styles.templateButtonWrapper}>
                      <Button
                        {...{
                          btnLabel: "",
                          btnTitle: "Click to view",
                          href: `/template-view/${template.id}`,
                          startIcon: <ViewIcon style={{ marginLeft: "5px" }} />,
                        }}
                      />
                      <Button
                        {...{
                          btnLabel: "",
                          btnTitle: "Click to edit",
                          href: `/template-edit/${template?.id}`,
                          startIcon: <EditIcon style={{ marginLeft: "5px" }} />,
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ((!isLoading && isError) ||
            (isSuccess && data?.length === 0) ||
            (!isLoading && isSuccess && !data)) && (
            <div className={styles.NoData}>
              <img src={svgIcons.Coffee_Break} alt="disputes" />
              <h3>No Template</h3>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default TemplateTable;
