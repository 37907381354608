import ApiService from "../../apiService";
import { apiConstants } from "../network/api-constants";
import { configs } from "../../../configs/index";
export class PostDisputeFlow {
  /**
   * Function to invoke to dispute-template
   */
  async invoke(payload: any) {
    const res = await ApiService.getInstance().post(
      apiConstants.POST_DISPUTE_FLOW,
      payload,
      { baseURL: configs.BASE_URL as string }
    );

    return res;
  }

  
  async invokeHandleDispute(payload: any) {
    const res = await ApiService.getInstance().post(
      apiConstants.POST_DISPUTE_HANDLE_SAVE,
      payload,
      { baseURL: configs.BASE_URL as string }
    );

    return res;
  }


  
  async invokeDisputeFlowDuplicateId(payload: any) {
    const res = await ApiService.getInstance().post(
      apiConstants.POST_DISPUTE_FLOW_DUPLICATE_ID(payload),
      payload,
      { baseURL: configs.BASE_URL as string }
    );

    return res;
  }


  async invokeList(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_LIST
    );

    return res;
  }

  async invokeReasonList(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_REASON_LIST(payload)
    );

    return res;
  }

  async invokeActionList(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_ACTION_LIST(payload)
    );

    return res;
  }

  async invokeStageList(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_STAGE_LIST(payload)
    );

    return res;
  }

  async invokeFindByType(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_FIND_BY_TYPE(payload)
    );

    return res;
  }
  async invokeFindByStore(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_FIND_BY_STORE(payload)
    );

    return res;
  }

  async invokeFilterTemplates(payload: any) {
    const res = await ApiService.getInstance().post(
      apiConstants.POST_DISPUTE_FLOW_FILTER_FLOWS,
      payload,
      { baseURL: configs.BASE_URL as string }
    );

    return res;
  }

  async invokeView(payload: any) {
    const res = await ApiService.getInstance().get(
      apiConstants.GET_DISPUTE_FLOW_VIEW(payload)
    );

    return res;
  }
}
