import styles from './style.module.scss';
import FlowTable from '../flowComponents/flowTable';
import FlowFilter from '../flowComponents/flowFilter';
import Loading from 'components/loading/loading';
import useSystemFlow, { DisputeFlowData } from 'hooks/flowHooks/useSystemFlow';
import { useEffect, useState } from 'react';

const SystemFlow = () => {
  const { isLoading, isSuccess, isError, data } = useSystemFlow();
  // code for implementation search
  const [searchItem, setSearchItem] = useState('');
  const [newFilterArray, setNewFilterArray] = useState(
    data?.data?.disputeFlowDTOList
  );

  const handleSearch = (searchQuery: string) => {
    setSearchItem(searchQuery);
  };
  useEffect(() => {
    const fetchFilteredData = () => {
      try {
        const response = data?.status;
        if (response === 200 && data.data.disputeFlowDTOList.length > 1) {
          const filteredArray =
            data?.data?.disputeFlowDTOList.length > 0
              ? data?.data?.disputeFlowDTOList.filter((data: DisputeFlowData) =>
                  data.name.toLowerCase().includes(searchItem.toLowerCase())
                )
              : [];
          setNewFilterArray(filteredArray);
        }
      } catch (error) {
        console.error('error', error);
      }
    };
    fetchFilteredData();
  }, [searchItem, data]);
  return (
    <>
      <div className={styles.disputesWrapper}>
        <div>
          <FlowFilter handleSearch={handleSearch} />
        </div>

        <div>
          {isLoading ? (
            <div style={{ background: '#fff' }}>
              <Loading />
            </div>
          ) : (
            <FlowTable
              data={data?.status === 200 ? newFilterArray : []}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
            />
          )}
        </div>

        {/* {Math.ceil(data?.count / tableList) > 1 && (
          <div>
            <Pagination
              onPageChange={(pageNum) => onPageChange(pageNum)}
              page={page}
              totalPages={Math.ceil(data?.count / tableList)}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default SystemFlow;
