import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getColorByStatus } from "utils/disputeUtils";
import styles from "./style.module.scss";
import dayjs from "dayjs"

interface DisputeOpenedByBuyerProps {
  disputeState: string;
}

const DisputeOpenedByBuyer = ({ disputeState }: DisputeOpenedByBuyerProps) => {
  const [disputeCheck, setDisputeCheck] = useState<boolean>(false);

  useEffect(() => {
    if (
      disputeState !== "RESOLVED" &&
      disputeState !== "UNDER_PAYPAL_REVIEW" &&
      disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&
      disputeState !== "response template" &&
      disputeState !== "dispute lost"
    ) {
      setDisputeCheck(true);
    } else setDisputeCheck(false);
  }, [disputeState]);

  const { data } = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  return (
    <div className={styles.disputeOpenedWrapper}>
      <h4>Dispute opened by buyer</h4>
      <div className={styles.disputeOpenedData}>
        <div>
          <h6>Status</h6>
          {data?.status !== 500 && (
            <p
              className={
                disputeCheck
                  ? styles.disputeAwaitingStatus
                  : styles.disputeSubmitStatus
              }
              style={
                disputeCheck
                  ? {
                      backgroundColor: getColorByStatus(data?.status)
                        ?.backgroundColor,
                      color: getColorByStatus(data?.status)?.textColor,
                    }
                  : { display: "block" }
              }
            >
              {disputeCheck
                ? data?.status?.split("_").join(" ")
                : "Response Submitted"}
            </p>
          )}
        </div>

        {disputeCheck && (
          <div>
            <h6>Due date for response</h6>
            <p className={styles.disputeDueDate}>
              {data?.sellerResponseDueDate &&
                dayjs(data?.sellerResponseDueDate).format("MMMM Do YYYY")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DisputeOpenedByBuyer;
