import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postDisputePaypalPullReportRequest } from 'redux/actions';
import { SingleAccountBreadcrumb } from 'components';
import styles from './style.module.scss';
import { BreadcumbItem } from 'components/ui';
import { getItem, setItem } from 'utils/localStorage';
import {
  useBreadCumbs,
  useDateFilter,
  useOpenDatePicker,
  useShopFilter,
  useStorelist,
} from 'hooks';
import Loading from 'components/loading/loading';
import PaypalReportList from 'components/disputeDashboard/disiputePaypalReports';
import { IStoreOptions } from 'hooks/billingReports/useNewBilling';
import ReactSelect from 'react-select';
import { CalendarIcon } from '@mui/x-date-pickers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

type TShop = {
  value: number;
  label: string;
};

const paypalReportsHeading = [
  'Dispute ID',
  'Store Name',
  'Date',
  'Amount',
  'Reason',
  'Response Due',
  'Decision Date',
  'Status',
  'Carrier',
  'Last Mile Carrier',
  'Response Status',
];

interface Props {
  shop?: TShop;
}

const PaypalPullReports = ({ shop }: Props) => {
  const dispatch = useDispatch();
  const SingleAccountCheck = getItem('singleAccountCheck');
  const { breadCumbs } = useBreadCumbs({ pageName: 'Paypal Reports' });
  const [page, setPage] = useState(1);
  const tableList = 15;
  const selectRef: any = useRef(null);
  // const [shopList, setShopList] = useState<IStoreOptions[]>([]);
  const [disputesPayload, setDisputesPayload] = useState<any>();
  const { storesOptions } = useStorelist();

  const { isLoading, isSuccess, isError, data } = useSelector(
    (state: any) => state?.paypalPullReports?.papalReportsList
  );

  // this is the custom hook for date filtering
  const {
    openDatePicker,
    datePickerRef,
    startDate,
    endDate,
    setDateRange,
    setDateFilterCheck,
  } = useDateFilter(disputesPayload, setDisputesPayload);

  /// this is the custom hook for store filtering
  const {shopList,setShopList} = useShopFilter(disputesPayload,setDisputesPayload,setPage)

  // const { openDatePicker, datePickerRef } = useOpenDatePicker();
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  // const [dateFilterCheck, setDateFilterCheck] = useState(false);

  // useEffect(() => {
  //   setPage(1);
  //   setItem("disputePage", 1);

  //   if (dateFilterCheck && startDate && endDate) {
  //     const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
  //     const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");
  //     let datePayload = {
  //       ...disputesPayload,
  //       page: 1,
  //       createdDateFrom: formattedStartDate,
  //       createdDateTo: formattedEndDate,
  //     };
  //     setDisputesPayload(datePayload);
  //   } else if (dateFilterCheck && !startDate && !endDate) {
  //     let datePayload = {
  //       ...disputesPayload,
  //       page: 1,
  //       createdDateFrom: null,
  //       createdDateTo: null,
  //     };
  //     setDisputesPayload(datePayload);
  //   }
  // }, [dateFilterCheck, startDate, endDate]);

  // useEffect(() => {
  //   setPage(1)
  //   const shopIds =
  //     shopList && shopList.length > 0 ? shopList.map(item => item.value) : [];
  //   const payload = {
  //     ...disputesPayload,
  //     shopIds,
  //   };
  //   setDisputesPayload(payload);
  // }, [shopList]);
  useEffect(() => {
    const payload = {
      ...disputesPayload,
      page,
      pageLength: 15,
    };
    dispatch(postDisputePaypalPullReportRequest(payload));
  }, [page, disputesPayload]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className={styles.disputesWrapper}>
      {SingleAccountCheck === 'true' ? (
        <SingleAccountBreadcrumb />
      ) : (
        <>
          <div className={styles.nav}>
            <h3>Paypal Pull Reports</h3>
            <div className={styles.navPath}>
              {breadCumbs?.map((item, key) => (
                <BreadcumbItem key={key} {...item} />
              ))}
            </div>
          </div>

          <div className={styles.search}>
            <div onClick={openDatePicker} className={styles.date}>
              <CalendarIcon className={styles.icon} />
              <DatePicker
                dateFormat='dd/MM/yyyy'
                selectsRange={true}
                placeholderText='Decision Date...'
                ref={datePickerRef}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update);
                  setDateFilterCheck(true);
                }}
                className={styles.datePicker}
                monthsShown={2}
                isClearable
              />
            </div>
            <div className={styles.searchTop}>
              <div className={styles.searchDiv}>
                <ReactSelect
                  ref={selectRef}
                  className={styles.multipleSelect}
                  styles={{
                    control: baseStyles => ({
                      ...baseStyles,
                      minHeight: '44px',
                    }),
                  }}
                  closeMenuOnSelect={false}
                  placeholder={'Select stores...'}
                  options={storesOptions}
                  isMulti
                  value={shopList}
                  onChange={(e: any) => setShopList(e)}
                />
              </div>
            </div>
          </div>

          <div>
            {isLoading ? (
              <div style={{ background: '#fff', borderRadius: '8px' }}>
                <Loading />
              </div>
            ) : (
              <PaypalReportList
                isLoading={isLoading}
                isSuccess={isSuccess}
                isError={isError}
                data={data}
                tableHeadings={paypalReportsHeading}
                onPageChange={onPageChange}
                tableList={tableList}
                page={page}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PaypalPullReports;
