import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { Api } from "../../services/accountApi/network";

interface FindAllRes {
       data: {
        "id": number,
        "disputeId": string,
        "disputeTemplateId": number,
        "fields": any,
        "missingFields": string,
        "products": any,
        "submittedAt": string,
        "status": string
    },
    status:number
}

interface GetDisputeResponsePdfByIdAction {
    type: string; // You might want to specify the exact action type if it's consistent across your app
    payload: any; // Adjust the type of payload as per your actual payload type
  }

type PdfResponseType = {
    status:number;
    data:Blob;

}


export function* getDisputeResponseFindAllRequest() {
    try {
        const res: FindAllRes = yield Api.getDisputeResponseFindAll();
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponseFindAllError());
        }else {
            yield put(actions.getDisputeResponseFindAllSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponseFindAllError());
    }
}

export function* getDisputeResponseFindByDisputeIdRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeResponseFindByDisputeId(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponseFindByDisputeIdError());
        }else {
            yield put(actions.getDisputeResponseFindByDisputeIdSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponseFindByDisputeIdError());
    }
}

export function* getDisputeFlowListByPspRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeFlowListByPSP(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeFlowListByPspError());
        }else {
            yield put(actions.getDisputeFlowListByPspSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeFlowListByPspError());
    }
}

export function* getDisputeTemplateByFlowIdRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeTemplateByFlowId(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeTemplateByFlowIdError());
        }else {
            yield put(actions.getDisputeTemplateByFlowIdSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeTemplateByFlowIdError());
    }
}

export function* postDisputeResponseCreateRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.postDisputeResponseCreate(action.payload);
        yield put(actions.postDisputeResponseCreateSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeResponseCreateError());
    }
}
export function* postCreateResponseByDisputeIdTemplateId(action: any) {
    try {
        const res: FindAllRes = yield Api.postCreateResponseByDisputeIdTemplaetId(action.payload);
        yield put(actions.postCreateResponseDisputeIdTemplateIdSuccess(res.data));
    } catch (e) {
        yield put(actions.postCreateResponseDisputeIdTemplateIdError());
    }
}

export function* getDisputeResponsePdfRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeResponsePdf(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponsePdfError());
        }else {
            yield put(actions.getDisputeResponsePdfSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponsePdfError());
    }
}

export function* getDisputeResponsePdfByIdRequest(action: any) {
    try {
        const res: PdfResponseType = yield Api.getDisputeResponsePdfById(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponsePdfByIdError());
        }else {
            yield put(actions.getDisputeResponsePdfByIdSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponsePdfByIdError());
    }
}

export function* getDisputeResponseStatusSubmittedRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeResponseStatusSubmitted(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponseStatusSubmittedError());
        }else {
            yield put(actions.getDisputeResponseStatusSubmittedSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponseStatusSubmittedError());
    }
}
export function* getDisputeUpdateStatusSubmittedRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeUpdateStatusSubmitted(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeUpdateStatusSubmittedError());
        }else {
            yield put(actions.getDisputeUpdateStatusSubmittedSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeUpdateStatusSubmittedError());
    }
}
export function* getDisputeResponseStatusQueuedRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeResponseStatusQueued(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponseStatusQueuedError());
        }else {
            yield put(actions.getDisputeResponseStatusQueuedSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponseStatusQueuedError());
    }
}

export function* getDisputeResponseStatusApprovedRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeResponseStatusApproved(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponseStatusApprovedError());
        }else {
            yield put(actions.getDisputeTemplateFetchContentSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponseStatusApprovedError());
    }
}

export function* getDisputeResponseFetchContentRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getDisputeResponseFetchContent(action.payload);
        if (res.status === (500 || 401)){
            yield put(actions.getDisputeResponseFetchContentError());
        }else {
            yield put(actions.getDisputeResponseFetchContentSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getDisputeResponseFetchContentError());
    }
}

export function* postDisputeResponseSaveContentRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.postDisputeResponseSaveContent(action.payload);
        yield put(actions.postDisputeResponseSaveContentSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeResponseSaveContentError());
    }
}
export function* PostSavingMissingMacrosRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.postSavingMissingMacros(action.payload);
        yield put(actions.PostSavingMissingMacrosSuccess(res.data));
    } catch (e) {
        yield put(actions.PostSavingMissingMacrosError());
    }
}
export function* getAllMacrosRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getAllMacros(action.payload);
        if(res.status === 500){
            yield put(actions.getAllMacrosError());
        }else{
            yield put(actions.getAllMacrosSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getAllMacrosError());
    }
}
export function* getMissingMacrosRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.getMissingMacros(action.payload);
        if(res.status === 500){
            yield put(actions.getMissingMacrosError());
        }else{
            yield put(actions.getMissingMacrosSuccess(res.data));
        }
    } catch (e) {
        yield put(actions.getMissingMacrosError());
    }
}

export function* postDisputeResponseUpdateStatusApprovedRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.postDisputeResponseUpdateStatusApproved(action.payload);
        yield put(actions.postDisputeResponseUpdateStatusApprovedSuccess(res.data));
    } catch (e) {
        yield put(actions.postDisputeResponseUpdateStatusApprovedError());
    }
}
export function* postCreateResponseRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.postCreateResponse(action.payload);
        yield put(actions.postCreateResponseSuccess(res.data));
    } catch (e) {
        yield put(actions.postCreateResponseError());
    }
}
export function* deleteDisputeResponseRequest(action: any) {
    try {
        const res: FindAllRes = yield Api.deleteDisputeResponse(action.payload);
        yield put(actions.deleteDisputeResponseSuccess(res.data));
    } catch (e) {
        yield put(actions.deleteDisputeResponseError());
    }
}