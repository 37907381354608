import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  getDisputeDetailsRequest,
  getDisputeAdjudicationDetailsRequest,
  getDisputeMoneyMovementDetailsRequest,
  getDisputeSupportingInfoDetailsRequest,
  getDisputeOfferDetailsRequest,
  getDisputeResponseJsonByIdRequest,
} from "redux/actions";
import DisputeAccordion from "./disputeAccordion";
import DisputeSummary from "./disputeSummary";
import DisputeStages from "./disputeStages";
import DisputeCurrent from "./disputeCurrent";
// import DisputeResolver from "./disputeResolver";
import ShippingJourney from "./shippingJourney";
import DisputeUnderReview from "./disputeUnderReview";
import DisputeResponseTemplate from "./disputeResponseTemplate";
import DisputeWon from "./disputeWon";
import DisputeLost from "./disputeLost";
import { getColorByStatus } from "utils/disputeUtils";
import { svgIcons } from "elements";
import styles from "./style.module.scss";
import ManageDispute from "./manageDispute";
import { useManagerList } from "hooks";
// import SelectUpdateManager from "components/storesView/selectUpdateManager";
import { Button } from "components/ui";
import DisputeResponsePreview from "./disputeResponsePreview";
import DisputeResponseSummary from "./DisputeResponseSummary";
import MissingMacros from "./MissingMacros";
import { Box, Modal, Typography } from "@mui/material";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  height: "800px",
  borderRadius: 6,
};

const DisputeDetail = () => {
  const dispatch = useDispatch();
  const { disputeId } = useParams();
  const { manageManager } = useManagerList();
  const [disputeState, setDisputeState] = useState("");
  const [clickedTab, setClickedTab] = useState<string>("disputeResponse");
  const [disputeOutcome, setDisputeOutcome] = useState("");
  const [disputePaymentGateway, setDisputePaymentGateway] = useState("");
  const [disputeResponsePreview, setDisputeResponsePreview] = useState<any>({});
  const disputeDetails = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    isSuccess,
    data: responseJson,
    isLoading,
    isError,
  } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeResponseJsonDetails
  );
  const handleResponseJson = () => {
    dispatch(getDisputeResponseJsonByIdRequest(disputeId));
    handleOpen()
  };
  useEffect(() => {
    if (disputeId) {
      const payload = disputeId;
      dispatch(getDisputeDetailsRequest(payload));
      dispatch(getDisputeAdjudicationDetailsRequest(payload));
      dispatch(getDisputeMoneyMovementDetailsRequest(payload));
      dispatch(getDisputeSupportingInfoDetailsRequest(payload));
      dispatch(getDisputeOfferDetailsRequest(payload));
    }
  }, [disputeId, dispatch]);

  useEffect(() => {
    if (disputeDetails?.isSuccess && disputeDetails?.data) {
      setDisputeState(disputeDetails.data.disputeState);
      setDisputeOutcome(disputeDetails.data.disputeOutcome);
      setDisputePaymentGateway(disputeDetails.data?.paymentProcessor);
    }
  }, [disputeDetails]);

  const copyToClipboard = () => {
    const jsonString = JSON.stringify(responseJson?.data, null, 2);
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        alert("JSON copied to clipboard!");
      })
      .catch((err) => {
        alert("Failed to copy JSON to clipboard");
        console.error("Copying failed", err);
      });
  };

  const defaultValue =
    manageManager?.data &&
    manageManager?.data?.length > 0 &&
    manageManager?.data.find(
      (item: any) => item?.userName === disputeDetails?.data?.manager
    );
  const [selectedValue, setSelectedValue] = useState<any>({});
  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue(null);
    }
  }, [defaultValue]);
  return (
    <div className={styles.DisputeDetailsWrapper}>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              JSON Response
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                mt: 2,
                overflowY: "auto",
                height: "600px",
                background: "#ededed",
                padding: "5px",
              }}
            >
              <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                {JSON.stringify(responseJson?.data, null, 2)}
              </pre>
            </Typography>
            <Box sx={{ marginTop: "30px" }}>
              <Button
                {...{
                  btnTitle: "Copy Json",
                  btnLabel: "Copy Json",
                  className: styles.selectButtonStyle,
                  onClick: () => copyToClipboard(),
                }}
              />
            </Box>
          </Box>
        </Modal>
      </div>
      <div className={styles.DisputeDetailsBody}>
        <div>
          <div className={styles.disputeIdWrapper}>
            <Link to={`/store-details/${disputeDetails?.data?.shopId}`}>
              <h3
                style={{
                  color: disputeDetails?.data?.chargeActive
                    ? "#19801b"
                    : "#B54708",
                  marginRight: "10px",
                }}
              >
                {disputeDetails?.data?.shopName}
              </h3>
            </Link>
            <h3>
              {disputeDetails?.data?.id
                ? `Dispute ${disputeDetails?.data?.id}`
                : "Dispute"}
            </h3>
            {disputeDetails?.data?.status !== 500 && (
              <p
                style={{
                  backgroundColor: getColorByStatus(
                    disputeDetails?.data?.status
                  )?.backgroundColor,
                  color: getColorByStatus(disputeDetails?.data?.status)
                    ?.textColor,
                }}
              >
                {disputeDetails?.data?.status?.split("_").join(" ")}
              </p>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p className={styles.disputeIdTimeline}>
              This is the timeline of your dispute with responses and updates
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* <SelectUpdateManager
                {...{
                  selectedValue,
                  setSelectedValue,
                  defaultValue,
                  dispute: true,
                  shopFindShop: disputeDetails?.data,
                  toUsers: manageManager?.data,
                }}
              /> */}
              <Button
                {...{
                  btnTitle: "Response Json",
                  btnLabel: "Response Json",
                  className: styles.selectButtonStyle,
                  onClick: () => handleResponseJson(),
                }}
              />
              <Button
                {...{
                  btnTitle: "Mark as completed",
                  btnLabel: "Mark as completed",
                  className: styles.selectButtonStyle,
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.cardsWrapper}>
          <div className={styles.main}>
            {/* main section: with 3 stages of dispute */}
            <DisputeStages
              disputeState={disputeState}
              disputeOutcome={disputeOutcome}
            />

            {disputeState !== "RESOLVED" &&
              disputeState !== "UNDER_PAYPAL_REVIEW" &&
              disputeState !== "REQUIRED_OTHER_PARTY_ACTION" &&
              disputeState !== "response template" &&
              disputeState !== "dispute lost" && (
                <>
                  <div className={styles.dispueByBuyer}>
                    {/* main section: with dispute stage, reason, due date to submit and messages */}
                    <DisputeCurrent />
                  </div>
                  <div className={styles.disputeResolver}>
                    {/* main section: with AI Dispute Resolver */}
                    {/* <DisputeResolver setDisputeState={setDisputeState} /> */}

                    <ManageDispute
                      {...{
                        disputeResponsePreview,
                        setDisputeResponsePreview,
                        clickedTab,
                        setClickedTab,
                      }}
                    />
                  </div>
                </>
              )}
            {disputeResponsePreview?.id && clickedTab === "disputeResponse" && (
              <div className={styles.disputeResponsePreview}>
                <MissingMacros id={disputeResponsePreview?.id} disputeId={disputeId} />
              </div>
            )}
            {disputeResponsePreview?.id && clickedTab === "disputeResponse" && (
              <div className={styles.disputeResponsePreview}>
                <DisputeResponsePreview
                  {...{
                    disputeResponsePreview,
                    isSubmitted: disputeDetails?.data?.disputeResponseSubmitted,
                  }}
                />
              </div>
            )}
            {disputeResponsePreview?.id && clickedTab === "disputeResponse" && (
              <div className={styles.disputeResponsePreview}>
                <DisputeResponseSummary
                  {...{
                    disputeResponseSummary: disputeResponsePreview,
                    isSubmitted: disputeDetails?.data?.disputeResponseSubmitted,
                  }}
                />
              </div>
            )}
            <ShippingJourney disputeResponsePreview="" />
            {(disputeState === "UNDER_PAYPAL_REVIEW" ||
              disputeState === "REQUIRED_OTHER_PARTY_ACTION") && (
              <div className={styles.disputeReviewWrapper}>
                <DisputeUnderReview setDisputeState={setDisputeState} />
              </div>
            )}

            {disputeState === "response template" && (
              <>
                <div className={styles.dispueByBuyer}>
                  <DisputeCurrent />
                </div>
                <div className={styles.disputeResolver}>
                  <DisputeResponseTemplate setDisputeState={setDisputeState} />
                </div>
              </>
            )}

            {disputeState === "RESOLVED" &&
              disputeOutcome === "RESOLVED_SELLER_FAVOUR" && (
                <div className={styles.disputeReviewWrapper}>
                  <DisputeWon />
                </div>
              )}

            {disputeState === "RESOLVED" &&
              disputeOutcome === "RESOLVED_BUYER_FAVOUR" && (
                <div className={styles.disputeReviewWrapper}>
                  <DisputeLost />
                </div>
              )}
          </div>

          <div className={styles.sideMain}>
            <div className={styles.disputeSummaryWrapper}>
              <div className={styles.disputeSummaryHead}>
                <p>Dispute Summary</p>
                {disputePaymentGateway &&
                  disputePaymentGateway?.toLowerCase() === "paypal" && (
                    <img src={svgIcons.PayPal_Icon_Text} alt="PayPal" />
                  )}
                {disputePaymentGateway &&
                  disputePaymentGateway?.toLowerCase() === "klarna" && (
                    <img src={svgIcons.Klarna} alt="Klarna" />
                  )}
                {disputePaymentGateway &&
                  disputePaymentGateway?.toLowerCase() === "stripe" && (
                    <img src={svgIcons.Stripe_Icon_Text} alt="Stripe" />
                  )}
                {disputePaymentGateway &&
                  disputePaymentGateway?.toLowerCase() === "braintree" && (
                    <div className={styles.summaryLogo}>
                      <img src={svgIcons.BrainTree} alt="BrainTree" />
                      <p>Braintree</p>
                    </div>
                  )}
                {disputePaymentGateway &&
                  disputePaymentGateway?.toLowerCase() ===
                    "shopifypayments" && (
                    <div className={styles.summaryLogo}>
                      <img src={svgIcons.Lachiepor} alt="ShopifyPayments" />
                      <p>Shopify Payments</p>
                    </div>
                  )}
              </div>
              <div className={styles.disputeSummary}>
                <DisputeSummary disputeState={disputeState} />
              </div>
            </div>
            <div className={styles.disputeFeatures}>
              <DisputeAccordion />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeDetail;
