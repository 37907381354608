import { Api } from "services";
import * as actions from "../actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

interface disputeBilling {
  data: {};
  status: number;
}

interface ResProp {
  data: {
      count: number,
      disputeResponseList: [],
  },
  status: number
}

export function* postDisputePaypalPullReportRequest(action: any) {
  try {
    const res: disputeBilling = yield Api.postPaypalPullReports(action.payload);
    if (res.status === 500) {
      yield put(actions.postDisputePaypalPullReportError())
    } else {
      yield put(actions.postDisputePaypalPullReportSuccess(res.data))
    }
  } catch (e) {
    yield put(actions.postDisputePaypalPullReportError())
  }
}
