import { useJiraComments } from "hooks";
import styles from "./style.module.scss";
import Loading from "components/loading/loading";
import { isEmpty } from "lodash";

const JiraComments = () => {
  const { data, isLoading } = useJiraComments();
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!isEmpty(data?.comments) ? (
            <div className={styles.jiraCommentsWrapper}>
              {
                data?.comments.map((item: any, index: any) =>
                  <div key={index} style={{ marginBottom: "12px", boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)", padding: "12px", borderRadius: "12px" }}>
                    <div className={styles.orderFulFillmentContainer}>
                      <p className={styles.orderFulfillmentTitle}>Author Name</p>
                      <p className={styles.orderFulfillmentDetails}>
                        {item?.authorName}
                      </p>
                    </div>
                    <div className={styles.orderFulFillmentContainer}>
                      <p className={styles.orderFulfillmentTitle}>Comment Text</p>
                      <p className={styles.orderFulfillmentDetails}>
                        {item?.commentText}
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          ) : (
            <div className={styles.nojiraComments}>
              No Jira comments found
            </div>
          )}
        </>
      )}
    </>
  );
};

export default JiraComments;
