import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  getDisputeTemplateReasonListRequest,
  getShopAllShopsRequest,
  postDisputeBillingRequest,
} from "redux/actions";
import {
  DisputeFormData,
  selectDropDownOption,
} from "components/disputeFlow/types";
import {
  disputeStatus,
} from "components/disputeFlow/NodeHelpers/Utils";
// TODO- Remove unnecessay api's from this hook by checking network
const useBillingReports = () => {
  const { disputeFlowView, globalFlowState }: any = useSelector(
    (state: any) => state?.disputeFlow
  );

  const {
    data: billingReport,
    isLoading: isReportLoading,
    isError: isReportError,
    isSuccess: isReportSuccess,
  } = useSelector((state: any) => state?.billing?.disputeBilling);
  interface disputeBillingFormProps {
    store: string;
    status: string;
    disputeReason: string;
    decisionDate: string;
  }
  const dispatch = useDispatch();
  const initialDefault: disputeBillingFormProps = {
    store: "",
    status: "",
    disputeReason: "",
    decisionDate: "",
  };
  const [defaultValues, setdefaultValues] = useState(initialDefault);
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch,
    formState: { errors },
  }: any = useForm<disputeBillingFormProps>({
    defaultValues,
  });
  const [storesOptions, setStoresOptions] = useState<selectDropDownOption[]>(
    []
  );
  const [reasons, setReasons] = useState<selectDropDownOption[]>();
  const [submitted, setSubmitted] = useState<DisputeFormData | null>();
  const { isLoading, isSuccess, isError } = useSelector(
    (state: any) => state?.disputeFlow?.disputeFlow
  );
  const disputeTemplateReasonList = useSelector(
    (state: any) => state?.disputeTemplate?.disputeTemplateReasonList
  );
  const shopAllShops = useSelector(
    (state: any) => state.storesShop.shopAllShops
  );

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  //Get the implementation of initial State
  const [page, setPage] = useState(1);
  const pageLength = 15;

  const onPageChange = (page:number)=>{
    setPage(page)
  }

  useEffect(() => {
    const payload = {
      pagination: {
        page,
        pageLength,
      },
      submitForm: {},
    };
    dispatch(postDisputeBillingRequest(payload));
  }, [page]);

  useEffect(() => {
    const payload = {
      enabled: true,
    };
    dispatch(getDisputeTemplateReasonListRequest(payload));
    dispatch(getShopAllShopsRequest(payload));
  }, [dispatch]);

  useEffect(() => {
    if (
      disputeTemplateReasonList?.isSuccess &&
      disputeTemplateReasonList?.data?.length > 0
    ) {
      const response = disputeTemplateReasonList?.data?.map((reason: any) => {
        let reasonObj = {
          value: reason?.disputeReasonValue,
          label: reason?.description,
        };
        return reasonObj;
      });
      setReasons(response);
    }
    if (shopAllShops?.isSuccess && shopAllShops?.data?.count > 0) {
      let storeOption = shopAllShops?.data?.shopResponseList?.map((store: any) => {
        let storeObj = {
          value: store?.id,
          label: store?.name,
        };
        return storeObj;
      });
      setStoresOptions(storeOption);
    }
  }, [disputeTemplateReasonList, shopAllShops]);

  const mapedForm = [
    {
      label: "Store",
      name: "store",
      rules: {},
      placeholder: "Select store",
      options: storesOptions,
    },
    {
      label: "Status",
      name: "status",
      placeholder: "Select status",
      rules: {},
      options: disputeStatus,
    },
    {
      placeholder: "Decision range",
      label: "Decision Date",
      name: "decisionDate",
      type: "date",
      rules: {},
      options: "",
    },
    {
      placeholder: "Dispute reason",
      label: "Dispute Reason",
      name: "disputeReason",
      rules: {},
      options: reasons,
    },
  ];

  const formFunction = {
    control,
    errors,
    register,
    handleSubmit,
    clearErrors,
    setValue,
    reset,
    watch
  };
  return {
    storesOptions,
    isLoading,
    isSuccess,
    isError,
    submitted,
    mapedForm,
    formFunction,
    disputeFlowView,
    reasons,
    globalFlowState,
    billingReport,
    isReportLoading,
    isReportError,
    page,
    isReportSuccess,
    pageLength,
    setSubmitted,
    setPage,
    onPageChange
  };
};
export default useBillingReports;
