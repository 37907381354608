import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Loading from '../../../loading/loading';
import { Button } from 'components/ui';
import styles from './style.module.scss';
import { Textarea } from 'components/ui';
import BasicSelect from 'components/ui/Select/Index';
import {
  useResizeObserver,
  useFetchDisputeflow,
  useCreateResponse,
  useDisputeList,
  useDisputeResponseList,
} from 'hooks';
import { ErrorMessage } from 'components/disputeFlow/NodeHelpers';
import { Box, Tooltip } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
import { svgIcons } from 'elements';
import _, { isEmpty } from 'lodash';
import {
  getDisputeResponseFindByDisputeIdRequest,
  getDisputeTemplateViewRequest,
  getFetchDisputeTemplateRequest,
  getFetchTemplateSummaryRequest,
  postCreateResponseDisputeIdTemplateIdRequest,
} from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import DisputeTemplatePreview from 'components/disputeDetails/disputeTemplatePreview';

type FlowIdDataTypes = { id: number; name: string };
type TemplateIdDataTypes = { id: number; name: string };

const fileTypes = ['PNG', 'JPG', 'JPEG', 'PDF'];
const CreateResponse = ({ paymentProcessor }: any) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const disputeDetails = useSelector(
    (state: any) => state?.disputeDetailsStore?.disputeDetails
  );

  const dispatch = useDispatch();
  const { disputeId } = useParams();
  const [uploadFile, setUploadFile] = useState<any>([]);
  // this is the handleCreate handlder
  const { handleUpdateCreateResponse } = useCreateResponse();
  useResizeObserver();

  const [newFlowIdData, setNewFlowIdData] = useState<FlowIdDataTypes[]>([]);
  const [newTemplateData, setNewTemplateData] = useState<TemplateIdDataTypes[]>(
    []
  );

  const { data: templateIds, isSuccess } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFetchTemplate
  );
  const { data: basedOnTemplateSummary } = useSelector(
    (state: any) => state?.disputeDetailsStore?.manageDisputeFetchSummary
  );

  // this is the disputeResponse data
  const { data: disputeResponseData } = useDisputeResponseList(disputeId);

  // this is the success state if handleCreateResponse post request is successfull
  const { isSuccess: isHandleCreateResponseSuccess } = useSelector(
    (state: any) => state?.disputeResponse?.createDisputeResponse
  );

  // if handleCreateReponse post reqeust is successfull then refetch the disputeResponseList by dispute id for automatic update in the dispute response tab table data
  useEffect(() => {
    if (isHandleCreateResponseSuccess) {
      dispatch(getDisputeResponseFindByDisputeIdRequest(disputeId));
    }
  }, [dispatch]);

  // this is the data which is initially rendered to the flow id select options
  const { fetchedFlow, isFlowLoading, isFlowSuccess } = useFetchDisputeflow({
    disputeId,
    paymentProcessor,
  });

  // this is the handler for file upload
  const handleFileUpload = (doc: any) => {
    setUploadFile(doc[0]);
  };
  useEffect(() => {
    if (uploadFile?.name) {
      clearErrors('file');
    }
  }, [uploadFile?.name]);

  const template = watch('templateId'); // this is from select component
  const flowId = watch('flowId'); // this is from select component
  const templateId = disputeResponseData[0]?.disputeTemplateId || template; // this is from dispute Response for fetching template summary

  //  this disputeFlowListPsp and disputeFlowListTempalteFlowId options are to be used when dispute dispute repsonse is empty
  const { disputeFlowListPsp, disputeFlowListTemplateFlowId } = useDisputeList(
    paymentProcessor,
    flowId
  );

  useEffect(() => {
    // for flow id
    if (isFlowSuccess && fetchedFlow && disputeResponseData?.length > 0) {
      const flowIdOptions: FlowIdDataTypes[] =
        fetchedFlow &&
        fetchedFlow?.length > 0 &&
        fetchedFlow?.map((flow: FlowIdDataTypes) => {
          return { id: flow?.id, name: flow?.name };
        });
      // Check if {flowId: 0, name:"PLACEHOLDER"} is present in flowIdOptions
      const placeholderExists =
        flowIdOptions.length > 0 &&
        flowIdOptions?.some(
          option => option.id === 0 && option.name === 'PLACEHOLDER'
        );
      // If not present, append the placeholder object to the array
      if (!placeholderExists) {
        flowIdOptions.push({ id: 0, name: 'PLACEHOLDER' });
      }
      setNewFlowIdData(flowIdOptions);
    } else {
      const newFlowIdOptions: FlowIdDataTypes[] = disputeFlowListPsp?.data?.map(
        (flow: FlowIdDataTypes) => {
          return { id: flow?.id, name: flow?.name };
        }
      );
      setNewFlowIdData(newFlowIdOptions);
    }
  }, [fetchedFlow, isFlowSuccess, disputeFlowListPsp]);

  //changing the flow Id
  useEffect(() => {
    if (flowId === 0 || flowId) {
      dispatch(getFetchDisputeTemplateRequest({ flowId, disputeId }));
    }
  }, [flowId]);

  useEffect(() => {
    if (template === 0 || template) {
      dispatch(
        getFetchTemplateSummaryRequest({ templateId: template, disputeId })
      );
      dispatch(getDisputeTemplateViewRequest(templateId));
    }
  }, [template]);

  useEffect(() => {
    if (isSuccess && templateIds && disputeResponseData?.length > 0) {
      // Create a deep copy of templateIds
      const clonedTemplateId = _.cloneDeep(templateIds);
      // Check if the placeholder exists
      const placeholderExists = clonedTemplateId?.some(
        (option: any) => option?.id === 0 && option?.name === 'PLACEHOLDER'
      );
      // If not present, add the placeholder
      if (!placeholderExists) {
        clonedTemplateId.push({ id: 0, name: 'PLACEHOLDER' });
      }
      // Update the state with the new template data
      setNewTemplateData(clonedTemplateId);
    } else {
      const newFlowTemplateOptions =
        disputeFlowListTemplateFlowId?.data?.length > 0
          ? disputeFlowListTemplateFlowId?.data?.map((template: any) => {
              return {
                id: template?.templateId,
                name: template?.templateName,
              };
            })
          : [];
      setNewTemplateData(newFlowTemplateOptions);
    }
  }, [isSuccess, templateIds, disputeFlowListTemplateFlowId]);

  useEffect(() => {
    if (template && !isEmpty(basedOnTemplateSummary)) {
      setValue('summary', basedOnTemplateSummary ?? '');
    }
  }, [template, basedOnTemplateSummary]);

  useEffect(() => {
    if (!isEmpty(newFlowIdData) && !isEmpty(newTemplateData)) {
      const currentFlowValue = watch('flowId');
      const currentTemplateValue = watch('templateId');

      if (!currentFlowValue && !currentTemplateValue) {
        const findFlow = newFlowIdData?.find(item => Number(item?.id) === 0);
        const findTemplate = newTemplateData?.find(
          item => Number(item?.id) === 0
        );
        if (findFlow) { 
          setValue('flowId', findFlow?.id);
        }
        if (findTemplate) {
          setValue('templateId', findTemplate?.id);
        }
      }
    }
  }, [newFlowIdData, newTemplateData, flowId, template]);

  // this is the submit handler when all fileds are given and create button is placed
  const onSubmit = (_data: any) => {
    if (disputeResponseData.length > 0){
      const payload = {
        disputeId,
        flowId: _data.flowId,
        templateId: _data.templateId,
        disputeSummary: _data?.summary,
      };
      handleUpdateCreateResponse(payload, uploadFile);
      reset();
    }
    else {
      const payload = {
        disputeId: disputeId,
        templateId: template
      };
      dispatch(postCreateResponseDisputeIdTemplateIdRequest(payload));
      reset();
    }
    
  };
  // flowId and templateId select data will be reset
  useEffect(() => {
    reset({ flowId: '', templateId: '' });
  }, []);

  return (
    <div className={styles.formWrapper}>
      {isFlowLoading ? (
        <Loading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.dropDownWrapper}>
            <div className={styles.initialSelects}>
              <div className={styles.selectContainer}>
                <Box flex={`1`} className={styles.initialSelectWrapper}>
                  <Controller
                    name='flowId'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Flow is required',
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <BasicSelect
                            label={'Flow Id'}
                            options={newFlowIdData ?? []}
                            field={field}
                            placeholder={'Select Flow...'}
                          />
                          {fieldState.error && (
                            <p className={styles.errorStyle}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </Box>

                <Box flex={`1`} className={styles.initialSelectWrapper}>
                  <Controller
                    name='templateId'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Template is required',
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        <>
                          <BasicSelect
                            label={'Template'}
                            options={newTemplateData ?? []}
                            field={field}
                            error={!!fieldState.error}
                            placeholder={'Select Template...'}
                          />
                          {fieldState.error && (
                            <p className={styles.errorStyle}>
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      );
                    }}
                  />
                </Box>
              </div>
              <div>
                <DisputeTemplatePreview />
              </div>
              <div className={styles.textAreaWrapper}>
                <label>Summary</label>
                <Textarea
                  minRows={10}
                  aria-label='maximum height'
                  placeholder='Type here...'
                  {...register('summary', {
                    required: {
                      value: true,
                      message: 'summary is required',
                    },
                  })}
                />
                {errors && <ErrorMessage name='summary' errors={errors} />}
              </div>
              {template === 0 && flowId === 0 && (
                <Tooltip
                  arrow
                  placement='top'
                  title='Upload or drag and drop the file'
                >
                  <div className={styles.uploadFile}>
                    <label>Upload File</label>
                    <div className={''}>
                      <FileUploader
                        multiple={true}
                        handleChange={(doc: any) => handleFileUpload(doc)}
                        name='file'
                        types={fileTypes}
                        children={
                          <div className={styles.documentField}>
                            <img
                              style={{ display: uploadFile.name ? 'none' : '' }}
                              src={svgIcons.Upload_In_Blue}
                              alt='upload'
                            />
                            <p
                              style={{ display: uploadFile.name ? 'none' : '' }}
                            >
                              Upload File
                            </p>
                            <input
                              data-testid='dispute-document'
                              hidden={
                                uploadFile && uploadFile?.name ? false : true
                              }
                              readOnly
                              value={uploadFile?.name}
                            />
                          </div>
                        }
                      />
                    </div>
                    <ErrorMessage name={'file'} errors={errors} />
                  </div>
                </Tooltip>
              )}
              {!disputeDetails?.data?.disputeResponseSubmitted && (
                <div>
                  <Button
                    {...{
                      btnTitle: 'Create',
                      btnLabel: 'Create',
                      className: styles.selectButtonStyle,
                      isDisable: disputeResponseData?.length > 0 ? true : false,
                      type: 'submit',
                      onClick: () => {
                        if (
                          isEmpty(uploadFile?.name) &&
                          flowId === 0 &&
                          template === 0
                        ) {
                          setError('file', {
                            type: 'required',
                            message: 'Please add a file',
                          });
                        } else {
                          clearErrors('uncategorizedFile');
                        }
                      },
                    }}
                  />
                  {disputeResponseData?.length > 0 && (
                    <p style={{ marginTop: '10px', color: '#98A2B3' }}>
                      Please delete the existing response to create a new
                      response.
                    </p>
                  )}
                </div>
              )}
              
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateResponse;
