import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
    shopAllShops: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    shopsSaveAddon: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    shopFindShop: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    shopFindActiveStores: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: []
    },
    shopStoreReport: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    shopFindShopAddon: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: {}
    },
    singleFilePreview: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        data: ""
    },

}


//stores-shop-list
const getShopAllShopsRequest = (state: any, action: any) => {
    return update(state, {
        shopAllShops: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getShopAllShopsSuccess = (state: any, action: any) => {
    return update(state, {
        shopAllShops: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getShopAllShopsError = (state: any, action: any) => {
    return update(state, {
        shopAllShops: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//stores-shop-find-shop
const getShopFindShopRequest = (state: any, action: any) => {
    return update(state, {
        shopFindShop: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getShopFindShopSuccess = (state: any, action: any) => {
    return update(state, {
        shopFindShop: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getShopFindShopError = (state: any, action: any) => {
    return update(state, {
        shopFindShop: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// find active stores 
const getShopFindActiveStoresRequest = (state: any, action: any) => {
    return update(state, {
        shopFindActiveStores: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getShopFindActiveStoresSuccess = (state: any, action: any) => {
    return update(state, {
        shopFindActiveStores: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getShopFindActiveStoresError = (state: any, action: any) => {
    return update(state, {
        shopFindActiveStores: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

// find active stores 
const getShopStoreReportRequest = (state: any, action: any) => {
    return update(state, {
        shopStoreReport: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: {} }
        }
    });
};

const getShopStoreReportSuccess = (state: any, action: any) => {
    return update(state, {
        shopStoreReport: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getShopStoreReportError = (state: any, action: any) => {
    return update(state, {
        shopStoreReport: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};


//shops-save-addon and //shop-save-company-addon and //shop-save-company-policies
const postShopsSaveAddonRequest = (state: any, action: any) => {
    return update(state, {
        shopsSaveAddon: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const postShopsSaveAddonSuccess = (state: any, action: any) => {
    return update(state, {
        shopsSaveAddon: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const postShopsSaveAddonError = (state: any, action: any) => {
    return update(state, {
        shopsSaveAddon: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

//for-shop-find-shop-addon
const getShopFindShopAddonRequest = (state: any, action: any) => {
    return update(state, {
        shopFindShopAddon: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const getShopFindShopAddonSuccess = (state: any, action: any) => {
    return update(state, {
        shopFindShopAddon: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const getShopFindShopAddonError = (state: any, action: any) => {
    return update(state, {
        shopFindShopAddon: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
//for-get-Single-File-Show-Request
const getSingleFileShowRequest = (state: any, action: any) => {
    return update(state, {
        singleFilePreview: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: '' }
        }
    });
};

const getSingleFileShowSuccess = (state: any, action: any) => {
    return update(state, {
        singleFilePreview: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: action.payload }
        }
    });
};

const getSingleFileShowError = (state: any, action: any) => {
    return update(state, {
        singleFilePreview: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};
const getPreSignedUrlClear = (state: any, action: any) => {
    return update(state, {
        singleFilePreview: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: "" },
        }
    });
};
//for-shop-collaborator-access
const updateShopCollaboratorAccessRequest = (state: any, action: any) => {
    return update(state, {
        shopFindShopAddon: {
            isLoading: { $set: true },
            isError: { $set: false },
            isSuccess: { $set: false },
            data: { $set: [] }
        }
    });
};

const updateShopCollaboratorAccessSuccess = (state: any, action: any) => {
    return update(state, {
        shopFindShopAddon: {
            isLoading: { $set: false },
            isError: { $set: false },
            isSuccess: { $set: true },
            data: { $set: { ...action.payload } }
        }
    });
};

const updateShopCollaboratorAccessError = (state: any, action: any) => {
    return update(state, {
        shopFindShopAddon: {
            isLoading: { $set: false },
            isError: { $set: true },
            isSuccess: { $set: false },
            data: { $set: action.payload },
        }
    });
};

export default handleActions(
    {
        [constants.GET_SHOP_ALL_SHOPS_REQUEST]: getShopAllShopsRequest,
        [constants.GET_SHOP_ALL_SHOPS_SUCCESS]: getShopAllShopsSuccess,
        [constants.GET_SHOP_ALL_SHOPS_ERROR]: getShopAllShopsError,

        [constants.GET_SHOP_FIND_SHOP_REQUEST]: getShopFindShopRequest,
        [constants.GET_SHOP_FIND_SHOP_SUCCESS]: getShopFindShopSuccess,
        [constants.GET_SHOP_FIND_SHOP_ERROR]: getShopFindShopError,

        [constants.GET_SHOP_FIND_ACTIVE_STORES_REQUEST]: getShopFindActiveStoresRequest,
        [constants.GET_SHOP_FIND_ACTIVE_STORES_SUCCESS]: getShopFindActiveStoresSuccess,
        [constants.GET_SHOP_FIND_ACTIVE_STORES_ERROR]: getShopFindActiveStoresError,

        [constants.GET_SHOP_STORE_REPORT_REQUEST]: getShopStoreReportRequest,
        [constants.GET_SHOP_STORE_REPORT_SUCCESS]: getShopStoreReportSuccess,
        [constants.GET_SHOP_STORE_REPORT_ERROR]: getShopStoreReportError,

        [constants.POST_SHOP_FILTER_SHOPS_REQUEST]: getShopAllShopsRequest,
        [constants.POST_SHOP_FILTER_SHOPS_SUCCESS]: getShopAllShopsSuccess,
        [constants.POST_SHOP_FILTER_SHOPS_ERROR]: getShopAllShopsError,

        [constants.POST_SHOPS_SAVE_ADD_ON_REQUEST]: postShopsSaveAddonRequest,
        [constants.POST_SHOPS_SAVE_ADD_ON_SUCCESS]: postShopsSaveAddonSuccess,
        [constants.POST_SHOPS_SAVE_ADD_ON_ERROR]: postShopsSaveAddonError,

        [constants.POST_SHOP_SAVE_COMPANY_ADD_ON_REQUEST]: postShopsSaveAddonRequest,
        [constants.POST_SHOP_SAVE_COMPANY_ADD_ON_SUCCESS]: postShopsSaveAddonSuccess,
        [constants.POST_SHOP_SAVE_COMPANY_ADD_ON_ERROR]: postShopsSaveAddonError,

        [constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_REQUEST]: postShopsSaveAddonRequest,
        [constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_SUCCESS]: postShopsSaveAddonSuccess,
        [constants.POST_SHOP_SAVE_COMPANY_POLICIES_ADD_ON_ERROR]: postShopsSaveAddonError,

        [constants.GET_SHOP_FIND_SHOP_ADD_ON_REQUEST]: getShopFindShopAddonRequest,
        [constants.GET_SHOP_FIND_SHOP_ADD_ON_SUCCESS]: getShopFindShopAddonSuccess,
        [constants.GET_SHOP_FIND_SHOP_ADD_ON_ERROR]: getShopFindShopAddonError,

        [constants.GET_PRE_SIGNED_URL_REQUEST]: getSingleFileShowRequest,
        [constants.GET_PRE_SIGNED_URL_SUCCESS]: getSingleFileShowSuccess,
        [constants.GET_PRE_SIGNED_URL_ERROR]: getSingleFileShowError,
        [constants.GET_PRE_SIGNED_URL_CLEAR]: getPreSignedUrlClear,

        [constants.UPDATE_SHOP_COLLABORATOR_ACCESS_REQUEST]: updateShopCollaboratorAccessRequest,
        [constants.UPDATE_SHOP_COLLABORATOR_ACCESS_SUCCESS]: updateShopCollaboratorAccessSuccess,
        [constants.UPDATE_SHOP_COLLABORATOR_ACCESS_ERROR]: updateShopCollaboratorAccessError,
    },
    initialState
);