import { Link } from 'react-router-dom';import { BlueTick, svgIcons } from 'elements';
import { getDisputeCurrency } from 'utils/disputeUtils';
import styles from './style.module.scss';
// import InlineUpdateManager from "components/storesView/InlineUpdateManager";
import { HtmlTooltip } from 'components/ui';
import ToolTipTitle from 'components/tooltip/ToolTipTitle';
import { DisputesData } from 'hooks/disputeHooks/useDiputeList';
import Loading from 'components/loading/loading';
import Image from 'components/image';
import Pagination from 'components/pagination/Pagination';
import dayjs from 'dayjs';

type DisputeListProps = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  tableHeadings: string[];
  data: any;
  tableList: number;
  page: number;
  onPageChange: (page: number) => void;
};

const PaypalReportList = ({
  isLoading,
  isSuccess,
  data,
  tableList,
  page,
  onPageChange,
  isError,
  tableHeadings,
}: DisputeListProps) => {
  return (
    <>
      <div className={styles.tableWrapper}>
        {isLoading && <Loading />}
        {!isLoading && !data?.disputeResponseList?.length ? (
          <div className={styles.NoData}>
            <img src={svgIcons.Coffee_Break} alt='disputes' />
            {isError && <h3>Oops! Something went wrong. Please try again.</h3>}
            <h3>No dispute found</h3>
          </div>
        ) : (
          isSuccess &&
          data?.count > 0 && (
            <>
              <table>
                <thead>
                  <tr>
                    {tableHeadings?.length > 0 &&
                      tableHeadings?.map((item: string) => (
                        <th key={item}>{item}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data?.disputeResponseList?.length > 0 &&
                    data?.disputeResponseList?.map((dispute: DisputesData) => (
                      <tr key={dispute.id} className={styles.disputeWrapper}>
                        <td data-testid='dispute-order'>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {dispute?.paymentProcessor && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {dispute?.submittedByChargePay && (
                                  <div
                                    style={{
                                      marginRight: '5px',
                                      marginTop: '5px',
                                    }}
                                  >
                                    <BlueTick />
                                  </div>
                                )}
                                <Image
                                  icon={dispute?.paymentProcessor}
                                  altText='payment processor'
                                />
                              </div>
                            )}
                            <Link
                              className={styles.id}
                              to={`/dispute/${dispute?.id}`}
                            >
                              <p className={styles.recentDisputeID}>
                                {dispute?.id ?? 'Missing Dispute'}
                              </p>
                            </Link>
                          </div>
                        </td>
                        <td className={styles.id}>
                          <Link
                            className={styles.id}
                            to={
                              dispute?.shopId
                                ? `/store-details/${dispute?.shopId}`
                                : '#'
                            }
                          >
                            <p className={styles.recentDisputeID}>
                              {dispute?.shopName ?? 'Missing Dispute'}
                            </p>
                          </Link>
                        </td>
                        <td>
                          {dispute?.createTime &&
                            dayjs(dispute.createTime).format('MMMM D, YYYY')}
                        </td>
                        <td>
                          {
                            getDisputeCurrency(
                              dispute?.disputeAmountCurrencyCode
                            )?.currencySign
                          }
                          {Number(dispute?.disputeAmountValue)
                            ?.toFixed(2)
                            ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        </td>

                        <td>
                          <div className={styles.reasonType}>
                            <p>{dispute.reason?.split('_').join(' ')}</p>
                          </div>
                        </td>
                        <td>
                          {dispute?.sellerResponseDueDate &&
                            dayjs(dispute?.sellerResponseDueDate).format(
                              'MMMM DD, YYYY'
                            )}
                        </td>
                        <td>
                          {dispute?.decisionDate &&
                            dayjs(dispute?.decisionDate).format(
                              'MMMM DD, YYYY'
                            )}
                        </td>
                        {(!dispute?.carrier ||
                          dispute.carrier.trim() === '' ||
                          dispute.carrier.trim().toLowerCase() === 'null') &&
                        (!dispute?.lastMileCarrier ||
                          dispute.lastMileCarrier.trim() === '' ||
                          dispute.lastMileCarrier.trim().toLowerCase() ===
                            'null') ? (
                          <td colSpan={2}>
                            <p
                              style={{
                                backgroundColor: 'rgb(248, 226, 224)',
                                width: '110px',
                                color: '#000',
                                textAlign: 'center',
                                padding: '5px',
                                borderRadius: '4px',
                              }}
                            >
                              MISSING DATA
                            </p>
                          </td>
                        ) : (
                          <>
                            <td className={styles.carrierInfo}>
                              {dispute?.carrier ? (
                                <div className={styles.carrier}>
                                  <p className={styles.carrierData}>
                                    {dispute.carrier}
                                  </p>
                                </div>
                              ) : (
                                <div className={styles.carrier} />
                              )}
                            </td>
                            <td className={styles.carrierInfo}>
                              {dispute?.lastMileCarrier ? (
                                <div className={styles.carrier}>
                                  <p className={styles.carrierData}>
                                    {dispute.lastMileCarrier}
                                  </p>
                                </div>
                              ) : (
                                <div className={styles.carrier} />
                              )}
                            </td>
                          </>
                        )}
                        <td>
                          {dispute.responseStatus && (
                            <HtmlTooltip
                              placement='top-start'
                              arrow
                              title={<ToolTipTitle toolTipDetails={dispute} />}
                            >
                              <div
                                className={styles.reasonType}
                                style={{
                                  background:
                                    dispute.responseStatus === 'SUBMITTED'
                                      ? 'rgb(188 248 212)'
                                      : dispute.responseStatus ===
                                        'MISSING_INFO'
                                      ? 'rgb(248 226 224)'
                                      : 'rgb(252 228 154)',
                                }}
                              >
                                <p>{dispute.responseStatus}</p>
                              </div>
                            </HtmlTooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )
        )}
      </div>
      <div>
        {Math.ceil(data?.count / tableList) > 1 && (
          <Pagination
            onPageChange={pageNum => onPageChange(pageNum)}
            page={page}
            totalPages={Math.ceil(data?.count / tableList)}
          />
        )}
      </div>
    </>
  );
};
export default PaypalReportList;
