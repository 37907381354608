import { useState, useEffect, KeyboardEventHandler, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, uniqBy } from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@mui/material';
import cn from 'classnames';
import {
  SingleAccountBreadcrumb,
  Search,
  DisputeList,
  DisputeFilter,
  Pagination,
} from 'components';
import styles from './style.module.scss';
import ReactSelect from 'react-select';
import { getItem, setItem } from 'utils/localStorage';
import { StatusSection, responseStatus } from 'utils/disputeUtils';
import { useBreadCumbs, useOpenDatePicker, useStorelist,useDateFilter } from 'hooks';
import {
  getFilteredDisputesRequest,
  postDisputesListFetchByAccountRequest,
} from 'redux/actions';
import { CalendarIcon, FilterIcon } from 'components/disputeFlow/NodeHelpers';
import { BreadcumbItem } from 'components/ui';

type TShop = {
  value: number;
  label: string;
};

interface Props {
  shop?: TShop;
}

const Disputes = ({ shop }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const selectRef: any = useRef(null);

  //custom hooks
  const { storesOptions } = useStorelist();
  const { breadCumbs } = useBreadCumbs({ pageName: 'Disputes' });
  // const { openDatePicker, datePickerRef } = useOpenDatePicker();
  const tableList = 15;
  const [shopList, setShopList] = useState<TShop[]>([]);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [disputeStatusBtnCheck, setDisputeStatusBtnCheck] = useState(false);
  const [disputeClick, setDisputeClick] = useState(false);
  const [disputeStatusButton, setDisputeStatusButton] = useState([
    ...StatusSection,
  ]);
  const [disputeResponseStatus, setDisputeResponseStatus] = useState<
    { value: number; label: string }[]
  >([]);

  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  // const [dateFilterCheck, setDateFilterCheck] = useState(false);

  //for-payload (for all types of API call):-
  const [disputesPayload, setDisputesPayload] = useState<any>();
  const disputePageLength = getItem('disputePageLength');
  const SingleAccountCheck = getItem('singleAccountCheck');
  const { accountId } = useParams();
  const { data } = useSelector(
    (state: any) => state.disputesList.disputesList || {}
  );

  // this is the custom hook for date filtering
  const {
    openDatePicker,
    datePickerRef,
    startDate,
    endDate,
    setDateRange,
    setDateFilterCheck,
  } = useDateFilter(disputesPayload, setDisputesPayload);

  //for date dispute filter
  // useEffect(() => {
  //   setPage(1);
  //   setItem('disputePage', 1);

  //   if (dateFilterCheck && startDate && endDate) {
  //     const formattedStartDate = moment(startDate).format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     );
  //     const formattedEndDate = moment(endDate).format(
  //       'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
  //     );

  //     let datePayload = {
  //       ...disputesPayload,
  //       page: 1,
  //       fromDate: formattedStartDate,
  //       toDate: formattedEndDate,
  //     };
  //     setDisputesPayload(datePayload);
  //   } else if (dateFilterCheck && !startDate && !endDate) {
  //     let datePayload = {
  //       ...disputesPayload,
  //       page: 1,
  //       fromDate: null,
  //       toDate: null,
  //     };
  //     setDisputesPayload(datePayload);
  //   }
  // }, [dateFilterCheck, startDate, endDate]);
  useEffect(() => {
    setPage(1)
    if (shop) {
      setShopList(prev => uniqBy([...prev, shop], 'value'));
    }
  }, [shop]);
  useEffect(() => {
    setPage(1)
    if (shopList.length > 0) {
      // setDisputesPayloadType("disputeFilter");
      const maped: any = shopList.map((item: any) => item.value); // for sending storeId
      // const maped: any = shopList.map((item: any) => item.label);// for sending storeName
      const payload = {
        ...disputesPayload,
        shopIds: maped.length ? maped : null,
        page:1,
      };
      setDisputesPayload(payload);
    }
  }, [shopList]);

  useEffect(() => {
    setPage(1)
    if (disputeResponseStatus.length > 0) {
      const maped: any = disputeResponseStatus.map((item: any) => item.label);
      const payload = {
        ...disputesPayload,
        disputeResponseStatus: maped.length ? maped : null,
      };
      setDisputesPayload(payload);
    }
  }, [disputeResponseStatus]);

  //for search-dispute-filter
  const handleSearch = (searchItem: string) => {
    setPage(1);
    setItem('disputePage', 1);
    const payload = {
      ...disputesPayload,
      page: 1,
      search: searchItem ? searchItem : null,
    };
    setDisputesPayload(payload);
  };

  const handleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleDisputeStatus = (disputeStatus: any, index: number) => {
    setPage(1);
    setItem('disputePage', 1);
    const disputeBtn = cloneDeep(disputeStatusButton);
    disputeStatus.status = !disputeStatus.status;
    setDisputeStatusBtnCheck(!disputeStatusBtnCheck); //check for dispatching the api when ever status button clicked
    setDisputeClick(true); //check the user start using the status button filter
    disputeBtn[index] = disputeStatus;
    setDisputeStatusButton([...disputeBtn]);
  };

  const handleStatusFilterPayload = (statusFilter: string[]) => {
    if (!SingleAccountCheck) {
      return {
        ...disputesPayload,
        page: 1,
        pageLength: Number(disputePageLength),
        statuses: statusFilter?.length ? statusFilter : null,
      };
    } else {
      return {
        ...disputesPayload,
        page: 1,
        pageLength: Number(disputePageLength),
        statuses: statusFilter?.length ? statusFilter : null,
        userId: accountId,
      };
    }
  };

  //handle dispatch for account-filter or manager-filter
  const handleDispatch = (payload: any) => {
    SingleAccountCheck === 'true'
      ? dispatch(postDisputesListFetchByAccountRequest(payload))
      : dispatch(getFilteredDisputesRequest(payload));
  };

  useEffect(() => {
    setPage(1)
    if (disputeClick) {
      const statusArr = disputeStatusButton.filter(
        statusData => statusData.status
      );
      const statusFilterArr = statusArr.map(item => {
        return item?.title?.toUpperCase();
      });
      const payload = handleStatusFilterPayload(statusFilterArr);
      setDisputesPayload(payload); //set the payload by filtering status button
    }
  }, [disputeStatusBtnCheck, disputeStatusButton]);

  //initial payload when disputes render
  useEffect(() => {
    if (!SingleAccountCheck) {
      setItem('disputePage', page);
      setItem('disputePageLength', tableList);

      const payload = {
        page,
        pageLength: tableList,
        statuses: null,
        upto3: false,
        upto7: false,
        above7: false,
        reasons: null,
        stage: null,
        stores: shopList,
        disputeResponseStatus: null,
        paymentProcessor: null,
        oneDay: false,
        oneWeek: false,
        oneMonth: false,
        fromDate: null,
        toDate: null,
        search: null,
      };

      setDisputesPayload({
        ...payload,
        ...disputesPayload,
        page: page,
      });
    } else {
      setItem('disputePage', page);
      setItem('disputePageLength', tableList);

      const payload = {
        page: page,
        pageLength: tableList,
        statuses: null,
        carriers: null,
        stores: shopList,
        disputeResponseStatus: null,
        upto3: false,
        upto7: false,
        above7: false,
        reasons: null,
        stage: null,
        paymentProcessor: null,
        userId: accountId,
        fromDate: null,
        toDate: null,
        search: null,
      };

      setDisputesPayload({
        ...payload,
        ...disputesPayload,
        page: page,
      });
    }
  }, []);
  const blur = () => {
    selectRef.current?.blur();
  };
  const handleKeyDown: KeyboardEventHandler = (
    event: React.KeyboardEvent<Element>
  ) => {
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        blur();
        // setDisputesPayloadType("disputeFilter");
        const maped: any = shopList.map((item: any) => item.value);
        const mappedReponse: any = disputeResponseStatus.map(
          (item: any) => item.label
        );
        const payload = {
          ...disputesPayload,
          shopIds: maped.length ? maped : null,
          disputeResponseStatus: mappedReponse.length ? mappedReponse : null,
        };
        setDisputesPayload(payload);
        event.preventDefault();
    }
  };

  //to call all APIs
  useEffect(() => {
    if (disputesPayload) {
      handleDispatch(disputesPayload);
    }
  }, [disputesPayload]);

  const onPageChange = (pageNumber: number) => {
    setItem('disputePage', pageNumber);
    setItem('disputePageLength', tableList);
    setPage(pageNumber);
    setDisputesPayload({
      ...disputesPayload,
      page: pageNumber,
    });
  };

  return (
    <div
      style={shop ? { paddingLeft: 0, paddingRight: 0, minHeight: 0 } : {}}
      className={styles.disputesWrapper}
    >
      {SingleAccountCheck === 'true' ? (
        <SingleAccountBreadcrumb />
      ) : shop ? null : (
        <div className={styles.nav}>
          <h3>Disputes</h3>
          <div className={styles.navPath}>
            {breadCumbs.map((item, key) => (
              <BreadcumbItem {...item} {...{ key }} />
            ))}
          </div>
        </div>
      )}
      <div className={styles.note}>
        <p>Your disputes dashboard to keep you in track with disputes</p>
      </div>
      <div className={styles.status}>
        {disputeStatusButton?.map((statusBtn, index) => {
          return (
            <Button
              key={statusBtn.title}
              data-testid='dispute-status-button'
              className={cn(
                statusBtn.status && styles.allDisputes,
                styles.statusDiv
              )}
              onClick={() => handleDisputeStatus({ ...statusBtn }, index)}
            >
              <div
                className={cn(styles.open)}
                style={{
                  background: statusBtn.status ? '#ffffff' : statusBtn.color,
                }}
              ></div>
              {statusBtn?.title?.split('_').join(' ')}
            </Button>
          );
        })}
      </div>
      <div className={styles.filterContainer}>
        <div className={styles.filterButtonContainer}>
          <button
            data-testid='dispute-list-filter'
            className={styles.filterButton}
            onClick={() => handleFilter()}
          >
            <FilterIcon className={styles.icon} />
            Filter
          </button>
          <div onClick={openDatePicker} className={styles.date}>
            <CalendarIcon className={styles.icon} />
            <DatePicker
              dateFormat='dd/MM/yyyy'
              selectsRange={true}
              placeholderText='Select date range...'
              ref={datePickerRef}
              startDate={startDate}
              endDate={endDate}
              onChange={(update: any) => {
                setDateRange(update);
                setDateFilterCheck(true); //to control the API call
              }}
              className={styles.datePicker}
              monthsShown={2}
              isClearable
            />
          </div>
        </div>
        <div className={styles.filterSelectContainer}>
          <div className={styles.searchDiv}>
            <Search
              onSearch={query => handleSearch(query)}
              onType={query => handleSearch(query)}
              type={''}
              placeholder={'Search here ...'}
              className={styles.searchInput}
            />
          </div>
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  minHeight: '44px',
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={'Select stores...'}
              options={storesOptions}
              isMulti
              value={shopList}
              onChange={(e: any) => setShopList(e)}
            />
          </div>
          <div className={styles.searchDiv}>
            <ReactSelect
              ref={selectRef}
              className={styles.multipleSelect}
              styles={{
                control: baseStyles => ({
                  ...baseStyles,
                  minHeight: '44px',
                }),
              }}
              closeMenuOnSelect={false}
              onKeyDown={handleKeyDown}
              placeholder={'Select Response Status...'}
              options={responseStatus}
              isMulti
              value={disputeResponseStatus}
              onChange={(e: any) => setDisputeResponseStatus(e)}
            />
          </div>
        </div>
        <div
          className={styles.filters}
          style={{ display: !filterOpen ? 'none' : '' }}
        >
          <DisputeFilter
            disputesPayload={disputesPayload}
            setDisputesPayload={setDisputesPayload}
            setDateRange={setDateRange}
            setDateFilterCheck={setDateFilterCheck}
            setPage={setPage}
          />
        </div>
      </div>
      <div>
        <DisputeList
          tableList=  {tableList}
          page={page}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default Disputes;
